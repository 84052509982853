import type { CheckboardAddress } from "./CheckboardCreditCheck";

/**
 * @description
 * Takes a Checkboard address object and returns a string of concatenated addresses.
 * @param {CheckboardAddress} address - The address object to format.
 * @returns {string} formattedAddress - Concatenated address.address, address.address2, waddress.address3, address.city, address.country as string
 */
export const formatAddress = (address: CheckboardAddress): string => {
  let formattedAddress = address.address;
  if (address.address2) {
    formattedAddress += ` ${address.address2}`;
  }
  if (address.address3) {
    formattedAddress += ` ${address.address3}`;
  }
  if (address.city) {
    formattedAddress += `, ${address.city}`;
  }
  if (address.country) {
    formattedAddress += `, ${address.country}`;
  }
  return formattedAddress;
};
