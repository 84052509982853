import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";

import { BlockInstanceProps, Props } from "@/types";
import { computed, mappedComputed } from "./computed";
import { RevXVehicleStore } from "./types";
export { methods, getProps, getData, computed };
export type Props = ReturnType<typeof getProps>;
export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;
export type Computed = { [K in keyof typeof computed]: ReturnType<(typeof computed)[K]> } & ReturnType<
  typeof mappedComputed
>;

export interface BlockRevXProps {
  padding: Props.Str;
  enforceVerification: Props.Bool;
  enforceValidation: Props.Bool;
  invalidFeedback: Props.Str;
  clientId: Props.Str;
  clientSecret: Props.Str;
  requireConfirmation: Props.Bool;
  returnMercedesRecallData: Props.Bool;
  stagingEnvironment: Props.Bool;
  stores: Props.CGPropExtras<Array<{ name: RevXVehicleStore; type: string }>>;
}
/**
 * Passing Computed as a generic prevents TS from throwing circular reference error when
 * computed functions use a state object with `BlockRevXInstance` as its type
 */
export type BlockRevXInstance = Vue & Data & Computed & BlockInstanceProps<BlockRevXProps>;
