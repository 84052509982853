<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
// Components
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
// Helpers
import { baseProps } from "../helpers/baseProps";
import { gatewayHelper } from "../helpers/gatewayHelper";
import { viewSubmit } from "../helpers/viewSubmit";
import { getUserDetailsFromCheck } from "../helpers/getUserDetailsFromCheck";
import { handleUnknownError } from "../helpers/handleUnknownError";
// Types
import type { Questionnaire, RequestorAuthenticity } from "../helpers/CheckboardCreditCheck";
import type { Answer } from "../helpers/CheckboardCreditCheck";

export default (Vue as VueConstructor<Vue & RequestorAuthenticity.Props & RequestorAuthenticity.Refs>).extend({
  name: "RequestorAuthenticity",
  components: { BlockSingleSelect, CheckboardHeaderContent, BlockButton },
  props: {
    ...baseProps(),
    questionnaire: {
      type: Object as PropType<Questionnaire>,
      required: true,
    },
  },
  data() {
    return {
      answer1: null as Answer,
      answer2: null as Answer,
      answer3: null as Answer,
      invalidFeedback: "This field is required",
      answerState: {
        "1": null,
        "2": null,
        "3": null,
      },
      isSubmitting: false,
    };
  },
  computed: {
    user(): ReturnType<typeof getUserDetailsFromCheck> {
      return getUserDetailsFromCheck(this.check);
    },
  },
  methods: {
    handleAnswer(questionId: number) {
      const ref = questionId === 1 ? "question1" : questionId === 2 ? "question2" : "question3";
      const answer = {
        questionId,
        answer: [this.$refs[ref].selected + 1],
      };
      this[`answer${questionId}`] = answer;
    },
    validateAnswers() {
      const valid = [this.answer1, this.answer2, this.answer3].map((answer, index) => {
        if (answer) {
          this.answerState[index + 1] = true;
        } else {
          this.answerState[index + 1] = false;
        }

        return !!answer;
      });

      return valid.every((v) => v);
    },
    resetAnswerStates() {
      this.answerState = {
        "1": null,
        "2": null,
        "3": null,
      };
    },
    async handleSubmit() {
      this.isSubmitting = true;
      try {
        this.resetAnswerStates();
        if (!this.validateAnswers()) {
          return;
        }
        const data = {
          claimId: this.claimId,
          userId: this.userId,
          checkId: this.checkId,
          questionnaireResponse: {
            referenceId: this.questionnaire.referenceId,
            questionnaireId: Number(this.questionnaire.questionnaireId),
            answers: [this.answer1, this.answer2, this.answer3],
          },
        };
        const result = await gatewayHelper("checkboardSubmitAuthenticityAnswers", data);
        viewSubmit(this, "requestorAuthenticity", result);
      } catch (error) {
        console.error(`Error submitting authenticity answers: ${error}`);
        handleUnknownError(this, this.check);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
</style>
<template>
  <div class="d-flex flex-column gap-20">
    <CheckboardHeaderContent
      :isBuilder="isBuilder"
      :hideProgressBar="hideProgressBar"
      :progressValue="'66'"
      header="Verify Your Identity"
      :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, we need you to answer a few financial questions about yourself to verify your identity.`"
      :text="`Your answers will help us to confirm that you are the rightful claimant.`"
    />
    <div>
      <BlockSingleSelect
        v-bind="{
          options: questionnaire.questions[0].choices.map((choice) => choice.choiceText),
          label: questionnaire.questions[0].questionText,
          state: answerState['1'],
          invalidFeedback: invalidFeedback,
          padding: '0',
        }"
        @update:answer="handleAnswer(1)"
        ref="question1"
      />
    </div>
    <div>
      <BlockSingleSelect
        v-bind="{
          options: questionnaire.questions[1].choices.map((choice) => choice.choiceText),
          label: questionnaire.questions[1].questionText,
          state: answerState['2'],
          invalidFeedback: invalidFeedback,
          padding: '0',
        }"
        @update:answer="handleAnswer(2)"
        ref="question2"
      />
    </div>
    <div>
      <BlockSingleSelect
        v-bind="{
          options: questionnaire.questions[2].choices.map((choice) => choice.choiceText),
          label: questionnaire.questions[2].questionText,
          state: answerState['3'],
          invalidFeedback: invalidFeedback,
          padding: '0',
        }"
        @update:answer="handleAnswer(3)"
        ref="question3"
      />
    </div>
    <BlockButton
      :text="'Submit'"
      :variant="'primary'"
      :isProcessing="isSubmitting"
      @clicked="handleSubmit"
      :block="false"
      :submitButtonDefault="false"
    />
  </div>
</template>
