// eslint-disable-next-line no-unused-vars
import { T2a, AsyncResult } from "../../types";
import { AsyncHelper } from "./AsyncHelper";
import { AxiosService } from "./AxiosService";
import { FunnelLogMetadata, T2AGetAddressResponse, User } from "@claimsgate/core-types";
import { AxiosResponse } from "axios";
import { onCallGateway } from "./onCallGateway";
import { isClaimsGateFunctionReturnedError } from "../vue";

const T2A = {
  method: {
    addressSearch: "address_search",
    personVerify: "person_verify",
    validateEmail: "validate_email",
    validateMobileNumber: "validate_mobile_phone_number",
  },
  output: { json: "json" },
  apiKey: process.env.VUE_APP_T2A_KEY,
  url: "https://api.t2a.io/rest?",
};

class T2AService {
  /**
   * Calls T2A to get list of addresses and parses a successful result.
   */
  static async runAddressSearch(
    postcode: string,
    metadata: FunnelLogMetadata
  ): Promise<AsyncResult<T2a.ParsedAddress[] | undefined, boolean | undefined, boolean | undefined>> {
    try {
      const result = await onCallGateway<"searchAddress">({
        functionName: "searchAddress",
        data: { postcode, ...metadata },
      });

      if (isClaimsGateFunctionReturnedError(result)) {
        console.error("t2a Address search returned error", result);
        return AsyncHelper.onError(true);
      }

      const parsedAddresses = T2AService.parseT2AaddressListResponse(
        result.data as T2AGetAddressResponse["address_list"]
      );

      if (parsedAddresses) {
        return AsyncHelper.onCompleted(parsedAddresses);
      } else {
        return AsyncHelper.onError(true);
      }
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Calls T2A to check user data against electorall roll.
   */
  static async runElectoralRollCheck(
    userData: T2a.RollCheckUserData,
    metadata: FunnelLogMetadata
  ): Promise<
    AsyncResult<{ matchPassed: boolean; electoralResult: any } | undefined, boolean | undefined, boolean | undefined>
  > {
    const matchFailedValues = {
      match_code_forename: "NO_MATCH",
      match_code_surname: "NO_MATCH",
      match_code_address: "NO_MATCH",
      validation_status: "NOT_FOUND",
    };
    try {
      // const rollCheckURL = T2AService.buildRollCheckURL(userData);
      // console.log("rollCheckURL", rollCheckURL);
      // const electoralResult = await AxiosService.get(rollCheckURL, {
      //   ...metadata,
      //   invocationFunction: "runElectoralRollCheck",
      //   invocationReason: "T2AElectoralRollCheck",
      // });

      const electoralResult = await onCallGateway<"electoralRollCheck">({
        functionName: "electoralRollCheck",
        data: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          postcode: userData.postcode,
          addressLine1: userData.addressLine1,
          ...metadata,
        },
      });
      console.log("GATEWAY electoralResult", electoralResult);
      const matchPassed = Object.keys(matchFailedValues).every((key) => {
        return electoralResult.data[key] && electoralResult.data[key] !== matchFailedValues[key];
      });
      return AsyncHelper.onCompleted({ matchPassed, electoralResult });
    } catch {
      return AsyncHelper.onException(matchFailedValues);
    }
  }

  /**
   * Run Mobile number check against T2A
   */
  static async runMobilePhoneCheck(
    mobileNumber: string | number,
    metadata: FunnelLogMetadata
  ): Promise<AsyncResult<Object | undefined, never | undefined, true | undefined>> {
    try {
      const mobileCheckUrl = T2AService.buildMobileNumberCheck(mobileNumber);

      const response = await AxiosService.get(mobileCheckUrl, {
        ...metadata,
        invocationFunction: "runMobilePhoneCheck",
        invocationReason: "T2AMobilePhoneCheck",
      });

      const mobileCheckResult = T2AService.parseT2AMobileNumberCheck(response);

      return AsyncHelper.onCompleted(mobileCheckResult);
    } catch {
      return AsyncHelper.onException(true);
    }
  }

  /**
   * Checks given email with T2A API call
   */
  static async runEmailCheck(
    email: string,
    metadata: FunnelLogMetadata
  ): Promise<AsyncResult<Object | undefined, never | undefined, true | undefined>> {
    try {
      const emaiCheckUrl = T2AService.buildEmailCheckURL(email);

      const response = await AxiosService.get(emaiCheckUrl, {
        ...metadata,
        invocationFunction: "runEmailCheck",
        invocationReason: "T2AEmailCheck",
      });

      const emailCheckResult = T2AService.parseT2AEmailCheck(response);

      return AsyncHelper.onCompleted(emailCheckResult);
    } catch {
      return AsyncHelper.onException(true);
    }
  }

  /**
   */
  static parseT2AaddressListResponse(addressList: T2AGetAddressResponse["address_list"]): T2a.ParsedAddress[] | false {
    let result = [];
    try {
      result = addressList.map((address) => {
        return {
          text: address.addr_single_line,
          value: {
            id: address.address_id,
            addressLine1: `${address.line_1} ${address.line_2}` ?? "",
            addressLine2: address.line_3 ?? "",
            city: (address.town.length > 0 ? address.town : address.place) ?? "",
            postcode: address.postcode,
          },
        };
      });
      if (result.length > 0) {
        return result;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  /**
   * Checks response object to confirm if email has been verified
   */
  static parseT2AEmailCheck(response: AxiosResponse): boolean {
    try {
      return response.data.status.toLowerCase() === "ok";
    } catch {
      return false;
    }
  }

  /**
   */
  static parseT2AMobileNumberCheck(response: AxiosResponse): boolean {
    try {
      return response.data.status.toLowerCase() === "ok" && response.data.telephone_number !== undefined;
    } catch {
      return false;
    }
  }

  /**
   * Create URL for T2A Electoral Roll Check
   */
  static buildRollCheckURL(userData: T2a.PostcodeCheckUserData): string {
    const queryParams = {
      method: T2A.method.personVerify,
      forename: userData.firstName,
      surname: userData.lastName,
      postcode: userData.postcode,
      addr1: userData.addressLine1,
    };

    return T2AService.queryParamsToT2AURL(queryParams);
  }

  /**
   * Create URL for T2A address search from postcode
   */
  static buildAddressSearchURL(postcode: string): string {
    const queryParams = {
      method: T2A.method.addressSearch,
      postcode: postcode,
    };

    return T2AService.queryParamsToT2AURL(queryParams);
  }

  /**
   * Create a URL for T2A emaill search from email
   */
  static buildEmailCheckURL(email: string): string {
    const queryParams = {
      method: T2A.method.validateEmail,
      email_address: email,
    };

    return T2AService.queryParamsToT2AURL(queryParams);
  }

  static buildMobileNumberCheck(mobileNumber: string | number): string {
    const queryParams = {
      method: T2A.method.validateMobileNumber,
      telephone_number: mobileNumber,
    };

    return T2AService.queryParamsToT2AURL(queryParams);
  }

  /**
   * Converts `queryParams` into T2A search URL. Includes output:json and api_key:T2A.apiKey
   * @param { Object } queryParams key values to be added to URL as query params
   * @returns { String } url for http call
   */
  static queryParamsToT2AURL(queryParams: any): string {
    const URLparams = new URLSearchParams();

    queryParams.output = T2A.output.json;
    queryParams.api_key = T2A.apiKey;

    Object.keys(queryParams).map((key) => {
      URLparams.append(key, queryParams[key]);
    });

    return T2A.url + URLparams.toString();
  }
}

// eslint-disable-next-line no-undef
export { T2AService };
