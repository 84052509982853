import { BlockClaimsGateVehicleInstance } from "./BlockClaimsGateVehicle";
import { ClaimsGateVehicleResponse } from "@claimsgate/core-types";
import { ClaimsGateErrors, ClaimsGateFunctionReturnedError, Quantum, getClaimsByFunnelId } from "@claimsgate/core";
import { DateTime } from "luxon";
import { ClaimsGatePartialVehicleStore } from "./types";

import omitBy from "lodash.omitby";
import isNil from "lodash.isnil";
import { reject } from "@/helpers/vue";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { fireClaimsGateVehicleApiError } from "./sentry";
import { JONES_WHYTE_FUNNELS } from "@/components/claimant/form/next";
import { getFirebaseBackend } from "@/authUtils";

/** Methods to be consumed by Vue instance */
export const methods = {
  searchVehicle,
  submitVehicleConfirmation,
  submitKeeperSelect,
  displayHelpModal,
  setValidationInvalidFeedback,
  notMyVehicle,
  displayDropout,
  resetVehicleSearch,
  submitDropout,
};

async function submitDropout(state: BlockClaimsGateVehicleInstance) {
  state.BlockInputs.submitDropoutButton.isProcessing = true;
  state.BlockInputs.dropoutSingleSelect.state = null;
  state.BlockInputs.dropoutSingleSelect.invalidFeedback = "";

  // Validate dropout selected
  if (!state.BlockInputs.dropoutSingleSelect.answer) {
    state.BlockInputs.dropoutSingleSelect.state = false;
    state.BlockInputs.submitDropoutButton.isProcessing = false;
    state.BlockInputs.dropoutSingleSelect.invalidFeedback = "Please select an option";
    return;
  }

  // Parse the dropout selected
  const selectedDropout = state.BlockInputs.dropoutSingleSelect.answer;

  const funnelVariables = await _getFunnelVariables(state);

  const hashedVehicle = await state.variablesService.hashData({ dropoutAnswer: selectedDropout }, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }

  // If the vehicle is nearly eligible and is a S&G client
  await state.$store.dispatch("events/fire", { name: state.eventValues.next });

  state.BlockInputs.submitDropoutButton.isProcessing = false;
}

/** Resets the vehicle search view */
export function resetVehicleSearch(state: BlockClaimsGateVehicleInstance) {
  resetToSearchVehicleView(state);
}

export function displayDropout(state: BlockClaimsGateVehicleInstance) {
  state.uiToggles.isDropoutVisible = true;
  state.$nextTick(() => {
    state.uiToggles.isVehicleSearchVisible = false;
  });
}

export function displayHelpModal(state: BlockClaimsGateVehicleInstance) {
  state.$bvModal.show("helpModal");
}

/** When the user selects not my vehicle */
export function notMyVehicle(state: BlockClaimsGateVehicleInstance) {
  resetToSearchVehicleView(state);
}

/** Sets invalid feedback which has been fed from a page level validation onto the block */
export function setValidationInvalidFeedback(
  state: BlockClaimsGateVehicleInstance,
  invalidFeedback: string,
  stayOnPage = false
) {
  if (!stayOnPage) {
    resetToSearchVehicleView(state);
    state.vrmInputState = false;
    state.vrmInputInvalidFeedback = invalidFeedback;
  } else {
    state.BlockInputs.keeperSelectSingleSelect.state = false;
    state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = invalidFeedback;
    state.BlockInputs.searchRegButton.isProcessing = false;
    state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
  }
}

/** Handles the logic when the user submits the view for confirming their keeper dates dates */
export async function submitKeeperSelect(state: BlockClaimsGateVehicleInstance) {
  try {
    state.BlockInputs.submitKeeperSelectButton.isProcessing = true;
    state.BlockInputs.keeperSelectSingleSelect.state = null;

    // Validate keeper selected
    if (!validateKeeperSelected(state)) return;

    // Parse the keeper start and end dates from the keeperSelectSingleSelect
    const selectedKeeperStartDate = state.BlockInputs.keeperSelectSingleSelect.answer;
    const selectedKeeper = state.vehicle.keeperChangeHistory.find(
      (keeperChange) =>
        DateTime.fromJSDate(new Date(keeperChange.StartDateOfKeeper)).toISO() === selectedKeeperStartDate
    );

    state.vehicle.keeperStartDate = selectedKeeper.StartDateOfKeeper;

    state.vehicle.numberOfPreviousKeepers = parseInt(selectedKeeper.NumberOfPreviousKeepers);

    if (selectedKeeper.EndDateOfKeeper) {
      state.vehicle.keeperEndDate = selectedKeeper.EndDateOfKeeper;
    } else {
      state.vehicle.isCurrentKeeper = true;
    }

    await saveVehicle(state);
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

/** Validates if a keeper has been selected */
function validateKeeperSelected(state: BlockClaimsGateVehicleInstance): boolean {
  if (state.BlockInputs.keeperSelectSingleSelect.answer?.length === 0) {
    state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = state.uiMessages.noKeeperSelected;

    reject(state, state.uiMessages.noKeeperSelected);

    state.BlockInputs.keeperSelectSingleSelect.state = false;
    state.BlockInputs.submitKeeperSelectButton.isProcessing = false;

    return false;
  }
  return true;
}

/** Handles the logic when the user submits the view for confirming if the retured vehicle is correct */
export async function submitVehicleConfirmation(state: BlockClaimsGateVehicleInstance) {
  try {
    if (state.BlockInputs.vehicleConfirmationSingleSelect.answer === "No") {
      // Reset to the search vehicle view
      resetToSearchVehicleView(state);
      return;
    }

    state.BlockInputs.confirmVehicleText.html = `<div><div><h3><b>Vehicle Found</b></h3><h4>We found your vehicle, <b>${state.vehicle.make?.toUpperCase()}</b>, <b>${state.vehicle.model?.toUpperCase()}</b>, <b>${
      state.vehicle.yearOfManufacture
    }</b>, <b>${state.vehicle.fuelType}</b>.</h4></div></div>`;

    state.uiToggles.isVehicleSearchVisible = false;

    setupKeeperChangeSingleSelect(state);
    await state.$nextTick();
    state.uiToggles.showVehicleConfirmation = false;
    await state.$nextTick();
    state.uiToggles.isKeeperSelectVisible = true;
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

function setupKeeperChangeSingleSelect(state: BlockClaimsGateVehicleInstance) {
  state.BlockInputs.keeperSelectSingleSelect.options = state.vehicle.keeperChangeHistory.map((keeperChange) => {
    return DateTime.fromJSDate(new Date(keeperChange.StartDateOfKeeper)).toISO();
  });

  // Derive the end date of keeper from the dateOfPreviousDisposal
  state.vehicle.keeperChangeHistory = state.vehicle.keeperChangeHistory.map((keeperChange, index) => {
    // If the next index exists, create the end date of keeper using the dateOfPreviousDisposal
    if (state.vehicle.keeperChangeHistory[index + 1]) {
      keeperChange.EndDateOfKeeper = state.vehicle.keeperChangeHistory[index + 1].DateOfPreviousKeeperDisposal;
    }

    return keeperChange;
  });
}

export function resetToSearchVehicleView(state: BlockClaimsGateVehicleInstance) {
  // Clear fetched vehicle
  state.vehicle = null;

  // Clear previous answers
  state.vrm = "";
  state.ownershipDate = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.answer = "";
  state.BlockInputs.keeperSelectSingleSelect.answer = "";
  state.BlockInputs.dropoutSingleSelect.answer = "";

  // Clear previous block states
  state.vrmInputState = null;
  state.BlockInputs.ownershipDatePicker.state = null;
  state.BlockInputs.vehicleConfirmationSingleSelect.state = null;
  state.BlockInputs.keeperSelectSingleSelect.state = null;
  state.BlockInputs.dropoutSingleSelect.state = null;

  // Clear previous block invalid feedbacks
  state.vrmInputInvalidFeedback = "";
  state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = "";
  state.BlockInputs.ownershipDatePicker.invalidFeedback = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.invalidFeedback = "";
  state.BlockInputs.dropoutSingleSelect.invalidFeedback = "";

  // Display the vehicle select

  state.uiToggles.isKeeperSelectVisible = false;
  state.$nextTick(() => {
    state.uiToggles.showVehicleConfirmation = false;
  });

  state.$nextTick(() => {
    state.uiToggles.showOwnershipDateQuestion = false;
  });

  state.$nextTick(() => {
    state.uiToggles.isVehicleSearchVisible = true;
  });

  state.$nextTick(() => {
    state.uiToggles.isDropoutVisible = false;
  });

  // Reset the HTML text
  state.BlockInputs.confirmVehicleText.html = "";

  // Reset button loaders
  state.BlockInputs.submitVehicleConfirmationButton.isProcessing = false;
  state.BlockInputs.searchRegButton.isProcessing = false;
  state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
  state.BlockInputs.submitDropoutButton.isProcessing = false;
}

/** Returns the correct set of funnel variables */
async function _getFunnelVariables(state: BlockClaimsGateVehicleInstance) {
  let { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);
  if (JONES_WHYTE_FUNNELS.includes(state.funnelId)) {
    // If we are on a Jones Whyte funnel and the vehicle is a Mercedes
    if (state.vehicle.make.toLowerCase().includes("mercedes")) {
      const { data: _funnelVariables } = await state.funnelsService.getFunnelVariables(
        Quantum.Funnels.JONES_WHYTE_MERCEDES_EMISSIONS_ID
      );

      funnelVariables = _funnelVariables;

      // If we are on a Jones Whyte funnel and the vehicle is not a Mercedes
    } else {
      const { data: _funnelVariables } = await state.funnelsService.getFunnelVariables(
        Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID
      );

      funnelVariables = _funnelVariables;
    }
  }

  return funnelVariables;
}

/** Saves the vehicle stored in the state to the claim data service */
export async function saveVehicle(state: BlockClaimsGateVehicleInstance): Promise<boolean> {
  const funnelVariables = await _getFunnelVariables(state);

  const hashedVehicle = await state.variablesService.hashData(state.vehicle, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }

  if (await userHasClaimsForVin(state)) {
    reject(state, state.uiMessages.alreadyClaimed);
    setValidationInvalidFeedback(state, state.uiMessages.alreadyClaimed);

    return false;
  }

  const hasVoluntaryOrMandatoryRecall =
    state.vehicle.cgfDescriptionShort?.toLowerCase().includes("voluntary") ||
    state.vehicle.cgfDescriptionShort?.toLowerCase().includes("mandatory");

  if (!hasVoluntaryOrMandatoryRecall) {
    reject(state, state.uiMessages.sgDoesntQualify);
    setValidationInvalidFeedback(state, state.uiMessages.sgDoesntQualify);
    return false;
  }

  state.$store.dispatch("events/fire", { name: state.eventValues.next });

  return true;
}

async function userHasClaimsForVin(state: BlockClaimsGateVehicleInstance): Promise<boolean> {
  // const claimStatus = state.claimDataService.getArtefact("claimStatus");

  //Check that vin is not existing in this users claims
  const [claimsForUser, error] = await getClaimsByFunnelId(
    getFirebaseBackend().firestore(),
    state.userId,
    state.funnelId
  );

  if (state.claimDataService.getArtefact("claimStatus") === "testing") {
    return false;
  }

  if (error) {
    console.error("[motorspecs/methods.ts] Failed to get claims for user", error);
  }

  const funnelVariables = await _getFunnelVariables(state);

  const vinVariable = funnelVariables.find((variable) => variable.field === "vin");

  const existingClaim = claimsForUser.find((claim) => {
    if (claim.currentFunnelId !== state.funnelId) {
      return false;
    }
    // comment out this if you want testing to be included
    // if (claim.claimStatus === "testing" || claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
    //   return false;
    // }

    if (claim.claimStatus === "paused" || claim.claimStatus === "deleted" || claim.clientClaimProgress === "rejected") {
      return false;
    }

    if (claim.claimId === state.claimId) {
      return false;
    }

    return claim?.[vinVariable.id] === state.vehicle.vin;
  });

  if (!existingClaim) {
    return false;
  }

  await state.infoModalService.fire("warning", {
    title: "You have already registered a claim for this vehicle",
    text: "It looks like you already have an active claim for this vehicle. Click resume to go to your existing claim.",
    cancelButtonText: "Cancel",
    confirmButtonText: "Resume",
    onConfirm: () => {
      state.$router.push({ name: "Track", params: { claimId: existingClaim.documentId } }).then();
    },
    onHide: () => {
      console.log("Closed");
    },
  });

  return true;
}

export async function searchVehicle(state: BlockClaimsGateVehicleInstance) {
  try {
    resetSearchVehicleUiToggles(state);

    // Breaks if we were unable to validate the entered VRM
    if (!validateVrm(state)) {
      state.uiToggles.isSearchingByQueryParameters = false;
      return;
    }

    // Remove any spaces from the VRM
    const vrm = state.vrm.replace(/\s/g, "");

    const { data: claimsGateResponse } = await getVehicleFromClaimsGate(state, vrm);
    if (!(await parseClaimsGateResponse(claimsGateResponse, state))) {
      state.uiToggles.isSearchingByQueryParameters = false;
      return;
    }

    state.BlockInputs.searchRegButton.isProcessing = false;
    state.vrmInputState = null;
    state.uiToggles.isSearchingByQueryParameters = false;
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

/** Fetches the vehicle and updates the interface in the event of an error */
async function parseClaimsGateResponse(
  claimsGateResponse: ClaimsGateVehicleResponse | ClaimsGateFunctionReturnedError,
  state: BlockClaimsGateVehicleInstance
): Promise<boolean> {
  console.log("isClaimsGateFunctionReturnedError", isClaimsGateFunctionReturnedError(claimsGateResponse));
  if (isClaimsGateFunctionReturnedError(claimsGateResponse)) {
    // If the API returned a vehicle not found error, we need to check if the vehicle
    // a) could not be found
    // b) is affected by resverse cherished transfer and we require a date when the user owned the vehicle to find it
    const isClaimsGateApiVehicleNotFoundError =
      claimsGateResponse.error.id === new ClaimsGateErrors.ClaimsGateApiVehicleNotFound().id ||
      claimsGateResponse.error.id === "ClaimsGateApiVehicleNotFound";

    if (isClaimsGateApiVehicleNotFoundError) {
      resetToSearchVehicleView(state);
      state.vrmInputState = false;
      state.vrmInputInvalidFeedback = state.uiMessages.noVehicleFound;
      reject(state, state.uiMessages.noVehicleFound);
      state.BlockInputs.searchRegButton.isProcessing = false;
    } else {
      state.vrmInputState = false;
      state.vrmInputInvalidFeedback = state.uiMessages.errorFindingVehicle;
      reject(state, state.uiMessages.errorFindingVehicle);
      state.BlockInputs.searchRegButton.isProcessing = false;
    }

    return false;
  }

  console.log("claimsGateResponse", claimsGateResponse);

  const vehicle: ClaimsGatePartialVehicleStore = {
    make: claimsGateResponse.VehicleData.Combined_Make,
    model: claimsGateResponse.VehicleData.Combined_Model,
    colour: claimsGateResponse.VehicleData.Colour,
    vin: claimsGateResponse.VehicleData.Combined_VIN,
    vehicleRegistration: claimsGateResponse.VehicleData.VRM_Curr,
    fuelType: claimsGateResponse.VehicleData.Combined_FuelType,
    numberOfCylinders: parseInt(claimsGateResponse.VehicleData.NumberOfCylinders),
    engineCapacity: parseInt(claimsGateResponse.VehicleData.EngineCapacity),

    dateFirstRegistered: claimsGateResponse.VehicleData.DateFirstRegistered,
    engineNumber: claimsGateResponse.VehicleData.EngineNumber,
    engineModelCode: claimsGateResponse.VehicleData.EngineModelCode,
    series: claimsGateResponse.VehicleData.ModelSeries,
    maximumPowerInKw: parseInt(claimsGateResponse.VehicleData.MaximumPowerInKW),
    vinLast5: claimsGateResponse.VehicleData.Combined_VIN.substring(
      claimsGateResponse.VehicleData.Combined_VIN.length - 5
    ),
    yearOfManufacture: claimsGateResponse.VehicleData.YearOfManufacture,
    keeperChangeHistory: claimsGateResponse.VehicleData.KeeperChangeHistory.KeeperChange,
    cgfDescriptionLong: claimsGateResponse.ClaimsGateFields.Description_Long,
    cgfDescriptionShort: claimsGateResponse.ClaimsGateFields.Description_Short,
    cgfDescriptionSource: claimsGateResponse.ClaimsGateFields.Description_Source,
    cgfKeeper25May2018: claimsGateResponse.ClaimsGateFields.Keeper_25_May_2018,
    cgfVrmCurrStyle: claimsGateResponse.ClaimsGateFields.VRM_Curr_Style,
    cgfPrivateRegistration: claimsGateResponse.ClaimsGateFields.PrivateRegistration,
    vehicleDataSource: "ClaimsGate",
  };

  const compactedVehicle: ClaimsGatePartialVehicleStore = omitBy(vehicle, isNil);

  // Using a vue setter to prevenet reactivity issues
  state.$set(state, "vehicle", compactedVehicle);

  // Show the vehicle confirmation step

  state.uiToggles.showVehicleConfirmation = true;

  state.$nextTick(() => {
    state.uiToggles.isVehicleSearchVisible = false;
  });

  state.BlockInputs.searchRegButton.isProcessing = false;

  // If no error was returned, then we can save the vehicle data to our database

  return true;
}

/** Returns a boolean indcating if the entered vrm is valid */
function validateVrm(state: BlockClaimsGateVehicleInstance): boolean {
  if (state.vrm.length === 0) {
    state.vrmInputState = false;
    state.vrmInputInvalidFeedback = state.uiMessages.enterReg;
    reject(state, state.uiMessages.enterReg);
    state.BlockInputs.searchRegButton.isProcessing = false;
    return false;
  }

  return true;
}

/** Resets the states of inputs and processing state when on the search vehicle view */
function resetSearchVehicleUiToggles(state: BlockClaimsGateVehicleInstance) {
  state.BlockInputs.searchRegButton.isProcessing = true;
  state.BlockInputs.ownershipDatePicker.state = null;
  state.vrmInputState = null;
}

/** Type guard to check if the response returned from the ClaimsGate API is an error */
function isClaimsGateFunctionReturnedError(
  response: ClaimsGateVehicleResponse | ClaimsGateFunctionReturnedError
): response is ClaimsGateFunctionReturnedError {
  console.log("isClaimsGateReturnedError", response, response as ClaimsGateFunctionReturnedError);
  return (response as ClaimsGateFunctionReturnedError)?.error?.id !== undefined;
}

/** Fetches a given vehicle from the Claims Gate Vehicle API */
async function getVehicleFromClaimsGate<F = "getVehicleFromClaimsGate">(
  state: BlockClaimsGateVehicleInstance,
  vid: string
): Promise<{ data?: ClaimsGateVehicleResponse | ClaimsGateFunctionReturnedError }> {
  return onCallGateway<"getVehicleFromClaimsGate">({
    functionName: "getVehicleFromClaimsGate",
    data: {
      vid,
      claimId: state.claimId,
      funnelId: state.funnelId,
      pageId: state.pageId,
    },
  });
}

// Create a generic try catch handler which will log the error and reset the view to the search vehicle view
function tryCatchHandler(state: BlockClaimsGateVehicleInstance, error: Error) {
  console.error(error);
  resetToSearchVehicleView(state);

  state.vrmInputState = false;
  state.vrmInputInvalidFeedback = state.uiMessages.errorFindingVehicle;
  reject(state, state.uiMessages.errorFindingVehicle);

  fireClaimsGateVehicleApiError(state);
}
