import { getFirebaseBackend } from "@/authUtils";
import * as Sentry from "@sentry/vue";
import { captureMessage } from "@sentry/vue";

/** Fires a proactive Sentry log including the VRM called 'CarwebVrmEntered' release number */
export function fireCarwebVrmEntered(vrm: string) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", vrm);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebVrmEntered");
  });
}

/** Fires a proactive Sentry log including the VRM called 'CarwebVrmEntered' release number */
export function fireCarwebApiError(vrm: string) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", vrm);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebApiError");
  });
}

/** Fires a proactive Sentry log including the VRM called 'CarwebVehicleFound' release number */
export function fireCarwebVehicleFound(vrm: string) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", vrm);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebVehicleFound");
  });
}

/** Fires a proactive Sentry log including the VRM called 'CarwebVehicleNotFound' release number */
export function fireCarwebVehicleNotFound(vrm: string) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", vrm);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebVehicleNotFound");
  });
}
