import firebase from "firebase/compat/app";
// Add the Firebase products that you want to use
import "firebase/compat/auth";
//import "firebase/analytics";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/remote-config";
import store from "@/state/store"; // eslint-disable-line

import { UserDataService } from "./helpers/ClaimsGate/DataService";

var diff = function (x, y) {
  var target = {};
  Object.keys(x)
    .filter(function (i) {
      if (x[i] !== y[i]) {
        return true;
      }
      return false;
    })
    .map(function (j) {
      var obj = {};
      obj[j] = x[j];
      target = Object.assign(target, obj);
    });
  return target;
};

class FirebaseAuthBackend {
  //WARNING: THIS CODE SHOULD BE SHIPPED
  /**@type {firebase.firestore.Firestore} */
  firestoreInstance = null;
  /**@type {firebase.storage.Storage} */
  storageInstance = null;
  /**@type {firebase.auth.Auth} */
  authInstance = null;
  /**@type {firebase.functions.Functions} */
  functionsInstance = null;

  constructor(firebaseConfig) {
    if (firebaseConfig) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  upgradeAnonToUser = (email, password) => {
    return new Promise((resolve, reject) => {
      // Handle users who were anonymous and have now signed in with an email link
      // Links password to firebase user to enable new Auth Provider credential 'password'
      firebase
        .auth()
        .fetchSignInMethodsForEmail(email)
        .then(function (signInMethods) {
          if (signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
            firebase
              .auth()
              .currentUser.updatePassword(password)
              .then(() => {
                window.console.log("pass updated");
              });
          } else {
            var credential = firebase.auth.EmailAuthProvider.credential(email, password);
            firebase
              .auth()
              .currentUser.linkWithCredential(credential)
              .then(function (usercred) {
                var user = usercred.user;
                window.console.log("Anonymous account successfully upgraded", user);
                resolve(user);
              })
              .catch(function (error) {
                window.console.log("Error upgrading anonymous account", error);
                reject(error);
              });
          }
          resolve(firebase.auth().currentUser);
        });
    });
  };

  signInAnonymously = () => {
    return new Promise((resolve, reject) => {
      window.console.log("about to use anon sign in!");
      firebase
        .auth()
        .signInAnonymously()
        .then((user) => {
          window.console.log("signed in!", user);
          // eslint-disable-next-line no-redeclare
          var user = firebase.auth().currentUser;
          resolve(user);
        })
        .catch((error) => {
          // Handle Errors here.
          reject(error.code, error.message);
          //var errorCode = error.code;
          //var errorMessage = error.message;
          // ...
        });
    });
  };

  /**
   * Registers the user with given details
   */
  registerUser = (email, password, userdata) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          async (user) => {
            // eslint-disable-next-line no-redeclare
            var user = firebase.auth().currentUser;
            var userCollection = await this.getUserCollection();
            getFirebaseBackend()
              .firestore()
              .collection(userCollection)
              .doc(user.uid)
              .set(userdata)
              .then(() => {
                localStorage.setItem("store.currentUser", JSON.stringify(userdata));
                resolve(user);
              });
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {
            // eslint-disable-next-line no-redeclare
            var user = firebase.auth().currentUser;
            resolve(user);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url: window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  customTokenLogin = (token) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithCustomToken(token)
        .then(
          (user) => {
            // eslint-disable-next-line no-redeclare
            var user = firebase.auth().currentUser;
            resolve(user);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };
  /**
   * Send user headless authentication login link
   */
  /*eslint-disable*/
  sendEmailLogin = (email) => {
    var actionCodeSettings;
    if (process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
      actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: "https://claimsgate.co.uk/continue",
        handleCodeInApp: true,
      };
    } else if (process.env.VUE_APP_PROJECTId === "dev-claims-cake") {
      actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: "https://dev-claims-cake.web.app/continue",
        handleCodeInApp: true,
      };
    } else {
      throw new Error("Unkown VUE_APP_PROJECTId");
    }
    return new Promise((resolve) => {
      window.console.log(email, actionCodeSettings);
      firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(function () {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
          // Some error occurred, you can inspect the code: error.code
        });
    });
  };

  tryEmailSignInLink = () => {
    return new Promise((resolve, reject) => {
      // Confirm the link is a sign-in with email link.
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        var email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt("Please provide your email for confirmation");
        }

        // The client SDK will parse the code from the link for you.
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(function (result) {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            // Construct the email link credential from the current URL.

            resolve(result.user);
          })
          .catch(function (error) {
            reject(error);
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  updateEmail = (email) => {
    return new Promise((resolve, reject) => {
      try {
        const result = firebase.auth().currentUser.updateEmail(email);

        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  getUserCollection = async () => {
    //return this.userCollection;
    var validUser = await store.dispatch("auth/validate");
    if (validUser) {
      return "users";
    }
  };

  firestoreClass = () => {
    return firebase.firestore;
  };

  firebase = () => {
    return firebase;
  };

  firestore = () => {
    if (!this.firestoreInstance) {
      try {
        if (window._fb_shims === undefined) {
          window._fb_shims = [
            firebase.firestore.CollectionReference.prototype.get,
            firebase.firestore.DocumentReference.prototype.get,
            firebase.firestore.DocumentReference.prototype.update,
            firebase.firestore.DocumentReference.prototype.set,
            firebase.firestore.DocumentReference.prototype.delete,
          ];

          const removeShims = () => {
            firebase.firestore.CollectionReference.prototype.get = window._fb_shims[0];
            firebase.firestore.DocumentReference.prototype.get = window._fb_shims[1];
            firebase.firestore.DocumentReference.prototype.update = window._fb_shims[2];
            firebase.firestore.DocumentReference.prototype.set = window._fb_shims[3];
            firebase.firestore.DocumentReference.prototype.delete = window._fb_shims[4];
            window._fb_shims = null;
          };

          firebase.firestore.CollectionReference.prototype.get = function () {
            try {
              console.log(
                `[CollectionReference shim] calling get with path: ${this._delegate._path.segments.join("/")}`,
                "\nStack trace:",
                getStackTrace(),
                "\nContext:",
                this
              );
            } catch (e) {
              console.error("[shim engine] Disabling shims due to error!", e);
              removeShims();
            }
            return window._fb_shims[0].apply(this, arguments);
          };

          firebase.firestore.DocumentReference.prototype.get = async function () {
            try {
              console.log(
                `[DocumentReference shim] calling get with path: ${this._delegate._key.path.segments.join("/")}`,
                "\nStack trace:",
                getStackTrace(),
                "\nContext:",
                this
              );
            } catch (e) {
              console.error("[shim engine] Disabling shims due to error!", e);
              removeShims();
            }
            return window._fb_shims[1].apply(this, arguments);
          };

          firebase.firestore.DocumentReference.prototype.update = function () {
            try {
              console.log(
                `[DocumentReference shim] calling update with path: ${this._delegate._key.path.segments.join("/")}`,
                "\nStack trace:",
                getStackTrace(),
                "\nContext:",
                this
              );
            } catch (e) {
              console.error("[shim engine] Disabling shims due to error!", e);
              removeShims();
            }
            return window._fb_shims[2].apply(this, arguments);
          };

          firebase.firestore.DocumentReference.prototype.set = function () {
            try {
              console.log(
                `[DocumentReference shim] calling set with path: ${this._delegate._key.path.segments.join("/")}`,
                "\nStack trace:",
                getStackTrace(),
                "\nContext:",
                this
              );
            } catch (e) {
              console.error("[shim engine] Disabling shims due to error!", e);
              removeShims();
            }
            return window._fb_shims[3].apply(this, arguments);
          };

          firebase.firestore.DocumentReference.prototype.delete = function () {
            try {
              console.log(
                `[DocumentReference shim] calling delete with path: ${this._delegate._key.path.segments.join("/")}`,
                "\nStack trace:",
                getStackTrace(),
                "\nContext:",
                this
              );
            } catch (e) {
              console.error("[shim engine] Disabling shims due to error!", e);
              removeShims();
            }
            return window._fb_shims[4].apply(this, arguments);
          };
        }
      } catch (e) {
        console.error("[shim engine] Failed to place shims!", e);
        window._fb_shims = null; // disable in case of error!
      }

      const getStackTrace = () => {
        try {
          const stack = new Error().stack;
          if (!stack) {
            return "[Stack trace unavailable]";
          }
          // Get the first two meaningful callers (skip Error and this function)
          const callers = stack.split("\n").slice(2, 4);
          if (!callers || !callers.length) {
            return "[Stack trace unavailable]";
          }
          // Remove leading spaces/tabs if any and filter out any empty/null values
          return (
            callers
              .filter((caller) => caller)
              .map((caller) => caller.trim())
              .join("\n") || "[Stack trace unavailable]"
          );
        } catch (error) {
          console.warn("[getStackTrace] Error getting stack trace:", error);
          return "[Stack trace unavailable]";
        }
      };

      this.firestoreInstance = firebase.firestore();
      if (process.env.VUE_APP_FIREBASE_EMULATORS === "on") {
        window.console.log("Using firestore emulator!");
        this.firestoreInstance.useEmulator("localhost", 8080);
      }
    }

    return this.firestoreInstance;
  };

  firebaseAuth = () => {
    if (!this.authInstance) {
      this.authInstance = firebase.auth();
      if (process.env.VUE_APP_FIREBASE_EMULATORS === "on") {
        this.authInstance.useEmulator("http://localhost:9099");
      }
    }
    return this.authInstance;
  };

  firebaseAuthClass = () => {
    return firebase.auth;
  };

  firebaseAnalytics = () => {
    return firebase.analytics();
  };

  firebaseFunctions = () => {
    //return firebase.app().functions('europe-west2');
    if (!this.functionsInstance) {
      this.functionsInstance = firebase.app().functions("europe-west2");
      if (process.env.VUE_APP_FIREBASE_EMULATORS === "on" || process.env.VUE_APP_FORCE_LOCAL_FUNCTIONS === "true") {
        this.functionsInstance.useFunctionsEmulator("http://localhost:5001");
      }
    }

    return this.functionsInstance;
  };

  firebaseRealtime = () => {
    window.console.log(process.env.VUE_APP_DATABASEURL);
    return firebase.database();
  };

  firebaseStorage = () => {
    if (!this.storageInstance) {
      this.storageInstance = firebase.storage();
      if (process.env.VUE_APP_FIREBASE_EMULATORS === "on") {
        this.storageInstance.useEmulator("localhost", 9199);
      }
    }

    return this.storageInstance;
  };

  /*setLoggeedInUser = (user) => {
      //sessionStorage.setItem("authUser", JSON.stringify(user));
      localStorage.setItem('auth.currentUser', JSON.stringify(user))
  }*/

  /**
   * Returns the authenticated user
   * @returns { { email: string, phoneNumber: string , uid: string, [key: string] : any } }
   */
  getAuthenticatedUser = () => {
    var authUser = localStorage.getItem("auth.currentUser");
    if (!authUser) return null;
    return JSON.parse(authUser);
  };

  setUserData = (data) => {
    return new Promise(async (resolve, reject) => {
      var dataUser = localStorage.getItem("store.currentUser");
      if (!dataUser) dataUser = "{}";
      //return resolve(localStorage.setItem('store.currentUser', JSON.stringify(data)));
      dataUser = JSON.parse(dataUser);
      var updateDoc = diff(data, dataUser);
      Object.entries(updateDoc).reduce(
        (a, [k, v]) => (v !== null && v !== undefined && v !== "" ? ((a[k] = v), a) : a),
        {}
      );
      /*if(Object.keys(updateDoc).length == 0)
        updateDoc = data;
      */
      //window.console.log(updateDoc);
      if (Object.keys(updateDoc).length > 0) {
        var userCollection = await this.getUserCollection();
        getFirebaseBackend()
          .firestore()
          .collection(userCollection)
          .doc(getFirebaseBackend().getAuthenticatedUser().uid)
          .set(updateDoc, { merge: true })
          .then(() => {
            var y = Object.assign(dataUser, data);
            //window.console.log(y);
            localStorage.setItem("store.currentUser", JSON.stringify(y));
            return resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      } else {
        return resolve(false);
      }
    });
  };

  getUserData = () => {
    var dataUser = localStorage.getItem("store.currentUser");
    if (!dataUser) return null;

    var user = JSON.parse(dataUser);
    if (user.firstname)
      user.fullname = user.lastname.length > 0 ? `${user.firstname} ${user.lastname}` : user.firstname;
    return user;
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 * @returns { FirebaseAuthBackend }
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

let hasOnAuthStateChangeInitalised = false;

/**
 * This function creates an initial state change listener for Firebase authentication.
 * It loads cached user data on sign in and selects a workspace when the user becomes authenticated.
 * If the user is not authenticated, it removes the user data from local storage.
 */
const createInitialOnAuthStateChanged = async () => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user?.uid) {
      // Load in a cached version of the user data on sign in
      const userDataService = new UserDataService(user.uid);
      await userDataService.refresh();

      if (!userDataService.getArtefact("workspaces")) {
        await store.dispatch("permissions/clearWorkspaceData");
      }
      // Select a workspace when the user becomes authenticated (if it is not selected already)
      const workspaceId = await store.dispatch("permissions/getWorkspaceIdVueX");
      if (workspaceId) {
        await store.dispatch("permissions/setWorkspaceId", workspaceId);
      }
    } else {
      localStorage.removeItem("store.currentUser");
    }
  });
  hasOnAuthStateChangeInitalised = true;
};

/**
 * Returns the firebase backend
 * @returns { FirebaseAuthBackend }
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend, createInitialOnAuthStateChanged, FirebaseAuthBackend };
