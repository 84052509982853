<script lang="ts">
import Vue from "vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";

export default Vue.extend({
  name: "BlockCarouselWidget",
  components: {
    BlockPadding,
  },

  props: {
    padding: {
      type: String,
      required: false,
      default: "10",
    },
    store: {
      type: String,
      required: true,
    },
    // Add more props for customization as needed
  },

  data() {
    return {
      widgetId: "reviewsio-carousel-widget",
    };
  },

  async mounted(): Promise<void> {
    await this.loadExternalResources();
    this.$nextTick(() => {
      this.initializeWidget();
      this.$nextTick(() => {
        this.applyWidgetStyles();
      });
    });
  },

  methods: {
    async loadExternalResources(): Promise<void> {
      try {
        const script = document.createElement("script");
        script.src = "https://widget.reviews.io/carousel-inline-iframeless/dist.js?_t=2024080112";
        await new Promise<void>((resolve, reject) => {
          script.onload = () => resolve();
          script.onerror = () => reject(new Error("Failed to load script"));
          document.head.appendChild(script);
        });

        const stylesheets = [
          "https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2024080112",
          "https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2024080112",
        ];

        await Promise.all(
          stylesheets.map((href) => {
            return new Promise<void>((resolve, reject) => {
              const link = document.createElement("link");
              link.rel = "stylesheet";
              link.href = href;
              link.onload = () => resolve();
              link.onerror = () => reject(new Error(`Failed to load stylesheet: ${href}`));
              document.head.appendChild(link);
            });
          })
        );
      } catch (error) {
        console.error("Error loading external resources:", error);
      }
    },

    initializeWidget(): void {
      try {
        if (window["carouselInlineWidget"]) {
          const targetElement = document.getElementById("reviewsio-carousel-widget");
          if (!targetElement) {
            throw new Error("Target element for carousel widget not found");
          }

          new window["carouselInlineWidget"]("reviewsio-carousel-widget", {
            store: "jointheclaim.com",
            sku: "",
            lang: "en",
            carousel_type: "topHeader",
            styles_carousel: "CarouselWidget--topHeader",

            options: {
              general: {
                /*What reviews should the widget display? Available options: company, product, third_party. You can choose one type or multiple separated by comma.*/
                review_type: "company",
                /*Minimum number of reviews required for widget to be displayed*/
                min_reviews: "1",
                /*Maximum number of reviews to include in the carousel widget.*/
                max_reviews: "20",
                address_format: "CITY, COUNTRY",
                /*Carousel auto-scrolling speed. 3000 = 3 seconds. If you want to disable auto-scroll, set this value to false.*/
                enable_auto_scroll: 10000,
              },
              header: {
                /*Show overall rating stars*/
                enable_overall_stars: true,
                rating_decimal_places: 2,
              },
              reviews: {
                /*Show customer name*/
                enable_customer_name: true,
                /*Show customer location*/
                enable_customer_location: true,
                /*Show "verified review" badge*/
                enable_verified_badge: true,
                /*Show "verified subscriber" badge*/
                enable_subscriber_badge: true,
                /*Show "I recommend this product" badge (Only for product reviews)*/
                enable_recommends_badge: true,
                /*Show photos attached to reviews*/
                enable_photos: false,
                /*Show videos attached to reviews*/
                enable_videos: false,
                /*Show when review was written*/
                enable_review_date: true,
                /*Hide reviews written by the same customer (This may occur when customer reviews multiple products)*/
                disable_same_customer: true,
                /*Minimum star rating*/
                min_review_percent: 4,
                /*Show 3rd party review source*/
                third_party_source: true,
                /*Hide reviews without comments (still shows if review has a photo)*/
                hide_empty_reviews: true,
                /*Show product name*/
                enable_product_name: false,
                /*Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2)*/
                tags: "home",
                /*Show branch, only one input*/
                branch: "",
                enable_branch_name: false,
              },
            },

            styles: {},
          });
          console.log("Carousel widget initialized successfully");

          this.applyWidgetStyles();
        } else {
          throw new Error("carouselInlineWidget is not defined");
        }
      } catch (error) {
        console.error("Error initializing carousel widget:", error);
      }
    },

    applyWidgetStyles(): void {
      try {
        const widgetElement = document.getElementById("reviewsio-carousel-widget");
        if (!widgetElement) {
          throw new Error("Widget element not found for style application");
        }

        const styles = {
          "--base-font-size": "16px",
          "--base-maxwidth": "100%",
          "--reviewsio-logo-style": "var(--logo-normal)",
          "--list-star-color": "#fcb44b",
          "--common-star-disabled-color": "rgba(0,0,0,0.25)",
          "--medium-star-size": "22px",
          "--small-star-size": "19px",
          "--x-small-star-size": "16px",
          "--x-small-star-display": "inline-flex",
          "--small-star-color": "#fcb44b",
          "--header-order": "1",
          "--header-width": "160px",
          "--header-padding": "0.5em",
          "--header-star-color": "#fcb44b",
          "--header-heading-text-color": "#173c50",
          "--header-subheading-text-color": "#173c50",
          "--item-maximum-columns": "5",
          "--item-background-start-color": "#ffffff",
          "--item-padding": "1.5em",
          "--item-border-radius": "5px",
          "--item-shadow-size": "3px",
          "--item-shadow-color": "rgba(0,0,0,0.1)",
          "--heading-text-color": "#173c50",
          "--heading-text-font-weight": "600",
          "--heading-text-line-height": "1.4",
          "--body-text-color": "#173c50",
          "--body-text-line-height": "1.4",
          "--scroll-button-icon-color": "#0E1311",
          "--scroll-button-icon-size": "24px",
          "--badge-icon-color": "#0E1311",
          "--badge-icon-font-size": "15px",
          "--badge-text-color": "#173c50",
          "--photo-video-thumbnail-size": "60px",
          "--popup-backdrop-color": "rgba(0,0,0,0.75)",
          "--popup-color": "#ffffff",
          "--popup-badge-icon-font-size": "19px",
          "--popup-badge-text-font-size": "14px",
          "--popup-icon-color": "#0E1311",
          "--tooltip-bg-color": "#0E1311",
        };

        Object.entries(styles).forEach(([key, value]) => {
          widgetElement.style.setProperty(key, value);
        });

        // Add Bootstrap utility classes
        widgetElement.classList.add("w-100", "mx-auto", "px-3");

        const styleElement = document.createElement("style");
        styleElement.textContent = `
        #reviewsio-carousel-widget {
          max-width: var(--carousel-container-max-width, 1200px);
        }
        #reviewsio-carousel-widget .R-ReviewsList {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          scroll-snap-type: x mandatory;
          padding: 20px 0;
          -webkit-overflow-scrolling: touch;
        }
        #reviewsio-carousel-widget .R-ReviewsList__item {
          flex: 0 0 auto;
          width: calc(100% - 20px);
          max-width: 389px;
          margin-right: 20px;
          scroll-snap-align: start;
          background-color: white;
          border-radius: 8px;
          padding: 15px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.1);
        }
        #reviewsio-carousel-widget .R-ReviewsList__item--body {
          max-height: 100px;
          overflow-y: auto;
        }
        #reviewsio-carousel-widget .R-RatingStars__stars .stars__icon {
          color: gold !important;
        }
        @media (min-width: 768px) {
          #reviewsio-carousel-widget .R-ReviewsList__item {
            width: calc(75% - 20px);
          }
        }
        @media (min-width: 1024px) {
          #reviewsio-carousel-widget .R-ReviewsList__item {
            width: 375px
          }
        }
      `;
        document.head.appendChild(styleElement);

        console.log("Styles applied to widget and its children");
      } catch (error) {
        console.error("Error applying widget styles:", error);
      }
    },
  },
});
</script>

<template>
  <div>
    <BlockPadding :padding="padding" />
    <div id="reviewsio-carousel-widget"></div>
  </div>
</template>
