import { Props } from "@/types";
import { MotorSpecsVehicleStore } from "../motorSpecs/types";

export function getProps() {
  return {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,
    returnMercedesRecallData: {
      type: Boolean,
      default: () => false,
      required: false,
      allowVariable: false,
      description: "Set this to true if you want to return the Mercedes recall data",
    } as Props.CGPropExtras<boolean>,

    stores: {
      type: Array,
      required: false,
      default: (): Array<{ name: MotorSpecsVehicleStore; type: string }> => [
        { name: "emCode", type: "number" },
        { name: "model", type: "string" },
        { name: "make", type: "string" },
        { name: "version", type: "string" },
        { name: "keeperChangeHistory", type: "array" },
        { name: "keeperStartDate", type: "date" },
        { name: "keeperEndDate", type: "date" },
        { name: "isCurrentKeeper", type: "boolean" },
        { name: "dateFirstRegistered", type: "date" },
        { name: "engineModelCode", type: "array" },
        { name: "engineLocation", type: "array" },
        // { name: "engineFamily", type: "array" },
        { name: "fuelType", type: "string" },
        { name: "vehicleRegistration", type: "string" },
        { name: "vin", type: "string" },
        { name: "engineNumber", type: "string" },

        { name: "numberOfDoors", type: "string" },
        // { name: "keeperChangeCount", type: "number" },
        { name: "euroStatus", type: "string" },
        { name: "yearOfManufacture", type: "number" },
        { name: "vinLast5", type: "number" },
        { name: "series", type: "string" },
        { name: "numberOfCylinders", type: "number" },
        { name: "engineCapacity", type: "number" },
        { name: "maximumPowerInKw", type: "string" },
        { name: "bhp", type: "number" },
        { name: "torqueNm", type: "number" },
        { name: "colour", type: "string" },
        { name: "recalls", type: "array" },
        { name: "recallsCount", type: "number" },
        { name: "mercedesEmissionsRecallMandatoryStatus", type: "string" },
        { name: "mercedesEmissionsRecallEligibleForExclusion", type: "boolean" },
        { name: "mercedesEmissionsRecallAccomplished", type: "boolean" },
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<{ name: MotorSpecsVehicleStore; type: string }>>,
  };
}
