export default {
  lastUpdatedOn: "Tuntutan terakhir dikemaskinikan pada",
  discoverButton: "Mula",
  discoverHeading: "Ketahui Tuntutan dengan Tuntutan",

  claimCompleted:
    "Tuntutan anda telah selesai, ini bermaksud, tiada butiran lanjut diperlukan daripada anda pada masa ini.  Anda akan menerima kemas kini yang kerap mengenai status tuntutan anda di sini dan melalui e-mel.",
  startNew: "Mulakan Tuntutan Baharu",
  status: "Status",
  contactUs: "Hubungi Kami",
  hereToHelp: "Pasukan kami sedia membantu anda. Klik di sini untuk menghantar mesej kepada kami.",
  contact: "Hubungi",
  sendUsAMessage: "Hantarkan mesej kepada kami dan kami akan menghubungi anda secepat mungkin.",
  name: "Nama",
  email: "E-mel",
  message: "Mesej",
  send: "Hantar",
  cancel: "Batal",
  view: "Lihat",
  files: "Fail",

  history: "Sejarah",
  myClaims: "Tuntutan Saya",
  updatedOn: "Dikemaskinikan pada",
  finish: "Selesai",
  notComplete: "Anda belum menyelesaikan semua langkah yang diperlukan untuk tuntutan anda.",
  continueMyClaim: "Teruskan tuntutan saya",
  mySettings: "Tetapan Saya",

  identityPicture1: "Gambar Identiti 1",
  identityPicture2: "Gambar Identiti 2",
  shareLink: "Kongsi Pautan",
  copyLink: "Salin Pautan",
  withdrawInterest: "Tarik Balik Kepentingan",

  information: "Maklumat",
  clickToGo: "Klik di sini untuk pergi ke",
  homePage: "halaman utama",
  shareThe: "Kongsi",
  withSomeone: "dengan seseorang yang mungkin layak.",
  pageCompleted: "Halaman selesai",
};
