export default {
  me: "Saya",
  myClaims: "Tuntutan Saya",
  menu: {
    myDetails: "Butiran Saya",
    settings: "Tetapan",
    legal: "Undang-undang",
    logOut: "Log keluar",
    logIn: "Log masuk",
  },
};
