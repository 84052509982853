export default {
  signInToAccess: "Masuk, akses akun Anda dengan Gugatan",
  signInMethod: "Cara Masuk",
  selectSignInMethod: "Pilih cara masuk",
  continue: "Lanjutkan ",
  welcomeBack: "Selamat Datang Kembali!",
  emailPlaceholder: "Email",
  emailLabel: "Email",
  phonePlaceholder: "Telepon",
  phoneLabel: "Telepon",
  enterYourPassword: "Masukkan kata sandi Anda",
  enterPasswordLink: "Masukkan kata sandi yang ditautkan ke",
  back: "Kembali",
  passwordPlaceholder: "Kata Sandi",
  passwordLabel: "Kata Sandi",
  forgotPassword: "Lupa kata sandi?",
  signIn: "Masuk",
  showPassword: "Tampilkan Kata Sandi ",
  createPasswordHeading: "Buat Kata Sandi Anda",
  forgotPasswordHeading: "Lupa kata sandi?",
  createPasswordSupporting: "Silakan buat kata sandi baru untuk akun Anda dengan Gugatan",
  confirmLabel: "Sahkan",
  confirmPlaceholder: "Sahkan",
  incorrectPassword: "Kata laluan salah. Sila cuba lagi.",
  otpModalHeading: "Kode salah",
  otpModalSupporting: "Masukkan kode yang telah dikirimkan ke:",
  otpModalInfo:
    "Anda mungkin perlu menyemak folder spam anda. Jika anda mengalami masalah menerima kod, sila kembali dan cuba menggunakan kaedah log masuk alternatif",
  otpModalCodeInvalid: "Kod yang anda masukkan tidak sah",
};
