<script lang="ts">
// Code Breakdown
import { methods, getProps, getData, computed, mappedComputed, AddressInstance } from "./AddressInstance";
import { mounted } from "./mounted";
import { blocks } from "./data";

// Components
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockLoadingBar from "@/components/shared/layout/loadingBar.vue";
import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";

import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";

import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDateOfBirth from "@/components/shared/blocks/user/dateofbirth.vue";
import BlockLink from "@/components/shared/blocks/link.vue";
import Vue from "vue";

export default Vue.extend({
  components: {
    BlockInput,
    BlockDropdown,
    BlockButton,
    BlockLoadingBar,
    BlockDateOfBirth,
    BlockSingleSelect,
    BlockLink,
    BlockCheckbox,
    BlockPadding,
  },
  props: { ...getProps() },
  data() {
    return { ...getData(this) };
  },
  methods: {
    emitSubmit() {
      return methods.emitSubmit(this as any as AddressInstance);
    },
    placeSelected(place: any) {
      return methods.placeSelected(this as any as AddressInstance, place);
    },

    searchAddressByPostcode() {
      return methods.searchAddressByPostcode(this as any as AddressInstance);
    },
    allFieldsDefined() {
      return methods.allFieldsDefined(this as any as AddressInstance);
    },
    setLookupMode() {
      return methods.setLookupMode(this as any as AddressInstance);
    },

    selectAddress(event: string) {
      return methods.selectAddress(this as any as AddressInstance, event);
    },
    setAndDisableCompletedFields() {
      return methods.disableCompletedFields(this as any as AddressInstance);
    },
    postcodeSearchSuccess() {
      return methods.postcodeSearchSuccess(this as any as AddressInstance);
    },
    setAddressManually() {
      return methods.setAddressManually(this as any as AddressInstance);
    },
    displayWholeLine() {
      return methods.displayWholeLine(this as any as AddressInstance);
    },
  },
  computed: {
    ...mappedComputed(),

    displayOtherInput: {
      get(): boolean {
        return computed.displayOtherInput(this as any as AddressInstance);
      },
    },

    loaderState(): boolean {
      return computed.loaderState(this as any as AddressInstance);
    },
    titleAnswer(): string {
      return computed.titleAnswer(this as any as AddressInstance);
    },
    otherAnswer(): string {
      return computed.otherAnswer(this as any as AddressInstance);
    },
    dateOfBirthAnswer(): any {
      return computed.dateOfBirthAnswer(this as any as AddressInstance);
    },
    userIsVerified(): boolean {
      return computed.userIsVerified(this as any as AddressInstance);
    },
  },
  watch: {
    dropdownSelectAnswer: function (newVal) {
      if (newVal) {
        methods.selectAddress(this as any as AddressInstance, newVal);
      }
    },
    padding: {
      handler: function (newPadding) {
        blocks.forEach((block) => {
          this.BlockInputs[block].padding = newPadding;
        });
      },
      immediate: true,
    },
    themeSize: function (newVal) {
      blocks.forEach((block) => {
        this.BlockInputs[block].size = newVal;
      });

      this.BlockInputs.postcodeSearchBlock.size = newVal;
    },
    titleAnswer: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (newVal === "Other") {
          this.user.title = this.otherAnswer;
        } else {
          this.user.title = newVal;
        }
      }
    },
    otherAnswer: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal && this.titleAnswer === "Other") {
        this.user.title = newVal;
      }
    },
    dateOfBirthAnswer: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.user.dateOfBirth = this.dateService.parseDate(newVal);
      }
    },
  },
  async mounted() {
    await mounted(this as any as AddressInstance);
  },
});
</script>

<template>
  <div>
    <!--<BlockPadding :padding="padding" />-->
    <template v-if="uiToggles.pageLoading">
      <BlockLoadingBar text="Loading..." />
    </template>
    <template v-else>
      <template v-if="collectName">
        <b-row>
          <!-- Title -->
          <template v-if="title">
            <template v-if="(BlockInputs.title.answer === 'Other' && allowOtherTitle) || displayOtherInput">
              <b-col cols="6">
                <BlockDropdown
                  v-bind="BlockInputs.title"
                  v-bind.sync="BlockInputs.title"
                  data-cy="kyc-title-dropdown"
                />
              </b-col>
              <b-col cols="6">
                <BlockInput
                  v-bind="BlockInputs.other"
                  v-bind.sync="BlockInputs.other"
                  data-cy="kyc-title-other-input"
                />
              </b-col>
            </template>
            <template v-else>
              <b-col cols="6">
                <BlockDropdown
                  v-bind="BlockInputs.title"
                  v-bind.sync="BlockInputs.title"
                  data-cy="kyc-title-dropdown"
                />
              </b-col>
            </template>
          </template>

          <!-- First Name -->
          <b-col cols="6">
            <BlockInput
              v-bind="BlockInputs.firstName"
              v-bind.sync="BlockInputs.firstName"
              :answer.sync="user.firstName"
              data-cy="kyc-first-name-input"
            />
          </b-col>

          <!-- Middle Name -->
          <template v-if="middleName">
            <b-col cols="6">
              <BlockInput
                v-bind="BlockInputs.middleName"
                v-bind.sync="BlockInputs.middleName"
                :answer.sync="user.middleName"
                data-cy="kyc-middle-name-input"
              />
            </b-col>
          </template>

          <!-- Last Name -->
          <b-col :cols="displayWholeLine() ? 12 : 6">
            <BlockInput
              v-bind="BlockInputs.lastName"
              v-bind.sync="BlockInputs.lastName"
              :answer.sync="user.lastName"
              data-cy="kyc-last-name-input"
            />
          </b-col>
        </b-row>
      </template>

      <!-- DOB -->
      <template v-if="collectDateOfBirth">
        <BlockDateOfBirth
          v-bind="BlockInputs.dateOfBirth"
          v-bind.sync="BlockInputs.dateOfBirth"
          :requireAgeCheck="requireAgeCheck"
          :minAge="minAge"
          :maxAge="maxAge"
          :isChild="true"
          :ref="'blockDateOfBirth'"
          :userIsVerified="userIsVerified"
          data-cy="kyc-dob-input"
        />
      </template>
      <BlockSingleSelect
        v-bind="BlockInputs.livesInUkSelect"
        v-if="allowInternationalClients && !uiToggles.disabledOnMount && !preventUkPostcodeLookup"
        v-bind.sync="BlockInputs.livesInUkSelect"
        data-cy="kyc-lives-in-uk-select"
      />

      <template
        v-if="
          (BlockInputs.livesInUkSelect.answer === 'No' || preventUkPostcodeLookup) &&
          !uiToggles.disabledOnMount &&
          allowInternationalClients
        "
      >
        <BlockInput v-bind.sync="BlockInputs.addressLine1" :answer.sync="user.addressLine1" :disabled="false" />

        <BlockInput v-bind.sync="BlockInputs.city" :answer.sync="user.city" :disabled="false" />
        <BlockInput v-bind.sync="BlockInputs.postcode" :answer.sync="user.postcode" :disabled="false" />

        <BlockButton
          v-bind="BlockInputs.nextBlockButton"
          v-bind.sync="BlockInputs.nextBlockButton"
          @clicked="emitSubmit"
          :isProcessing="loaders.next"
          data-cy="kyc-next-button"
        />
      </template>

      <!-- OR uiToggles.disabledOnMount -->
      <div v-if="!preventUkPostcodeLookup || uiToggles.disabledOnMount">
        <template
          v-if="BlockInputs.livesInUkSelect.answer === 'Yes' || !allowInternationalClients || uiToggles.disabledOnMount"
        >
          <BlockInput
            v-bind="BlockInputs.postcodeSearchBlock"
            :answer.sync="user.postcode"
            data-cy="kyc-postcode-search-input"
          >
            <template v-slot:form-input>
              <template v-if="!BlockInputs.postcodeSearchBlock.disabled && !uiToggles.manualEntrySelected">
                <b-input-group-append>
                  <b-button
                    @click="searchAddressByPostcode()"
                    :variant="BlockInputs.postcodeSearchBlock.disabled ? 'gray' : 'primary'"
                    class="text-white"
                    :size="BlockInputs.postcodeSearchBlock.size"
                    :disabled="BlockInputs.postcodeSearchBlock.disabled"
                    :class="{ 'btn-gray': BlockInputs.postcodeSearchBlock.disabled }"
                    data-cy="kyc-postcode-search-button"
                  >
                    <template v-if="uiToggles.searchingForPostcode">
                      <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
                    </template>
                    <template v-else> Search </template>
                  </b-button>
                </b-input-group-append>
              </template>
            </template>
          </BlockInput>

          <!-- Add a link or button here to trigger manual address entry -->
          <BlockLink
            :emitClick="true"
            @clicked="setAddressManually()"
            :align="'left'"
            :padding="'5'"
            :text="'Can\'t find your address? Click here to enter it manually'"
            v-if="!uiToggles.manualEntrySelected && !uiToggles.disabledOnMount"
            data-cy="kyc-manual-address-link"
          />

          <!-- Add a link or button here to trigger lookup mode -->
          <BlockLink
            v-if="uiToggles.manualEntrySelected && !uiToggles.disabledOnMount"
            :emitClick="true"
            @clicked="setLookupMode()"
            :text="'Want to search for your address? Click here'"
            :align="'left'"
            :padding="'5'"
          ></BlockLink>
        </template>
      </div>

      <div v-if="!preventUkPostcodeLookup || uiToggles.disabledOnMount">
        <template
          v-if="
            uiToggles.manualEntrySelected ||
            ((uiToggles.postcodeSearchSuccessful || uiToggles.addressSelected) &&
              (BlockInputs.livesInUkSelect.answer === 'Yes' || !allowInternationalClients || uiToggles.disabledOnMount))
          "
        >
          <template v-if="!uiToggles.addressSelected && !uiToggles.manualEntrySelected">
            <BlockDropdown
              v-bind="BlockInputs.addressSelectBlockDropdown"
              v-bind.sync="BlockInputs.addressSelectBlockDropdown"
              placeholder="Select your address"
              :answer.sync="dropdownSelectAnswer"
              data-cy="kyc-address-select-dropdown"
            >
            </BlockDropdown>
          </template>

          <template v-else>
            <BlockInput
              v-bind="BlockInputs.addressLine1"
              v-bind.sync="BlockInputs.addressLine1"
              :answer.sync="user.addressLine1"
            />
            <BlockInput
              v-bind="BlockInputs.city"
              v-bind.sync="BlockInputs.city"
              :answer.sync="user.city"
              data-cy="kyc-city-input"
            />

            <template v-if="collectVehicleFinanced">
              <BlockPadding :padding="padding" />
              <BlockCheckbox v-bind.sync="BlockInputs.collectVehicleFinanced" padding="0" />
            </template>

            <template>
              <BlockButton
                v-bind="BlockInputs.nextBlockButton"
                v-bind.sync="BlockInputs.nextBlockButton"
                @clicked="emitSubmit"
                :isProcessing="loaderState"
                data-cy="kyc-next-button"
              />
            </template>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>
