import store from "@/state/store";
// import { permionisssComputed } from "../state/helpers";

// Services
import { WorkspaceService } from "@claimsgate/core";
import { getFirebaseBackend } from "./authUtils";

// Functions
import { getClaimsGateDomain } from "@/helpers/ClaimsGate/RedirectHelper";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true,
    },
    component: () => import("@/components/claimant/home/claims.vue"),
  },
  {
    path: "/reports/:funnelId/:reportId",
    name: "Reports",
    component: () => import("@/components/workspace/crm/reports/reports.vue"),
  },
  {
    path: "/login/:workspaceId?",
    name: "login",
    component: () => import("@/components/shared/auth/login/login.vue"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        // ? This does not work as localStorage.getItem("userDocument")?.{smth} is always undefined for some reason
        if (localStorage.getItem("userDocument")?.email || localStorage.getItem("userDocument")?.phoneNumber) {
          if (routeTo?.params?.workspaceId) {
            next({ name: "WorkspaceClaims", params: { workspaceId: routeTo.params.workspaceId } });
          } else {
            next({ name: "default" });
          }
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout/:workspaceId?",
    name: "logout",
    meta: {
      //authRequired: true,
      public: true,
      async beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          await store.dispatch("permissions/clearAllPermissionData");
          await store.dispatch("navigation/clearNavigationData");
          await store.dispatch("auth/logOut");
        }
        // Navigate back to previous page, or home as a fallback
        if (routeTo?.params?.workspaceId) {
          next({ name: "login", params: { workspaceId: routeTo.params.workspaceId } });
        } else if (window.location.host !== getClaimsGateDomain()) {
          const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());
          const { data: workspaceId } = await workspaceService.getWorkspaceIdFromSubdomain(window.location.host);
          if (workspaceId) {
            return next({
              name: "login",
              params: { workspaceId: workspaceId },
            });
          } else {
            next({ name: "login" });
          }
        } else {
          next({ name: "login" });
        }
      },
    },
  },

  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    name: "404page",
    meta: { public: true },
    component: () => import("@/components/shared/404page/404page.vue"),
  },

  // {
  //   path: "/edit_profile",
  //   name: "Edit Profile",
  //   meta: { authRequired: true, public: true },
  //   component: () => import("./views/account/edit_profile")
  // },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    meta: { public: true },
    component: () => import("@/components/shared/legal/privacy_policy.vue"),
  },
  {
    path: "/legal/:document?",
    name: "legal",
    meta: { public: true },
    component: () => import("@/components/shared/legal/legal.vue"),
  },

  // Start Solicitor Side Routes //

  // END Solicitor Side Routes //

  {
    path: "/continue/:regNum?",
    name: "continue",
    meta: { isPublic: true },
    component: () => import("@/components/shared/continue/continue.vue"),
  },

  {
    path: "/admin/login",
    name: "admin_login",
    meta: { authRequired: true, adminRequired: true },
    component: () => import("@/components/shared/admin/forcelogin.vue"),
  },

  {
    path: "/form/:funnelId/:pageId?/:claimId?",
    name: "form",
    meta: { authRequired: false },
    component: () => import("@/components/claimant/form/form.vue"),
  },

  /* NEW */
  {
    path: "/me/:workspaceId?",
    name: "Me",
    meta: { authRequired: true },
    component: () => import("@/components/shared/user/myDetails/myDetails.vue"),
  },
  {
    path: "/settings/:workspaceId?",
    name: "Settings",
    meta: { authRequired: true },
    component: () => import("@/components/shared/user/settings/settings.vue"),
  },
  {
    path: "/apps/workspace",
    name: "WorkspaceSelect",
    meta: { authRequired: true },
    component: () => import("@/components/workspace/workspaces/workspaceSelect/workspaceSelect.vue"),
  },
  {
    path: "/apps/workspace/:workspaceId",
    name: "Workspace",
    meta: { authRequired: true },
    component: () => import("@/components/workspace/workspaces/workspace.vue"),
  },
  {
    path: "/apps/pages/:funnelId",
    name: "Pages",
    meta: { authRequired: true },
    component: () => import("@/components/workspace/crm/pages/pages.vue"),
  },

  {
    path: "/apps/builder/:funnelId?/:pageId?",
    name: "Builder",
    meta: { authRequired: true },
    component: () => import("@/components/workspace/crm/builder/builder.vue"),
  },

  {
    path: "/claims",
    name: "Claims",
    meta: { authRequired: true },
    component: () => import("@/components/claimant/home/claims.vue"),
  },
  {
    path: "/claims/:workspaceId",
    name: "WorkspaceClaims",
    meta: { authRequired: true },
    component: () => import("@/components/claimant/workspaceClaims/workspaceClaims.vue"),
  },
  {
    path: "/profile",
    redirect: { name: "Me" },
  },
  {
    path: "/track/:claimId?",
    name: "Track",
    meta: { authRequired: true },
    component: () => import("@/components/claimant/track/track.vue"),
  },
  {
    path: "/files/:fileId/:options?",
    name: "File",
    meta: { authRequired: true },
    component: () => import("@/components/shared/file/file.vue"),
  },
  {
    path: "/redirect",
    name: "Redirect",
    meta: { authRequired: false },
    component: () => import("@/components/claimant/redirects/redirect.vue"),
  },
  {
    path: "/countrydemo",
    name: "CountryDemo",
    meta: { authRequired: false },
    component: () => import("@/components/shared/blocks/countryPicker/countryPicker.vue"),
  },
  {
    path: "/alchemist",
    name: "AlchemistRedirect",
    meta: { authRequired: false },
    component: () => import("@/components/claimant/redirects/alchemistRedirect.vue"),
  },
  {
    path: "/alchemist/car-finance",
    name: "AlchemistRedirect",
    meta: { authRequired: false },
    component: () => import("@/components/claimant/redirects/alchemistRedirect.vue"),
  },
  {
    path: "/apps/welcome",
    name: "Welcome",
    meta: { authRequired: true },
    component: () => import("@/components/workspace/onboarding/onboarding.vue"),
  },
  {
    path: "/get-started",
    name: "GetStarted",
    meta: { authRequired: false },
    component: () => import("@/components/workspace/newClientOnboarding/newClientOnboarding.vue"),
  },
  /* SPECIAL KELLER LENKNER ROUTES */
  {
    path: "/welcome/mercedes",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/welcome/vauxhall",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/welcome/volkswagen",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/welcome/diesel",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/enter_reg",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/enter_reg/:regNum",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/welcome/tesco",
    redirect: "/form/AYGlaP5d05oFw8vJIhPv/06jbveYFsAN3ycVhJPg5",
  },
];
