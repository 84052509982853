import { getClaimDataService } from "@/helpers/vue";

import { BlockRevXInstance } from "./BlockRevX";
import { resetToSearchVehicleView, searchVehicle } from "./methods";

export async function mounted(state: BlockRevXInstance) {
  state.userId = state.userService.getUserId();

  getClaimDataService<BlockRevXInstance>(state);

  resetToSearchVehicleView(state);
  await parseQueryParameters(state);
}

async function parseQueryParameters(state: BlockRevXInstance): Promise<void> {
  if (Object.keys(state.$route.query)?.length > 0 && state.$route.query?.vehicleRegistration) {
    state.vrm = state.$route.query?.vehicleRegistration?.toString();
  }

  if (state.vrm) {
    state.uiToggles.isSearchingByQueryParameters = true;
    await searchVehicle(state);
  }
}
