import Vue from "vue";

import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import hiBlocks from "./locales/hi/blocks";
import hiNavigation from "./locales/hi/navigation";
import hiMe from "./locales/hi/me";
import hiLogin from "./locales/hi/login";
import hiClaims from "./locales/hi/claims";
import hiLegal from "./locales/hi/legal";

import enBlocks from "./locales/en/blocks";
import enNavigation from "./locales/en/navigation";
import enMe from "./locales/en/me";
import enLogin from "./locales/en/login";
import enClaims from "./locales/en/claims";
import enLegal from "./locales/en/legal";

import msBlocks from "./locales/ms/blocks";
import msNavigation from "./locales/ms/navigation";
import msMe from "./locales/ms/me";
import msLogin from "./locales/ms/login";
import msClaims from "./locales/ms/claims";
import msLegal from "./locales/ms/legal";

import mlBlocks from "./locales/ml/blocks";
import mlNavigation from "./locales/ml/navigation";
import mlMe from "./locales/ml/me";
import mlLogin from "./locales/ml/login";
import mlClaims from "./locales/ml/claims";
import mlLegal from "./locales/ml/legal";

import arBlocks from "./locales/ar/blocks";
import arNavigation from "./locales/ar/navigation";
import arMe from "./locales/ar/me";
import arLogin from "./locales/ar/login";
import arClaims from "./locales/ar/claims";
import arLegal from "./locales/ar/legal";

const localStorageLang = localStorage.getItem("locale");

export const i18n = new VueI18n({
  locale: localStorageLang || "en",
  fallbackLocale: "en",
  messages: {
    ar: {
      blocks: arBlocks,
      navigation: arNavigation,
      me: arMe,
      login: arLogin,
      claims: arClaims,
      legal: arLegal,
    },
    en: {
      blocks: enBlocks,
      navigation: enNavigation,
      me: enMe,
      login: enLogin,
      claims: enClaims,
      legal: enLegal,
    },
    hi: {
      blocks: hiBlocks,
      navigation: hiNavigation,
      me: hiMe,
      login: hiLogin,
      claims: hiClaims,
      legal: hiLegal,
    },
    ms: {
      blocks: msBlocks,
      navigation: msNavigation,
      me: msMe,
      login: msLogin,
      claims: msClaims,
      legal: msLegal,
    },
    ml: {
      blocks: mlBlocks,
      navigation: mlNavigation,
      me: mlMe,
      login: mlLogin,
      claims: mlClaims,
      legal: mlLegal,
    },
  },
});
