import { Props } from "@/types";
import { CaravanVehicleStore } from "./types";

export function getProps() {
  return {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },

    invalidFeedback: {
      type: String,
      required: false,
      allowVariable: false,
    } as Props.CGPropExtras<string>,

    stores: {
      type: Array,
      required: false,
      default: (): Array<{ name: CaravanVehicleStore; type: string }> => [
        { name: "make", type: "string" },
        { name: "yearOfManufacture", type: "string" },
        { name: "serialNumber", type: "string" },
        { name: "vin", type: "string" },
        { name: "keeperStartDate", type: "string" },
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<{ name: CaravanVehicleStore; type: string }>>,
  };
}
