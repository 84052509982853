export default {
  email: {
    label: "Alamat e-mel",
    invalidFeedback: "Bidang ini wajib diisi",
  },

  phone: {
    label: "Nombor Telefon",
  },

  contactDetails: {
    mdrPrivacyPolicyText:
      "Dengan menekan Teruskan, saya bersedia untuk dihubungi oleh Mishcon de Reya LLP tentang tuntutan saya",
    "Do you agree to the": "Adakah anda bersetuju dengan",
    "Privacy Policy": "Dasar Privasi",
  },

  invalidFeedback: {
    "Please enter a valid email address": "Sila masukkan alamat e-mel yang sah",
    "Please enter a valid phone number": "Sila masukkan nombor telefon yang sah",
    "First name must be at least 2 characters": "Nama pertama mesti sekurang-kurangnya 2 aksara",
    "Last name must be at least 2 characters": "Nama akhir mesti sekurang-kurangnya 2 aksara",
    "Please select a title": "Sila pilih gelaran",
    "You must be at least 18 years old to use continue":
      "Anda mesti berumur sekurang-kurangnya 18 tahun untuk menggunakan butang Teruskan",
  },

  button: {
    continue: "Teruskan",
    previous: "Kembali",
  },

  singleSelect: {
    Yes: "Ya",
    No: "Tidak",
    "Exact figure": "Angka tepat",
    Approximation: "Anggaran",
  },

  placeholder: {
    "Select an item": "Sila pilih satu item",
  },

  dropdown: {
    "No, I am not aware that Mishcon de Reya LLP has acted or is currently acting for or against me in a legal matter":
      "Tidak, saya tidak tahu bahawa Mishcon de Reya LLP telah bertindak untuk atau menentang saya dalam hal undang-undang",
    "Yes, Mishcon de Reya LLP has acted and/or is currently acting against or for me in legal matter(s)":
      "Ya, Mishcon de Reya LLP telah bertindak dan/atau sedang bertindak menentang saya atau untuk saya dalam hal undang-undang",
    "I am not sure": "Saya tidak pasti",
    Title: "Title",
    Mr: "Mr",
    Mrs: "Mrs",
    Ms: "Ms",
    Miss: "Miss",
    Mx: "Mx",
    Dr: "Dr",
    Professor: "Professor",
    "Prefer not to say": "Prefer not to say",
    Other: "Lain-lain",
    Shareholder: "Pemegang saham",
    Director: "Pengarah",
    Employee: "Pekerja",
  },

  address: {
    addressLine1: "Alamat Baris 1",
    city: "Bandar",

    title: "Gelaran",
    firstName: "Nama Pertama",
    middleName: "Nama Tengah",
    lastName: "Nama Akhir",
    dateOfBirth: "Tarikh Lahir",
    postcode: "Poskod",
    livesInUk: "Adakah anda tinggal di UK?",
    enterAddress: "Masukkan Alamat Anda",
  },
  currency: {
    enterValue: "Sila masukkan nilai.",
  },

  yotiManualIdUpload: {
    selectDocumentType: "Please Select the document type you wish to upload",
    thisFieldIsRequired: "This field is required",
    uploadIdentityDocument: "Please upload a copy of your identity document",
    uploadMustBeClear: "You must ensure that the document is clear and legible in the file uploaded.",
    errorUploading: "There was an error uploading your files, please try again.",
  },

  yoti: {
    clickManualUpload:
      "Click here to manually upload your identity documents if you are having issues using the Yoti identity service",
    backToYoti: "Go back to identifying with Yoti",
    verificationInProgress: "Identity Verification In Progress",
    inProgressInfo:
      "Your identity verification is currently in progress. This process can take a few minutes. We appreciate your patience. If you encounter any issues, please don't hesitate to contact us.",
    faqs: {
      troubleCamera: "Having trouble with your camera?",
      iphoneUsersTitle: "iPhone Users",
      iphoneSafariInstructions: "For Safari: Go to Settings > Safari > Camera and select 'Allow'.",
      iphoneOtherBrowsersInstructions:
        "For Other Browsers: Go to Settings > [Your Browser] > Camera and select 'Allow'.",

      // Android Users Section
      androidUsersTitle: "Android Users",
      androidPopupInstructions: "A popup should appear asking for permissions when you try to use the camera.",
      androidManualInstructions:
        "If it doesn't, go to Settings > Apps > [Your Browser] > Permissions and enable Camera.",

      // Desktop/Laptop Users Section
      desktopUsersTitle: "Desktop/Laptop Users",
      chromeInstructions: "Google Chrome: A camera icon will appear in the address bar. Click it and choose 'Allow'.",
      firefoxInstructions: "Mozilla Firefox: A popup will appear near the address bar. Click 'Allow'.",
      edgeInstructions: "Microsoft Edge: A popup will appear at the bottom of the browser. Click 'Allow'.",
      safariInstructions: "Safari: A prompt will appear asking for your permission. Click 'Allow'.",
      introTextDocs: "Yoti accepts the following documents:",
      whatDocuments: "What identification documents are accepted?",
      passportItem:
        "Current passport (accepted from 200+ countries - click here to review the list of countries countries)",
      drivingLicenseItem: "Photo driving licence (accepted from 92 countries - click here to review list of countries)",
      nationalIdItem: "National Identity Card (accepted from 66 countries - click here to review list of countries)",
      residencePermitItem: "British Residence Permit with photo",
      additionalInfo:
        "Some versions of these documents might not be accepted because they don’t meet our security requirements",
      howToTakePhoto: "How do I take a good photo of my ID?",
      title: "Do you need help with the identity check?",
      introTextTakePhoto:
        "Please make sure the photo and personal details on your documents are facing up. The photo will be taken automatically. Depending on its country of issue, you might need to take a photo of the front and back of your ID. To take a photo of your ID, make sure that:",
      visibleCorners: "All four corners of your ID are visible in the photo",
      noFingerCovering: "Your fingers aren't covering your photo or the information written on it",
      documentClarity: "The document is clear and in focus",
      adequateLighting: "The lighting isn’t too dark",
      clearImage: "Make sure you capture a clear image without any glare - for best results, use your smartphone.",
      avoidGlare: "You avoid any glare or reflection",
      fullDocumentImage: "The whole document must be in the image.",
      struggleToConfirm: "Struggling to confirm your identity?",
      contact: "If you are having trouble completing the identity verification, please contact our team by",
      clickHere: "clicking here",
    },
  },

  multiFile: {
    addFile: "Add File",
  },
};
