import { CreditCheckInstance } from "./creditCheckInstance";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { gatewayHelper } from "./helpers/gatewayHelper";
// types
import type { ViewSubmitEvent } from "./helpers/CheckboardCreditCheck";
import { Checkboard, User } from "@claimsgate/core-types";
import { createClaimStore } from "./helpers/createClaimStore";
import { getCheckboardVariableIds } from "./helpers/getCheckboardVariableIds";
import { getFirebaseBackend } from "@/authUtils";
export { getLoggedInUserData as getUserData } from "@/helpers/ClaimsGate/users/getLoggedInUserData";

export async function initiateCheck(state: CreditCheckInstance): Promise<void> {
  const {
    claimId,
    userId,
    workspaceId,
    funnelId,
    pageId,
    useValidateRunCheck,
    headless,
    allowCheckboardFallback,
    skipApproveCheck,
    extraClaimsPageId,
    enforceQualificationPeriod,
  } = state;
  try {
    const data: Checkboard.Gateway.InitiateCheck.Request = {
      claimId,
      userId,
      workspaceId,
      funnelId,
      pageId,
      useValidateRunCheck,
      headless,
      allowCheckboardFallback,
      skipApproveCheck,
      extraClaimsPageId,
      enforceQualificationPeriod,
    };

    //potential errors are caught in the gatewayHelper
    const result = await gatewayHelper("checkboardInitiateCheck", data);

    if (result.clientView === "addressConfirmation") {
      state.addresses = result.check.addressConfirmation.addresses ?? [];

      state.currentAddress = result.check.addressConfirmation.currentAddress ?? null;

      // Edge case where client might be navigated to addressConfirmation without any addresses returned
      // from checkboard. In this case we want to redirect client to addressInput.
      if (state.addresses.length === 0) {
        result.clientView = "addressInput";
      }
    } else if (result.clientView === "requestorAuthenticity") {
      if (!result.check.questionnaire) {
        throw new Error("No questionnaire found");
      }

      state.questionnaire = result.check.questionnaire;
    } else if (result.clientView === "confirmLenders") {
      if (!result.check.possibleLenders) {
        throw new Error("No possible lenders found");
      }

      state.possibleLenders = result.check.possibleLenders;
      if (state.possibleLenders.length === 0) {
        state.checkId = result.check.id;
        state.check = result.check;
        state.currentView = "success";
        return;
      }
    } else if (result.clientView === "success") {
      if (headless) {
        await handleHeadless(state, result);
      }

      state.confirmedLenders = result.check.confirmedLenders;
      state.confirmedClaimLender = result.check.confirmedClaimLender;
    }

    state.currentView = result.clientView;

    state.checkId = result.check.id;

    state.check = result.check;
  } catch (error) {
    console.error("Error initiating check:", error);
    throw error;
  }
}

export function handleSubmit(event: ViewSubmitEvent, state: CreditCheckInstance): void {
  try {
    state.check = event.responseData.check;

    if (event.view === "approveCheck") {
      type Response =
        | Checkboard.Gateway.ApproveCheck.Response
        | Checkboard.Gateway.RunCheck.Response
        | Checkboard.Gateway.ValidateRunCheck.Response;
      const response = event.responseData as Response;

      // Edge case where client might be navigated to addressConfirmation without any addresses returned
      // from checkboard. In this case we want to redirect client to addressInput.
      if (response.clientView === "addressConfirmation" && response.addresses?.length === 0) {
        state.currentView = "addressInput";
      } else if (response.clientView === "addressConfirmation" && response.addresses?.length > 0) {
        state.currentView = response.clientView;

        state.addresses = response.check.addressConfirmation.addresses;

        state.currentAddress = response.check.addressConfirmation.currentAddress;
      } else if (response.clientView === "requestorAuthenticity") {
        state.currentView = response.clientView;

        state.questionnaire = response.questionnaire;
      } else if (response.clientView === "confirmLenders") {
        state.possibleLenders = response.possibleLenders ?? response.check.possibleLenders;

        if (state.possibleLenders.length > 0) {
          // Check has completed with no lenders. Let them pass.
          state.currentView = "confirmLenders";
        } else {
          state.currentView = "success";
        }
      } else {
        state.currentView = response.clientView;
      }
    } else if (event.view === "addressInput") {
      state.currentView = event.responseData.clientView;

      state.addresses = event.responseData.check?.addressConfirmation?.addresses;
    } else if (event.view === "addressConfirmationToHistory") {
      state.currentView = "addressHistory";

      state.selectedAddress = event.selectedAddress;
    } else if (event.view === "addressConfirmationToAddressInput") {
      state.currentAddress = null;
      state.currentView = "addressInput";
    } else if (event.view === "addressInputToFail") {
      state.currentView = "fail";
    } else if (event.view === "addressConfirmation") {
      state.currentView = event.responseData.clientView;

      state.questionnaire = event.responseData.check.questionnaire;
    } else if (event.view === "requestorAuthenticity") {
      const response = event.responseData as Checkboard.Gateway.SubmitAuthenticityAnswers.Response;

      state.currentView = response.clientView;

      if (response.clientView === "confirmLenders") {
        state.possibleLenders = response.possibleLenders ?? response.check.possibleLenders;

        if (state.possibleLenders.length === 0) {
          // Check has completed with no lenders. Let them pass.
          state.currentView = "success";
        }
      }
    } else if (event.view === "confirmLenders") {
      const response = event.responseData as Checkboard.Gateway.ConfirmLender.Response;

      state.currentView = response.clientView;

      if (response.clientView === "success") {
        state.confirmedLenders = response.confirmedLenders ?? [];
        state.confirmedClaimLender = response.confirmedClaimLender;
      }
    } else if (event.view === "fail") {
      const result = event.responseData as Checkboard.Gateway.InitiateCheck.Response;

      state.currentView = result.clientView;

      state.checkId = result.check.id;

      state.check = result.check;
    } else {
      state.currentView = event.responseData.clientView;
    }
  } catch (error) {
    console.error("Error handling submit:", error);
    state.currentView = "fail";
  }
}

async function handleHeadless(
  state: CreditCheckInstance,
  _result: Checkboard.Gateway.InitiateCheck.Response
): Promise<void> {
  console.log("Headless result", _result);
  const result = _result as Checkboard.Gateway.ValidateRunCheck.Response;

  //const clientView = result.clientView;

  //const isSuccessView = clientView === "success";

  //if (!isSuccessView) {
  //  // ? Not sure what to do here. Do we skip? The response may have set to confirmLenders, or even auth questions.
  //  // ? We could drop the user there? Might be confusing for them.
  //  console.log("Headless was not success");
  //  await state.$store.dispatch("events/fire", { name: "next" });
  //  return;
  //}

  const variableIds = await getCheckboardVariableIds({ funnelService: state.funnelService, funnelId: state.funnelId });

  const claimStore = createClaimStore({
    confirmedClaimLender: result.check.confirmedClaimLender,
    confirmedLenders: result.check.confirmedLenders,
    variableIds: variableIds,
    creditService: "checkboard",
  });

  Object.keys(claimStore).forEach((key) => {
    if (!!key && claimStore[key]) {
      state.claimDataService.setArtefact(key, claimStore[key]);
    }
  });

  await state.claimDataService.update();

  //await state.$store.dispatch("events/fire", { name: "next" });
  return;
}
