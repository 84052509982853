import { DateTime } from "luxon";

/**
 * This class contains a collection of
 * useful date processing / handling methods.
 * These are commonly used date handling functions
 * that are required in various other components within
 * the system.
 */
class DateService {
  /**
   * Returns the date of the first day of the
   * week in which the given date occurs.
   * @param {*} date - the date
   * @returns {Date} - the date of the first day of the week
   */
  getFirstDayOfWeek(date) {
    const dateObj = this.parseDate(date);
    const dayOfWeek = dateObj.getDay();
    const diff = dateObj.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    return new Date(dateObj.setDate(diff));
  }

  /**
   * Returns the date of the last day of the
   * week in which the given date occurs.
   * @param {*} date - the date
   * @returns {Date} - the date of the last day of the week
   */
  getLastDayOfWeek(date) {
    const dateObj = this.parseDate(date);
    const dayOfWeek = dateObj.getDay();
    const diff = dateObj.getDate() - dayOfWeek + 7;
    return new Date(dateObj.setDate(diff));
  }

  /**
   * Returns a string representation of a date
   * with the timezone component removed.
   * @param {*} date - the date we want to remove the time zone from
   * @returns {String} - string representation of date without timezone
   */
  removeTimezone(date) {
    const dateObj = this.parseDate(date);
    return dateObj.toDateString();
  }
  /**
   * Returns the string representation of date formatted
   * to the locale "English (United Kingdom)"
   * @param {*} value - the date value
   * @returns {String} - locale specific string representation of the date
   */
  toString(value) {
    if (value === null || value === undefined) {
      return;
    }
    try {
      const dateObj = this.parseDate(value);
      return DateTime.fromJSDate(dateObj).setZone("Europe/London").toFormat("dd LLLL yyyy");
    } catch (e) {
      console.log("value", value);
      console.log("error", e);
    }
  }
  /**
   * Returns the string representation of date formatted
   * to the locale "English (United Kingdom)" including time
   * @param {*} value - the date value
   * @param {boolean} showSeconds - whether to include the time in the string
   * @returns {String} - locale specific string representation of the date
   */
  toStringWithTime(value, showSeconds = false) {
    if (value === null || value === undefined) {
      return;
    }
    try {
      const dateObj = this.parseDate(value);
      const format = showSeconds ? "dd LLLL yyyy hh:mm:ss a" : "dd LLLL yyyy hh:mm a";
      return DateTime.fromJSDate(dateObj).setZone("Europe/London").toFormat(format);
    } catch (e) {
      console.log("value", value);
      console.log("error", e);
    }
    return;
  }
  /**
   *
   * @param {*} value
   * @returns
   */
  parseDate(value) {
    if (!value || Array.isArray(value)) {
      return value;
    }

    if (typeof value === "boolean" || typeof value === "number") {
      return value;
    }

    if (typeof value === "string") {
      const regex = /[a-zA-Z]{2,}/g;
      if (regex.test(value)) {
        return value;
      }

      // Check if the string is made up of only digits
      const onlyDigitsRegex = /^\d+$/g;

      if (onlyDigitsRegex.test(value)) {
        return value;
      }
    }

    if (value && typeof value.toDate === "function") {
      const dateObj = DateTime.fromJSDate(value.toDate());
      return dateObj ? dateObj.setZone("Europe/London").toJSDate() : null;
    } else if (Object.prototype.toString.call(value) === "[object Date]") {
      const dateObj = DateTime.fromJSDate(value);
      return dateObj ? dateObj.setZone("Europe/London").toJSDate() : null;
    } else if (value.seconds !== undefined) {
      const dateObj = DateTime.fromSeconds(value.seconds);
      return dateObj ? dateObj.setZone("Europe/London").toJSDate() : null;
    } else if (value._seconds !== undefined) {
      const dateObj = DateTime.fromSeconds(value._seconds);
      return dateObj ? dateObj.setZone("Europe/London").toJSDate() : null;
    } else {
      const dateObj = DateTime.fromJSDate(new Date(value));
      if (dateObj) {
        const dateValue = dateObj.setZone("Europe/London").toJSDate();
        return typeof value.split !== "function" || isNaN(dateValue) ? null : dateValue;
      } else {
        return null;
      }
    }
  }

  /**
   *
   * @param {*} str a string that could be an ISO format date
   * @returns {boolean} if string is ISO
   */
  isIsoString(str) {
    if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str) || /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}z/.test(str)) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Checks is value is ISO Date, a string date that has month names in it, or any (yyyy|mm|dd)(-|/) combination.
   * @param {String} value
   */
  dateTripleCheck(value) {
    // check if date is valid
    if (isNaN(new Date(value).getTime())) {
      return value;
    }

    let dateValue;
    const options = { month: "long", day: "numeric", year: "numeric" };
    const months = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      " december",
    ];
    const regExp = new RegExp(
      / ^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$ /
    );

    if (this.isIsoString(value)) {
      dateValue = new Date(value).toLocaleDateString("en-gb", options);
    } else if (months.find((month) => value.toLowerCase().includes(month))) {
      dateValue = new Date(value).toLocaleDateString("en-gb", options);
    } else if (regExp.test(value)) {
      dateValue = new Date(value).toLocaleDateString("en-gb", options);
    }

    return dateValue ?? value;
  }
}

export { DateService };
