import { getFirebaseBackend } from "@/authUtils";
import * as Sentry from "@sentry/vue";
import { captureMessage } from "@sentry/vue";
import { BlockRevXInstance } from "./BlockRevX";

/** Fires a proactive Sentry log including the VRM called 'RevXApiError' fingerprinted by our release number */
export function fireRevXApiErrorEvent(state: BlockRevXInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("RevXApiError");
  });
}

/** Fires a proactive Sentry log including the VRM called 'RevXVehicleNotFound' release number */
export function fireRevXVehicleNotFoundEvent(state: BlockRevXInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("RevXVehicleNotFound");
  });
}

/** Fires a proactive Sentry log including the VRM called 'RevXVehicleFound' release number */
export function fireRevXVehicleFoundEvent(state: BlockRevXInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("RevXVehicleFound");
  });
}

/** Fires a proactive Sentry log including the VRM called 'RevXVrmEntered' release number */
export function fireRevXVrmEntered(state: BlockRevXInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("RevXVrmEntered");
  });
}

/** Fires a proactive Sentry log including the VRM called 'RevXVehicleNotFound' release number */
export function fireRevXNotQualifiedEvent(state: BlockRevXInstance) {
  const vrm = tryParseJson(state.vrm);
  const make = tryParseJson(state.vehicle.make);
  const fuelType = tryParseJson(state.vehicle.fuelType);
  const dateFirstRegistered = tryParseJson(state.vehicle.dateFirstRegistered);

  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("funnelId", state.funnelId);
    scope.setTag("vrm", vrm);
    scope.setTag("make", make);
    scope.setTag("fuelType", fuelType);
    scope.setTag("dateFirstRegistered", dateFirstRegistered);

    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("RevXVehicleNotQualified");
  });
}

function tryParseJson<T>(json: T): T | string {
  try {
    return JSON.parse(JSON.stringify(json));
  } catch (e) {
    return "";
  }
}
