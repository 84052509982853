<script lang="ts">
import Vue from "vue";
import { getFirebaseBackend } from "@/authUtils";
import ModalBodyWrapper from "@/components/shared/wrappers/modalBodyWrapper.vue";

export default Vue.extend({
  components: {
    ModalBodyWrapper,
  },
  data() {
    return {
      isOffline: false,
      unsubscribe: null as (() => void) | null,
    };
  },
  computed: {
    buttonText(): string {
      return this.isOffline ? "Waiting for Connection..." : "Refresh";
    },
  },
  methods: {
    updateConnectionStatus(online: boolean) {
      this.isOffline = !online;
    },
    refreshPage() {
      window.location.reload();
    },
  },
  mounted() {
    const db = getFirebaseBackend().firestore();
    const docRef = db.collection("config").doc("apis");

    this.unsubscribe = docRef.onSnapshot(
      { includeMetadataChanges: true },
      (docSnapshot) => {
        const source = docSnapshot.metadata.fromCache ? "local" : "server";
        const isFirestoreConnected = source === "server";
        this.updateConnectionStatus(navigator.onLine || isFirestoreConnected);
      },
      (error) => {
        console.error("Error fetching data from Firestore:", error);
      }
    );

    window.addEventListener("online", () => this.updateConnectionStatus(true));
    window.addEventListener("offline", () => this.updateConnectionStatus(false));
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    window.removeEventListener("online", () => this.updateConnectionStatus(true));
    window.removeEventListener("offline", () => this.updateConnectionStatus(false));
  },
  watch: {
    isOffline: {
      handler(offline) {
        if (offline) {
          this.$bvModal.show("connectionLostModal");
        }
      },
    },
  },
});
</script>

<template>
  <b-modal
    id="connectionLostModal"
    ref="connectionLostModal"
    hide-header
    hide-footer
    centered
    size="md"
    no-close-on-backdrop
    no-close-on-esc
  >
    <ModalBodyWrapper :useTheme="false">
      <font-awesome-icon icon="exclamation" class="mb-2" style="display: inline-block; width: 100%; font-size: 40px" />
      <h3 class="text-center">Connection Lost</h3>
      <p class="mb-4 text-center">
        We've detected that your internet connection has been lost. Check your connection and refresh the page.
      </p>
      <b-button variant="light" @click="refreshPage" :disabled="isOffline" size="lg" block>
        {{ buttonText }}
      </b-button>
    </ModalBodyWrapper>
  </b-modal>
</template>
