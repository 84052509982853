declare global {
  const $infoModal: {
    show(params: Record<any, any>);
  };
  interface Window {
    isTwitterPixelInitalised: boolean;
    vm: Vue;
    intercom: {
      shutdown();
      boot(intercomOptions: Record<string, any>);
    };
    carousel: Promise<void>;
  }
}

import "./main.js";

// 1. Make sure to import 'vue' before declaring augmented types
import Vue from "vue";

// 2. Specify a file with the types you want to augment
//    Vue has the constructor type in types/vue.d.ts
declare module "vue/types/vue" {
  // 3. Declare augmentation for Vue
  interface Vue {
    $infoModal: {
      show(params: Record<any, any>);
    };
  }
}
