import { BlockVehicleSplitTestInstance } from "./BlockVehicleSplitTestInstance";

export async function mounted(state: BlockVehicleSplitTestInstance) {
  // If there is already a blockVehicleSplitTest in local storage, then we can use that.
  // Otherwise, we need to create a new one.
  const splitTest = getSplitTest();

  if (splitTest === VehicleSplitTests.Carweb) {
    state.uiToggles.show.carweb = true;
    state.splitTest = "carweb";
  } else if (splitTest === VehicleSplitTests.Motorspecs) {
    state.uiToggles.show.motorspecs = true;
    state.splitTest = "motorspecs";
  }
}

/** Enumerates over the different possible Split Tests a user can receive */
export enum VehicleSplitTests {
  Motorspecs = "motorspecs",
  Carweb = "carweb",
}

/** Runs logic to determine which Vehicle Block to display  */
function getSplitTest(): VehicleSplitTests {
  localStorage.removeItem("blockVehicleSplitTest");
  // If there is already a blockVehicleSplitTest in local storage, then we can use that.
  if (localStorage.getItem("blockVehicleSplitTest") === VehicleSplitTests.Motorspecs) {
    return VehicleSplitTests.Motorspecs;
  } else if (localStorage.getItem("blockVehicleSplitTest") === VehicleSplitTests.Carweb) {
    return VehicleSplitTests.Carweb;
  }

  // Generate a random number between 1 and 2
  // If the number is 1 then store motorspecs in local storage
  // If the number is 2 then store carweb in local storage
  const randomNumber = Math.floor(Math.random() * 2) + 1;

  if (randomNumber === 1) {
    localStorage.setItem("blockVehicleSplitTest", JSON.stringify(VehicleSplitTests.Motorspecs));
    return VehicleSplitTests.Motorspecs;
  } else {
    localStorage.setItem("blockVehicleSplitTest", JSON.stringify(VehicleSplitTests.Carweb));
    return VehicleSplitTests.Carweb;
  }
}
