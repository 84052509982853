import { FunnelsService } from "@claimsgate/core";

export const variableIds = {
  enteredRegistration: "",
  vehicleRegistration: "",
  make: "",
  model: "",
  keeperStartDate: "",
  craFinanceLender: "",
  craReport: "",
  craProductType: "",
  craCompanyType: "",
  craAgreementNumber: "",
  craCreditStartDate: "",
  craCurrentBalance: "",
  craAccountStatus: "",
  craSettlementDate: "",
  craLengthOfContract: "",
  craCreditEndDate: "",
  craEstimatedDebt: "",
  craAgreementAddress: "",
  financeLender: "",
  caseType: "",
  craCaseType: "",
  craCheckAttempted: "",
  craCheckDate: "",
  craCheckChargeable: "",
  craCheckIdentityOutcome: "",
  craCheckLendersReturned: "",
  craDateOfBirth: "",
  craTitle: "",
  craFirstName: "",
  craLastName: "",
  nonCraTitle: "",
  nonCraFirstName: "",
  nonCraLastName: "",
  nonCraAddress: "",
};

export type CheckboardVariableIds = Record<keyof typeof variableIds, string>;

export async function getCheckboardVariableIds({
  funnelService,
  funnelId,
}: {
  funnelService: FunnelsService;
  funnelId: string;
}): Promise<CheckboardVariableIds> {
  const { data: funnelVariables } = await funnelService.getFunnelVariables(funnelId);

  if (!funnelVariables) {
    throw new Error("No funnel variables found");
  }

  Object.keys(variableIds).forEach((key) => {
    variableIds[key] = funnelVariables.find((v) => v.field === key)?.id ?? "";
  });

  return variableIds;
}
