import { ClaimsGateVariables } from "@claimsgate/core";
import { VariableType } from "@claimsgate/core-types";
import { claimsGateTextPropValues } from "../blocks/textProps";

export interface StoreProperty {
  value: object | string;
  selectedVariable: string;
  required: string;
  type: VariableType;
  selectedAction: string;
  variableType: string;
  variableToCreate: string;
  isRepeatableChild: string;
  property: string;
  propertyType: string;
  description: string;
}

export class BuilderPropService {
  /** Fetches the props associated with a given block's Vue component */
  getComponentProps(blockName: string): Object {
    const vue: Vue = window.vm;
    let props: Object;

    // Check if the component exists
    const component = vue.$options.components[blockName];

    if (!component) {
      return {};
    }

    // Check if the component was created using Typescript, since Vue uses a
    // slightly different wrapper instance for components created with
    // in Typescript.
    if (typeof vue.$options.components[blockName] === "function") {
      //@ts-ignore
      ({ props } = component.options);
    } else {
      //@ts-ignore
      ({ props } = component);
    }
    console.log("Returning props for block create", props);
    return props;
  }

  /**
   * Generates a storeAs property for a given block name
   * @param blockName
   */
  generateStoreAs(blockName: string, type: VariableType = "string") {
    // The default store property
    const property: StoreProperty = {
      value: "",
      selectedVariable: "",
      required: "",
      type,
      selectedAction: "newVariable",
      variableType: "",
      variableToCreate: "",
      isRepeatableChild: "",
      property: "",
      propertyType: "",
      description: "Define the variable the input should be stored under",
    };

    // A map of the different blocks which require an override to their storeAs property
    const blockNamesToStoreAsOverrides = {
      BlockNationalInsurance: ClaimsGateVariables.User.nationalInsuranceNumber,
      BlockDateOfBirth: ClaimsGateVariables.User.dateOfBirth,
      BlockEmail: ClaimsGateVariables.User.email,
      BlockPhone: ClaimsGateVariables.User.phoneNumber,
      BlockName: [ClaimsGateVariables.User.firstName],
    };

    // If the block which we are generating exists in the list of blocks which require
    // their storeAs property to be overriden then override the value
    if (blockNamesToStoreAsOverrides[blockName]) {
      property.value = blockNamesToStoreAsOverrides[blockName];
    } else {
      property.value = { type: "", field: "", group: "", id: "" };
    }

    console.log("[property] is now", blockName, property);

    return property;
  }

  /**
   * Returns the an object containing each of a given component's props as keys
   * @param {String} component - Name of the component to fetch
   * @returns {Object} {k,v} prop map
   */
  getProps(blockName: string) {
    const out = {};

    // Fetch the props associated with the block's component from Vue
    const props = this.getComponentProps(blockName);

    // For each of the returned props
    Object.keys(props).forEach((propName) => {
      // Each prop is associated with meta parameters
      const prop = props[propName];
      out["valueTypes"] = {};
      out["isValid"] = false;

      if (propName === "answer") {
        out["storeAs"] = this.generateStoreAs(blockName);
      }

      // Initalise out object for prop
      if (!out[propName]) {
        out[propName] = {};
      }
      // window.console.log("Propname and component", propName, component);

      out[propName]["selectedVariable"] = "";
      out[propName]["value"] = "";

      out[propName]["isVariable"] = false;
      Object.keys(prop).forEach((propKey) => {
        // Determine the type of the given propKey

        if (propKey === "type") {
          if (typeof prop[propKey] !== "function") {
            out[propName]["type"] = "string";
          } else {
            const typeConstructor = prop[propKey]();
            if (Array.isArray(typeConstructor)) {
              out[propName]["type"] = "array";
            } else if (typeof typeConstructor === "object") {
              out[propName]["type"] = "object";
            } else if (typeof typeConstructor === "boolean") {
              out[propName]["type"] = "boolean";
            } else if (typeof typeConstructor === "number") {
              out[propName]["type"] = "number";
            } else {
              out[propName]["type"] = "string";
            }
          }
        } else if (propKey === "default") {
          // Support array of objects by using the propKey's 'default' property to get the correct structure
          if (typeof prop[propKey] === "function") {
            out[propName]["value"] = prop[propKey]();
          } else if (typeof prop[propKey] === "string") {
            out[propName]["value"] = prop[propKey];
          } else if (typeof prop[propKey] === "boolean") {
            out[propName]["value"] = prop[propKey];
          } else if (typeof prop[propKey] === "number") {
            out[propName]["value"] = prop[propKey];
          } else if (prop[propKey] === null) {
            out[propName]["value"] = prop[propKey];
          } else {
            out[propName]["value"] = "";
          }
        } else {
          // Append additional meta
          out[propName][propKey] = prop[propKey];
        }
      });
    });
    if (props["stores"]) {
      const storesArray = [];
      const prop = props["stores"].default();

      prop.forEach((store) => {
        storesArray.push({
          variableToCreate: store.name,
          variableType: store.type,
          selectedAction: "newVariable",
        });
      });

      out["stores"]["value"] = storesArray;
    }
    if ("BlockMultiFileInput" === blockName) {
      out["multiple"]["value"] = true;
    }
    return out;
  }

  public static propOrder() {
    const common = ["label", "info", "placeholder", "padding"];
    const appearance = ["text", "value", "height", "width", "size", "variant", "styles", "block", "weight", "align"];
    const other = [
      "rounded",
      "showProgressValue",
      "animated",
      "fluid",
      "thumbnail",
      "embedLink",
      "buttonText",
      "signatureRequired",
    ];
    const customise = [
      "inputType",
      "options",
      "optionCSV",
      "source",
      "displayDays",
      "yearsStart",
      "yearsEnd",
      "textRows",
      "other",
      "accept",
    ];
    const logic = [
      "identificationFlow",
      "faceMatch",
      "collectDateOfBirth",
      "requireAgeCheck",
      "minAge",
      "maxAge",
      "api",
      "apiKey",
      "required",
      "requireEmailConfirm",
      "summaryDocument",
      "description",
      "recallData",
      "condition",
      "storeAs",
      "file",
      "stores",
    ];
    return [...common, ...appearance, ...other, ...customise, ...logic];
  }
}
