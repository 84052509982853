export default {
  lastUpdatedOn: "पिछली बार क्लेम अपडेट किया गया",
  discoverButton: "आरंभ करें",
  discoverHeading: "के साथ दावों की खोज करें",

  claimCompleted:
    "आपका क्लेम पूरा हो गया है, अर्थात इस समय आपसे और किसी विवरण की जरूरत नहीं है।  आपको अपने क्लेम की स्थिति के बारे में यहां तथा ईमेल से नियमित अपडेटा प्राप्त होंगे।",
  startNew: "एक नया क्लेम आरंभ करें",
  status: "स्थिति",
  contactUs: "हमसे संपर्क करें",
  hereToHelp: "हमारी टीम आपकी मदद करने के लिए तत्पर है।  हमें एक मैसेज भेजने के लिए यहां क्लिक करें।",
  contact: "Contact",
  sendUsAMessage: "हमें एक मैसेज भेजें और हम आपसे यथाशीघ्र सम्पर्क करेंगे।",
  name: "Name",
  email: "Email",
  messge: "Message",
  send: "Send",
  cancel: "Cancel",
  view: "देखें",
  files: "फाइलें",

  history: "हिस्ट्री",
  myClaims: "मेरे क्लेम्स",
  updatedOn: "Updated on",
  finish: "Finish",
  notComplete: "You have not completed all of the required steps for your claim.",
  continueMyClaim: "Continue my claim",
  mySettings: "मेरी सेटिंग्स",

  identityPicture1: "पहचान चित्र 1",
  identityPicture2: "पहचान चित्र 2",
  identityPicture3: "पहचान चित्र 3",
  identityPicture4: "पहचान चित्र 4",
  shareLink: "लिंक साझा करें",
  copyLink: "लिंक कॉपी करें",
  withdrawInterest: "रुचि वापस लेना",

  information: "माहिती",
  clickToGo: "यहां जाने के लिए क्लिक करें",
  homePage: "होम पेज",
  shareThe: "शेयर करें",
  withSomeone: "जो योग्य हो सकता है।",
  pageCompleted: "पृष्ठ पूरा हुआ",
};
