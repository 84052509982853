import { getClaimDataService } from "@/helpers/vue";

import { ClaimsGateFunctionReturnedError } from "@claimsgate/core";
import { BusinessListEntity, ClaimsGateGetCompanyResponse, State } from "@claimsgate/core-types";
import isNil from "lodash.isnil";
import omitBy from "lodash.omitby";
import { BlockCompanyInstance } from "./BlockCompany";
import { BlockCompanyPartialStores, BlockCompanyStores } from "./types";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
export const methods = {
  mounted,
  searchCompany,
  computes,
  validations,
  toggleManualEntry,
  toggleCompanySearch,
};

/** Toggle the company search and manual entry options */
function toggleCompanySearch(state: BlockCompanyInstance) {
  state.uiToggles.show.companySearch = true;
  state.uiToggles.show.companySelect = false;
  state.uiToggles.show.manualEntryOption = true;
  state.uiToggles.show.manualEntry = false;

  // Reset the states
  state.BlockInputs.companyName.invalidFeedback = "";
  state.BlockInputs.companyName.state = null;

  state.BlockInputs.companyAddressLine1.state = null;
  state.BlockInputs.companyAddressLine1.answer = "";
}

/** Toggles the manual entry form */
function toggleManualEntry(state: BlockCompanyInstance) {
  // Hide the company search and select
  state.uiToggles.show.companySearch = false;
  state.uiToggles.show.companySelect = false;

  state.uiToggles.show.manualEntryOption = false;

  // Reset the company name state
  state.BlockInputs.companyName.invalidFeedback = "";
  state.BlockInputs.companyName.state = null;

  // Display an option to return to the company search
  state.$nextTick(() => {
    state.uiToggles.show.lookupOption = true;
    state.uiToggles.show.manualEntry = true;
  });
}

/** Runs the validations when the Form submit mechanism is triggered */
export async function validations(state: BlockCompanyInstance): Promise<boolean> {
  if (!validateCompanyName(state)) return false;

  // Run validations for the company search
  if (state.uiToggles.show.companySearch) {
    if (!validateCompanySearched(state)) return false;
    if (!validateCompanySelected(state)) return false;
  }

  // Run validations for the manual entry
  if (state.uiToggles.show.manualEntry) {
    if (!validateCompanyAddressLine1(state)) return false;
  }

  return true;
}

function validateCompanyAddressLine1(state: BlockCompanyInstance) {
  if (isNil(state.BlockInputs.companyAddressLine1.answer) || state.BlockInputs.companyAddressLine1.answer === "") {
    state.BlockInputs.companyAddressLine1.state = false;
    state.BlockInputs.companyAddressLine1.invalidFeedback = state.uiMessages.companyAddressMissing;
    return false;
  }
  return true;
}

function validateCompanySearched(state: BlockCompanyInstance): boolean {
  if (!state.BlockInputs.companySelect.options || state.BlockInputs.companySelect.options.length === 0) {
    state.BlockInputs.companyName.state = false;
    state.BlockInputs.companyName.invalidFeedback = state.uiMessages.companyNotSearched;
    return false;
  }
  return true;
}
/** Runs the computes when the Form submit mechanism is triggered */
export async function computes(state: BlockCompanyInstance): Promise<boolean> {
  const companyStores: BlockCompanyPartialStores = {};

  if (state.uiToggles.show.companySelect) {
    const company = findSelectedCompany(state);

    if (!company) {
      state.BlockInputs.companySelect.invalidFeedback = state.uiMessages.companyNotSelected;
      state.BlockInputs.companySelect.state = false;
      return false;
    }

    companyStores.companyName = company.name;
    companyStores.companyNumber = company.company_number;
    companyStores.companyAddressLine1 = company.line_1;
    companyStores.companyPostcode = company.postcode;
    companyStores.companyCity = company.town;
    companyStores.companyClassification = company.classification ?? null;
  } else if (state.uiToggles.show.manualEntry) {
    companyStores.companyName = state.BlockInputs.companyName.answer;
    companyStores.companyAddressLine1 = state.BlockInputs.companyAddressLine1.answer;
  }

  const compactedCompanyStores = omitBy(companyStores, isNil) as BlockCompanyPartialStores;
  await saveCompany(state, compactedCompanyStores);

  Object.keys(state.BlockInputs).forEach((key) => {
    state.BlockInputs[key].state = true;
  });
  return true;
}

export function findSelectedCompany(state: BlockCompanyInstance) {
  const companySelected = state.BlockInputs.companySelect.answer;
  // Find the matching company from the list of companies

  if (!state.companies) {
    return null;
  }
  const company = state.companies.find((company) => convertCompanyToDisplayName(company) === companySelected);

  return company;
}

export function validateCompanySelected(state: BlockCompanyInstance): boolean {
  state.BlockInputs.companySelect.invalidFeedback = "";
  state.BlockInputs.companySelect.state = null;

  if (!state.BlockInputs.companySelect.answer || state.BlockInputs.companySelect.answer.length === 0) {
    state.BlockInputs.companySelect.invalidFeedback = state.uiMessages.companyNotSelected;
    state.BlockInputs.companySelect.state = false;
    return false;
  }

  return true;
}

/** Validates the entered company name */
export function validateCompanyName(state: BlockCompanyInstance): boolean {
  state.BlockInputs.companyName.invalidFeedback = "";
  state.BlockInputs.companyName.state = null;

  if (!state.BlockInputs.companyName.answer || state.BlockInputs.companyName.answer.length === 0) {
    state.BlockInputs.companyName.invalidFeedback = state.uiMessages.companyNameMissing;
    state.BlockInputs.companyName.state = false;
    return false;
  }

  if (state.BlockInputs.companyName.answer.length < 4) {
    state.BlockInputs.companyName.invalidFeedback = state.uiMessages.companyNameTooShort;
    state.BlockInputs.companyName.state = false;
    return false;
  }

  return true;
}

export async function searchCompany(state: BlockCompanyInstance) {
  state.uiToggles.loaders.isSearchingForCompany = true;
  state.uiToggles.show.companySelect = false;

  state.BlockInputs.companyName.invalidFeedback = "";
  state.BlockInputs.companyName.state = null;
  state.BlockInputs.companySelect.state = null;
  state.BlockInputs.companySelect.invalidFeedback = "";
  state.BlockInputs.companySelect.options = [];
  state.BlockInputs.companySelect.answer = "";

  // Validate the company name
  if (!validateCompanyName(state)) {
    state.uiToggles.loaders.isSearchingForCompany = false;
    return;
  }

  const companyName = state.BlockInputs.companyName.answer;
  const claimId = state.claimId;
  const { data: workspaceId } = await state.funnelsService.getFunnelAuthor(state.funnelId);

  const { data: response } = await searchCompanyFromT2a(
    companyName,
    claimId,
    state.funnelId,
    state.pageId,
    workspaceId
  );

  state.uiToggles.show.manualEntryOption = true;

  // If we were unable to find the list of companies then display an error
  if (isClaimsGateFunctionReturnedError(response)) {
    state.BlockInputs.companyName.invalidFeedback = state.uiMessages.companyNotFound;
    state.BlockInputs.companyName.state = false;
    state.uiToggles.loaders.isSearchingForCompany = false;

    return;
  }

  // Set the list of returned companies into a dropdown
  const companies = response;

  // If we were able to find the list of companies then store it in the state
  state.companies = companies;

  // Setup the UI to show the dropdown
  state.BlockInputs.companySelect.options = companies.map((company) => {
    return convertCompanyToDisplayName(company);
  });

  // Hide the company search input
  state.uiToggles.loaders.isSearchingForCompany = false;

  // Show the dropdown
  state.uiToggles.show.companySelect = true;
}

function convertCompanyToDisplayName(company: BusinessListEntity): string {
  return `${company.name}, ${company.addr_single_line}`;
}

/** Type guard to check if a given value is a ClaimsGateFunctionReturnedError */
function isClaimsGateFunctionReturnedError(
  response: ClaimsGateGetCompanyResponse | ClaimsGateFunctionReturnedError
): response is ClaimsGateFunctionReturnedError {
  return !Array.isArray(response);
}

/** Fetches a given vehicle from the T2A backend */
async function searchCompanyFromT2a(
  companyName: string,
  claimId: string,
  funnelId: string,
  pageId: string,
  workspaceId: string
) {
  return onCallGateway<"searchCompany">({
    functionName: "searchCompany",
    data: {
      companyName,
      claimId,
      funnelId,
      pageId,
      workspaceId,
    },
  });
}

export async function mounted(state: BlockCompanyInstance) {
  state.userId = state.userService.getUserId();

  await getClaimDataService<BlockCompanyInstance>(state);

  // For each of the blcok inputs
  Object.keys(state.BlockInputs).forEach((key) => {
    state.BlockInputs[key].state = null;
    state.BlockInputs[key].invalidFeedback = "";
    state.BlockInputs[key].answer = "";
  });

  // Reset the dropdown options
  state.BlockInputs.companySelect.options = [];

  state.uiToggles.show.companySearch = true;
}

/** Saves the vehicle stored in the state to the claim data service */
export async function saveCompany(state: BlockCompanyInstance, company: BlockCompanyPartialStores): Promise<boolean> {
  const { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);
  const hashedVehicle = await state.variablesService.hashData(company, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }

  return true;
}
