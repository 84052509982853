import { Props } from "@/types";

export function getProps() {
  return {
    icons: {
      type: Array as () => Array<{ iconType: string; iconName: string }>,
      required: true,
      default: () => [
        { iconType: "faCarSide", iconName: "Car" },
        { iconType: "faVanShuttle", iconName: "Van" },
        { iconType: "faCaravanSimple", iconName: "Caravan" },
        { iconType: "faCaravan", iconName: "Motorhome" },
        { iconType: "faTruckField", iconName: "Horse Box" },
        { iconType: "faSailboat", iconName: "Boat" },
        { iconType: "faPlane", iconName: "Plane" },
        { iconType: "faMotorcycle", iconName: "Motorbike" },
        { iconType: "faBicycle", iconName: "E-Bike" },
        { iconType: "faMoped", iconName: "Moblity Scooter" },
        { iconType: "faTrailer", iconName: "Trailer" },
      ],
      description: "The icons which will appear to be selected",
    },

    padding: {
      type: String,
      required: false,
      default: "None",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,

    info: {
      type: String,
      required: false,
      default: "",
      description: "The info text above the block",
    } as Props.CGPropExtras<string>,

    label: {
      type: String,
      required: false,
      default: "",
      description: "The label above the block",
    } as Props.CGPropExtras<string>,

    storeAs: {
      type: [Object, String],
      required: true,
      default: "",
      description: "The name of the variable to store the selected icon",
    },

    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },

    answer: {
      type: String,
      required: false,
      default: "",
      description: "The icon that was clicked",
    },
  };
}
