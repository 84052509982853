export default {
  me: "मुझे",
  myClaims: "मेरे क्लेम्स",
  menu: {
    myDetails: "मेरे विवरण",
    settings: "सेटिंग्स",
    legal: "विधिक",
    logOut: "लॉग आउट करें",
    logIn: "लॉगिन करें",
  },
};
