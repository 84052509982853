import { getClaimDataService } from "@/helpers/vue";
import { BlockCarwebInstance } from "./BlockCarweb";

import { resetToSearchVehicleView, searchVehicle } from "./methods";
export async function mounted(state: BlockCarwebInstance) {
  hideVehicleSearch(state);
  state.userId = state.userService.getUserId();

  getClaimDataService<BlockCarwebInstance>(state);

  resetToSearchVehicleView(state);
  await parseQueryParameters(state);
}

async function parseQueryParameters(state: BlockCarwebInstance): Promise<void> {
  if (Object.keys(state.$route.query)?.length > 0 && state.$route.query?.vehicleRegistration) {
    state.vrm = state.$route.query?.vehicleRegistration?.toString();
  }

  if (state.vrm) {
    await searchVehicle(state);
    state.uiToggles.isSearchingByQueryParamaters = false;
  }
}

/** Hides the vehcile search if a query parameter related to vehicleRegistration has been passed */
function hideVehicleSearch(state: BlockCarwebInstance): void {
  if (Object.keys(state.$route.query)?.length > 0 && state.$route.query?.vehicleRegistration) {
    state.uiToggles.isSearchingByQueryParamaters = true;
  }
}
