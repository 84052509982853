<script lang="ts">
// Vue & Types
import Vue from "vue";
import { BlockProps, FormRoute, Props } from "@/types";
import { Milberg } from "@claimsgate/core";
import {
  fireCarwebApiError,
  fireCarwebVehicleFound,
  fireCarwebVehicleNotFound,
  fireCarwebVrmEntered,
} from "../carWeb/sentry";
interface BlockButtonProps {
  searchRegButton: Partial<BlockProps.Button>;
  cancelCherishSelect: Partial<BlockProps.Button>;
  cherishVehicleSelectButton: Partial<BlockProps.Button>;
  selectKeeperButton: Partial<BlockProps.Button>;
  cancelSelectKeeperButton: Partial<BlockProps.Button>;
}

interface BlockTexts {
  milbergVrmEntryTitle: Partial<BlockProps.Text>;
  milbergVrmEntryTitle2: Partial<BlockProps.Text>;
  milbergVrmEntrySubtitle: Partial<BlockProps.Text>;
  milbergAcquistionDateText: Partial<BlockProps.HTML>;
  milbergAcquistionIneligibleDateText: Partial<BlockProps.HTML>;
}

interface BlockInputsProps extends BlockButtonProps, BlockTexts {
  cherishedPlateVehicleSelect: Partial<BlockProps.SingleSelect>;
  keeperSelect: Partial<BlockProps.SingleSelect>;
}

const texts: BlockTexts = {
  milbergVrmEntryTitle: {
    text: "Mercedes-Benz",
    styles: "h2",
    padding: "0",
  },
  milbergVrmEntryTitle2: {
    text: "Diesel Emissions Claim",
    styles: "h2",
    padding: "0",
  },
  milbergVrmEntrySubtitle: {
    styles: "h4",
    text: "Did you own a Mercedes-Benz diesel vehicle after 2008? Find out if you're eligible to register a claim with us.",
  },
  milbergAcquistionDateText: {
    html: "",
  },
  milbergAcquistionIneligibleDateText: {
    html: "",
  },
};

type UiToggleOptions = "showRegistration" | "showCherishedPlateSelect" | "showKeeperSelect";

// Constants
const blocks: Array<"cherishedPlateVehicleSelect" | "keeperSelect"> = ["cherishedPlateVehicleSelect", "keeperSelect"];
const buttons: BlockButtonProps = {
  searchRegButton: { text: "Continue", submitButtonDefault: false, block: false },
  cancelCherishSelect: {
    text: "Cancel",
    variant: "outline-primary",
    submitButtonDefault: false,
    align: "center",
  },
  cherishVehicleSelectButton: { text: "Continue", submitButtonDefault: false, block: false },
  selectKeeperButton: { text: "Continue", submitButtonDefault: false, block: false },
  cancelSelectKeeperButton: {
    text: "Cancel",
    outline: true,
    submitButtonDefault: false,
  },
};

// Components
import BlockLink from "@/components/shared/blocks/link.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockSubtitle from "@/components/shared/blocks/text/subtitle.vue";
import BlockHtml from "@/components/shared/blocks/html.vue";

// Services
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { VehicleService, GetVehicleResult, VehicleApi } from "@/helpers/ClaimsGate/vehicles/VehicleService";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils.js";

import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { eventComputed, formComputed, themesComputed } from "@/state/helpers";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { msg } from "@/helpers/ClaimsGate/ResponseMessageService";
import { ParserService } from "@/helpers/ClaimsGate/pages/parsers";

export default Vue.extend({
  name: "BlockCarRegistrationInput",
  components: { BlockPadding, BlockSingleSelect, BlockButton, BlockLink, BlockSubtitle, BlockHtml },
  props: {
    label: {
      type: String,
      default: "Vehicle registration number",
      required: false,
      allowVariable: true,
      description: "The label on the input",
    } as Props.CGPropExtras<string>,
    invalidFeedback: {
      type: String,
      required: false,
      allowVariable: false,
    } as Props.CGPropExtras<string>,

    description: {
      type: String,
      required: false,
      allowVariable: false,
      description: "The help text surrounding the input",
    } as Props.CGPropExtras<string>,
    stores: {
      type: Array,
      required: false,
      default: () => [
        { name: "model", type: "string" },
        { name: "make", type: "string" },
        { name: "keeperChangeHistory", type: "array" },
        { name: "keeperStartDate", type: "date" },
        { name: "keeperEndDate", type: "date" },
        { name: "isCurrentKeeper", type: "boolean" },
        { name: "vinLast5", type: "string" },
        { name: "dateFirstRegistered", type: "date" },
        { name: "engineModelCode", type: "array" },
        { name: "engineFamily", type: "array" },
        { name: "fuelType", type: "string" },
        { name: "vehicleRegistration", type: "string" },
        { name: "vin", type: "string" },
        { name: "engineNumber", type: "string" },
        { name: "plateChangeCount", type: "number" },
        { name: "numberOfDoors", type: "string" },
        { name: "keeperChangeCount", type: "number" },
        { name: "euroStatus", type: "string" },
        { name: "yearOfManufacture", type: "number" },
        { name: "range", type: "string" },
        { name: "series", type: "string" },
        { name: "numberOfCylinders", type: "number" },
        { name: "engineCapacity", type: "number" },
        { name: "maximumPowerInKw", type: "string" },
        { name: "bhp", type: "number" },
        { name: "torqueNm", type: "number" },
        { name: "torqueFtLb", type: "number" },
        { name: "colour", type: "string" },
        { name: "nominalEngineCapacity", type: "number" },
        { name: "mercedesEmissionsRecallMandatoryStatus", type: "string" },
        { name: "mercedesEmissionsRecallEligibleForExclusion", type: "boolean" },
        { name: "mercedesEmissionsRecallAccomplished", type: "boolean" },
        { name: "version", type: "string" },
        { name: "engineSize", type: "number" },
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<any>>,
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,
    api: {
      type: String,
      description: "The API to use to retrieve the vehicle data",
      default: "UKVD",
      valid: ["UKVD", "CarWeb"],
      required: false,
    } as Props.CGPropExtras<VehicleApi>,
    apiKey: {
      type: String,
      description: "The API key to use to connect to the selected Vehicle API",
      default: undefined,
      required: false,
    } as Props.CGPropExtras<string>,
    recallData: {
      type: Boolean,
      description: "When set emissions recall data will be collected",
      default: false,
      required: false,
    } as Props.CGPropExtras<boolean>,
  },
  data() {
    return {
      selectedVehicle: undefined as Record<string, any>,
      Milberg,
      parserService: new ParserService(),
      BlockInputs: {
        ...UserVariableBlocksService.getBlocks(blocks),
        ...UserVariableBlocksService.getButtons(buttons as any),
        ...texts,
      } as BlockInputsProps,
      vrm: "",
      singleSelectOptionsMap: [] as Array<{ vehicle: Record<string, any>; text: string }>,
      keeperChangeHistoryOptionsMap: [] as Array<{ orginal: string; text: string }>,
      isButtonLoading: false,
      vrmInputState: null as boolean | null,
      uiToggles: {
        showRegistration: true,
        showCherishedPlateSelect: false,
        showKeeperSelect: false,
      },
      uiMessages: {
        enterReg: "Please enter a valid registration number",
        noVehicleFound: "No vehicle with that registration number could be found, please try again",
        errorFindingVehicle:
          "Whoops, there was an error searching for your vehicle. Our team has been notified, please try again later.",
        noVehicleSelected: "Please select the vehicle you owned",
        noKeeperSelected: "Please select the date you acquired the vehicle",
      },
      vrmInputInvalidFeedback: "",
      infoModalService: new InfoModalService(this.$infoModal),
      userVariableService: new UserVariableService(),
      vehicleService: new VehicleService(),
      variableService: new VariableService(),
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      userService: getUserHelper(),

      claimDataService: null as ClaimDataService,
      claimId: this.$route.params.claimId, // /:claimId
      funnelId: this.$route.params.funnelId, // /:funneld
      userId: undefined as string | undefined,
    };
  },
  watch: {
    /**
     * Watch for modifications to the invalid feedback prop and ensure
     * those changes are passed to the associated BlockInput prop
     */
    invalidFeedback: {
      handler: function (invalidFeedback) {
        // ! This is bespoke logic to handle setting page level errors
        // ! on the BlockVehicle
        // ! Please understand the fix is only temporary and may be confusing to understand.

        // Set any given invalid feedback on the registration entry box
        this.vrmInputInvalidFeedback = invalidFeedback;
        this.vrmInputState = false;

        // Ensure there are no outstanding loaders when invalid feedback is given
        Object.values(this.BlockInputs).forEach(
          (blockInput: { isProcessing: boolean }) => (blockInput.isProcessing = false)
        );

        // Ensure the registration entry box is being displayed on the screen
        this.uiToggle("showRegistration");
      },
    },
  },
  computed: {
    ...eventComputed,
    ...formComputed,
    ...themesComputed,
  },
  methods: {
    uiToggle(element: UiToggleOptions): void {
      if (element === "showRegistration") {
        this.BlockInputs.cherishedPlateVehicleSelect.state = null;
        this.BlockInputs.keeperSelect.state = null;
      }
      for (const key of Object.keys(this.uiToggles)) {
        if (element === key) {
          this.$nextTick(() => {
            this.uiToggles[key] = true;
          });
        } else {
          this.uiToggles[key] = false;
        }
      }
    },
    async cherishedVehicleSelected(): Promise<void> {
      const answer = this.BlockInputs.cherishedPlateVehicleSelect.answer;
      // this.BlockInputs.cherishVehicleSelectButton.state = null;

      if (!answer) {
        this.BlockInputs.cherishedPlateVehicleSelect.state = false;
        this.BlockInputs.cherishedPlateVehicleSelect.invalidFeedback = this.uiMessages.noVehicleSelected;
        return;
      }
      if (answer) {
        this.BlockInputs.cherishVehicleSelectButton.isProcessing = true;

        const vehicle = this.singleSelectOptionsMap.find((option) => option.text === answer);
        const selectedVin = vehicle.vehicle.vin;
        console.log("VIN SELECTED", selectedVin);

        let vehicleResult: GetVehicleResult;

        ({ data: vehicleResult } = await this.vehicleService.getVehicle(selectedVin, this.api, false, this.apiKey));

        this.setUpKeeperSelect(vehicleResult.vehicle);

        this.uiToggle("showKeeperSelect");

        this.BlockInputs.cherishVehicleSelectButton.isProcessing = false;
      }
    },
    async saveKeeperSelect(): Promise<void> {
      this.BlockInputs.keeperSelect.state = null;
      this.BlockInputs.selectKeeperButton.isProcessing = true;
      window.console.log("[keeperSelect]", this.BlockInputs.keeperSelect);
      if (!this.BlockInputs.keeperSelect.answer) {
        this.BlockInputs.keeperSelect.state = false;
        this.BlockInputs.keeperSelect.invalidFeedback = this.uiMessages.noKeeperSelected;
        this.BlockInputs.selectKeeperButton.isProcessing = false;
        return;
      }

      this.selectedVehicle.keeperStartDate = this.BlockInputs.keeperSelect.answer;

      // Find the end date of keeper

      const selectedKeeperIndex = this.BlockInputs.keeperSelect.options.indexOf(this.selectedVehicle.keeperStartDate);
      const keeperEndDate = this.BlockInputs.keeperSelect.options[selectedKeeperIndex + 1];

      if (keeperEndDate) {
        this.$set(this.selectedVehicle, "keeperEndDate", keeperEndDate);
        this.$set(this.selectedVehicle, "isCurrentKeeper", false);
      } else {
        delete this.selectedVehicle["keeperEndDate"];
        this.$set(this.selectedVehicle, "isCurrentKeeper", true);
      }

      await this.saveVehicle();
    },

    setUpKeeperSelect(selectedVehicle: Record<string, any>): void {
      this.selectedVehicle = selectedVehicle;

      this.BlockInputs.keeperSelect.options = selectedVehicle.keeperChangeHistory.map((date: any) => date);
      this.BlockInputs.milbergAcquistionDateText.html = `<div><div><h2>Great news</h2><h4>We believe your vehicle <b>MERCEDES-BENZ</b>, <b>${selectedVehicle.model}</b>, <b>${selectedVehicle.yearOfManufacture}</b> was affected by the diesel emissions scandal.</h4><h4>Please complete the next steps to find out <strong>how much compensation </strong>you could be due.</h4></div></div>`;
      this.BlockInputs.milbergAcquistionIneligibleDateText.html = `<div><div><h2>Vehicle found</h2><h4>We found the vehicle <b>${selectedVehicle.make.toUpperCase()}</b>, <b>${
        selectedVehicle.model
      }</b>, <b>${selectedVehicle.yearOfManufacture}</b></h4></div></div>`;
    },
    emitSubmit(): void {
      this.$store.dispatch("events/fire", { name: this.eventValues.next });
    },

    async saveVehicle(): Promise<void> {
      const vehicle = this.selectedVehicle;
      if (this.claimId && this.funnelId) {
        const { data: funnelVariables } = await this.funnelsService.getFunnelVariables(this.funnelId);
        window.console.log("[saveVehicle] vehicle is", vehicle, funnelVariables);
        const hashedVehicle = await this.variableService.hashData(vehicle, funnelVariables);
        window.console.log("[saveVehicle] hashed data", hashedVehicle);
        for (const [key, value] of Object.entries(hashedVehicle)) {
          window.console.log("[saveVehicle]", key, value);
          this.claimDataService.setArtefact(key, value);
        }
        this.emitSubmit();
        // this.BlockInputs.selectKeeperButton.isProcessing = false;
      } else {
        this.infoModalService.fire("error", { text: msg.errors.failedToSave });
        this.BlockInputs.selectKeeperButton.isProcessing = false;
      }
    },
    /**
     * Search for vehicle results in either none found (break), or multiple (show cherish select),
     * or single (show keeper select)
     * */
    async getVehicle(): Promise<void> {
      this.BlockInputs.searchRegButton.isProcessing = true;
      this.vrmInputState = null;

      try {
        let vehicleResult: GetVehicleResult;
        let error: any, exception: any;

        if (this.vrm.length === 0) {
          this.vrmInputState = false;
          this.vrmInputInvalidFeedback = this.uiMessages.enterReg;
          this.BlockInputs.searchRegButton.isProcessing = false;
          return;
        }

        fireCarwebVrmEntered(this.vrm);

        ({
          data: vehicleResult,
          error,
          exception,
        } = await this.vehicleService.getVehicle(this.vrm, this.api, this.recallData, this.apiKey));

        if (error) {
          throw error;
        }
        if (exception) {
          throw exception;
        }

        if (!vehicleResult || vehicleResult.vehicleStatus === "none") {
          fireCarwebVehicleNotFound(this.vrm);
          // Display invalid feedback on the registration input

          this.vrmInputState = false;
          this.vrmInputInvalidFeedback = this.uiMessages.noVehicleFound;
          this.BlockInputs.searchRegButton.isProcessing = false;
          return;
        } else if (vehicleResult.vehicleStatus === "multiple") {
          if (vehicleResult.vrmAssignmentHistory?.length === 1) {
            // Only one eligible vehicle in vrm History
            const vrmAssignmentVin = vehicleResult.vrmAssignmentHistory[0].vin;
            //Can only reach this point if using UKVD so no need to check if apiKey exists
            let vimVehicleResult: GetVehicleResult, vimError: any, vimException: any;
            ({
              data: vimVehicleResult,
              error: vimError,
              exception: vimException,
            } = await this.vehicleService.getVehicle(vrmAssignmentVin, this.api, false, this.apiKey));
            if (vimError) {
              throw vimError;
            }
            if (vimException) {
              throw vimError;
            }

            vehicleResult = vimVehicleResult;
            window.console.log("Querying by VIN result", vehicleResult);
            // Go to keeper select
            this.uiToggle("showKeeperSelect");
            this.setUpKeeperSelect(vehicleResult.vehicle);

            this.BlockInputs.searchRegButton.isProcessing = false;
            return;
          } else {
            this.uiToggle("showCherishedPlateSelect");

            // Populate the SingleSelect with text values for Vrm Assingment History
            this.singleSelectOptionsMap = vehicleResult.vrmAssignmentHistory.map((vehicle) => {
              return {
                vehicle: vehicle,
                text: `${vehicle?.make} ${vehicle?.model} ${vehicle?.yearOfManufacture}`,
              };
            });

            this.BlockInputs.cherishedPlateVehicleSelect.options = this.singleSelectOptionsMap.map(
              (vehicle) => vehicle.text
            );

            this.BlockInputs.searchRegButton.isProcessing = false;
            return;
          }
        } else if (vehicleResult.vehicleStatus === "single") {
          fireCarwebVehicleFound(this.vrm);
          // Car has been found successfully we can go to keeper select
          this.uiToggle("showKeeperSelect");
          this.BlockInputs.searchRegButton.isProcessing = false;

          this.setUpKeeperSelect(vehicleResult.vehicle);

          return;
          // this.BlockInputs.searchRegButton.isProcessing = false;
        } else {
          // Possibly unknown error has occoured
          fireCarwebApiError(this.vrm);
          await this.infoModalService.fire("error", {
            title: "Vehicle not found",
            text: `${this.uiMessages.errorFindingVehicle}`,
          });
          this.BlockInputs.searchRegButton.isProcessing = false;
        }
      } catch (exception) {
        fireCarwebApiError(this.vrm);
        await this.infoModalService.fire("error", {
          title: "Vehicle not found",
          text: `${this.uiMessages.errorFindingVehicle}`,
        });
        this.BlockInputs.searchRegButton.isProcessing = false;
      }
    },
    /** Initial triggger when user enters Rego */
    async submit(): Promise<void> {
      await this.getVehicle();
    },
  },
  async mounted() {
    this.userId = this.userService.getUserId();

    if (!this.claimId) {
      this.claimId = this.userService.createClaimId();

      // Now we need to emit a message to Form.vue which will update its claimId
      this.$store.dispatch("events/fire", {
        name: this.eventValues.updateClaimId,
        claimId: this.claimId,
      });
    }

    if (!this.claimDataService) {
      window.console.log("this.userId is...", this.userId);
      this.claimDataService = new ClaimDataService(this.userId, this.claimId);
      this.claimDataService.setArtefact("claimId", this.claimId);
    }

    this.BlockInputs.selectKeeperButton.isProcessing = false;
    this.BlockInputs.cherishVehicleSelectButton.isProcessing = false;
    this.BlockInputs.searchRegButton.isProcessing = false;

    // ! This is an explicit handler to support passing a vehicle registration
    // ! through query parameters
    if (Object.keys(this.$route.query)?.length > 0 && this.$route.query?.vehicleRegistration) {
      this.vrm = this.$route.query?.vehicleRegistration?.toString();
      const { data: hashedQueryParameters } = await this.parserService.parseQueryParameters(this.$route as FormRoute);

      if (hashedQueryParameters) {
        this.claimDataService.setManyArtefacts(hashedQueryParameters);
        await this.submit();
      }
    }
  },
});
</script>
<style scoped>
.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.selected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #3452e1 !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #eff2f7 !important;
  transition: 0.25s;
}

.input-group > .input-group-prepend {
  flex: 0 0 15%;
}
.input-group .input-group-text {
  width: 100%;
}
/* Reg Input styling */
.reg-input-selected {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}
.input-group-prepend:focus {
  border-color: var(--primary) !important;
}
.btn-glow,
.btn-glow:active,
.btn-glow:focus,
.btn-glow:hover {
  box-shadow: 0px 0px 30px -10px #3452e1 !important;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.disabled {
  background-color: #e9ecef !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}

.form-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.prepend-border {
  border: 2px solid var(--primary) !important;
}
</style>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <template v-if="uiToggles.showRegistration">
      <!-- BlockVrmInput -->

      <template v-if="funnelId === Milberg.Funnels.DIESEL_EMISSIONS_FUNNEL_ID">
        <BlockSubtitle v-bind="BlockInputs.milbergVrmEntryTitle" />
        <BlockSubtitle v-bind="BlockInputs.milbergVrmEntryTitle2" />
        <BlockSubtitle v-bind="BlockInputs.milbergVrmEntrySubtitle" />
        <BlockPadding padding="25" />
      </template>
      <h5 v-if="label" class="mb-1" :style="theme.textColourStyles">
        {{ label }}
      </h5>
      <p v-if="description && description.length > 0" class="mb-0">
        {{ description }}
      </p>
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text class="justify-content-center reg-input-selected">
            <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          class="border reg-input-selected"
          v-mask="'XXXXXXXX'"
          size="lg"
          v-model="vrm"
          :state="vrmInputState"
          placeholder="Registration number"
          @update="vrmInputState = null"
        ></b-form-input>
      </b-input-group>
      <template v-if="!vrmInputState">
        <b-form-invalid-feedback :state="vrmInputState" id="input-live-feedback">
          {{ vrmInputInvalidFeedback }}
        </b-form-invalid-feedback>
      </template>
      <BlockPadding />
      <BlockButton @clicked="submit()" v-bind="BlockInputs.searchRegButton" v-bind.sync="BlockInputs.searchRegButton" />
    </template>

    <template v-else-if="uiToggles.showCherishedPlateSelect">
      <BlockSingleSelect
        v-bind="BlockInputs.cherishedPlateVehicleSelect"
        v-bind.sync="BlockInputs.cherishedPlateVehicleSelect"
      />
      <BlockPadding />
      <BlockButton
        v-bind="BlockInputs.cherishVehicleSelectButton"
        v-bind.sync="BlockInputs.cherishVehicleSelectButton"
        @clicked="cherishedVehicleSelected()"
      />
      <BlockPadding padding="15" />
      <BlockLink
        :emitClick="true"
        @clicked="uiToggle('showRegistration')"
        :text="'Try a different registration number'"
      />
    </template>

    <template v-else-if="uiToggles.showKeeperSelect">
      <template v-if="funnelId === Milberg.Funnels.DIESEL_EMISSIONS_FUNNEL_ID">
        <template
          v-if="
            selectedVehicle.mercedesEmissionsRecallMandatoryStatus === 'Mandatory' ||
            selectedVehicle.mercedesEmissionsRecallMandatoryStatus === 'Voluntary'
          "
        >
          <BlockHtml v-bind="BlockInputs.milbergAcquistionDateText" />
          <BlockPadding padding="20" />
        </template>

        <template v-else>
          <BlockHtml v-bind="BlockInputs.milbergAcquistionIneligibleDateText" />

          <BlockPadding padding="20" />
        </template>
      </template>

      <BlockSingleSelect v-bind="BlockInputs.keeperSelect" v-bind.sync="BlockInputs.keeperSelect" />
      <BlockPadding />

      <BlockButton
        v-bind="BlockInputs.selectKeeperButton"
        v-bind.sync="BlockInputs.selectKeeperButton"
        @clicked="saveKeeperSelect()"
      />

      <BlockPadding padding="15" />
      <!-- for some reason the cancel button does not toggle the  loader off -->
      <BlockLink
        :emitClick="true"
        @clicked="uiToggle('showRegistration')"
        :text="'Try a different registration number'"
      />
    </template>
  </div>
</template>
