<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
// Components
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import ClaimsGateButton from "@/components/shared/styleWrappers/button/claimsgateButton.vue";
// Helpers
import { gatewayHelper } from "../helpers/gatewayHelper";
import { viewSubmit } from "../helpers/viewSubmit";
import { baseProps } from "../helpers/baseProps";
import { formatAddress } from "../helpers/formatAddress";
import { handleUnknownError } from "../helpers/handleUnknownError";
// Types
import type { AddressInput, CheckboardAddress } from "../helpers/CheckboardCreditCheck";

export default (Vue as VueConstructor<Vue & AddressInput.Refs>).extend({
  name: "AddressHistory",
  components: { BlockInput, BlockDropdown, ClaimsGateButton },
  props: {
    ...baseProps(),
    selectedAddress: {
      type: Object as PropType<CheckboardAddress>,
      required: true,
    },
  },
  data() {
    return {
      isHistoryComplete: false,
      isSearching: false,
      isSubmitting: false,
      postcodeLocked: false,
      invalidFeedback: "",
      invalidFeedbackOnAdd: "",
      additionalAddress: null as CheckboardAddress | null,
      addressHistory: [this.selectedAddress] as CheckboardAddress[],
      returnedAddresses: [] as CheckboardAddress[],
    };
  },
  computed: {
    dropdownOptions(): string[] {
      return this.returnedAddresses.map((address) => this.formatAddress(address));
    },
  },
  methods: {
    formatAddress,
    handleAddressSelect(address: string) {
      this.additionalAddress = this.returnedAddresses.find((addressObj) => this.formatAddress(addressObj) === address);
    },
    async searchAddress() {
      this.invalidFeedback = "";
      this.returnedAddresses = [];
      const postcode = this.$refs.input.newAnswer.toString().replace(/\s/g, "");
      if (postcode.length < 5 || postcode.length > 7) {
        this.invalidFeedback = "Please enter a valid UK postcode, between 5 and 7 characters long.";
      } else {
        try {
          this.isSearching = true;
          const data = {
            claimId: this.claimId,
            userId: this.userId,
            checkId: this.checkId,
            postcode,
          };
          const result = await gatewayHelper("checkboardAddressLookup", data);
          if (result.addresses.length === 0) {
            this.invalidFeedback = "No addresses found for this postcode. Please check your postcode and try again.";
          } else {
            this.returnedAddresses = result.addresses;
          }
        } catch (error) {
          console.error(`Error searching for addresses: ${error}`);
          handleUnknownError(this, this.check);
        } finally {
          this.isSearching = false;
        }
      }
    },
    addAddress() {
      this.invalidFeedbackOnAdd = "";
      if (this.additionalAddress) {
        const isDuplicate = this.addressHistory.some(
          (address) => address.address_id === this.additionalAddress.address_id
        );

        if (!isDuplicate) {
          this.addressHistory.push(this.additionalAddress);
          this.returnedAddresses = [];
          this.$refs.input.newAnswer = "";
          this.postcodeLocked = true;
        } else {
          this.invalidFeedbackOnAdd = "This address is already in your history.";
        }
      }
    },
    handleAddAnotherAddress() {
      this.isHistoryComplete = false;
      this.additionalAddress = null;
      this.postcodeLocked = false;
    },
    handleCompleteHistory() {
      this.isHistoryComplete = true;
    },
    async submit() {
      try {
        const data = {
          claimId: this.claimId,
          userId: this.userId,
          checkId: this.checkId,
          addressHistory: this.addressHistory,
        };
        const result = await gatewayHelper("checkboardRunCheck", data);
        viewSubmit(this, "addressHistory", result);
      } catch (error) {
        console.error("Error submitting address history:", error);
        handleUnknownError(this, this.check);
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>

<template>
  <div class="text-center mx-auto d-flex flex-column">
    <h1>Address History View</h1>
    <div class="mx-auto my-3">
      <p class="text-lg mb-0">Provide your address history for the past 3 years.</p>
      <p class="text-lg font-weight-bold mt-3 mb-0">Address history record:</p>
      <ul class="text-md list-unstyled text-left">
        <li v-for="(address, index) in addressHistory" :key="address.address_id">
          <strong>{{ index + 1 }}.</strong> {{ formatAddress(address) }}
        </li>
      </ul>
    </div>
    <div>
      <p class="text-lg mb-0">Enter the postcode to search for additional address.</p>
      <BlockInput class="mb-2" inputType="text" ref="input" :disabled="postcodeLocked" />
      <p v-if="invalidFeedback" class="text-sm text-danger mb-2">{{ invalidFeedback }}</p>
      <ClaimsGateButton
        variant="primary"
        text="Search"
        @click="searchAddress"
        :loading="isSearching"
        loadingText=""
        :disabled="postcodeLocked"
      />
    </div>
    <div v-if="returnedAddresses.length">
      <p class="text-lg mt-4 mb-0">Select the address you would like to add.</p>
      <BlockDropdown
        class="mb-2"
        :options="dropdownOptions"
        @update:answer="handleAddressSelect"
        placeholder="Select an address"
      />
      <p v-if="invalidFeedbackOnAdd" class="text-sm text-danger mb-2">{{ invalidFeedbackOnAdd }}</p>
      <ClaimsGateButton variant="primary" text="Add" @click="addAddress" :disabled="!additionalAddress" />
    </div>
    <div v-if="addressHistory.length > 1 && addressHistory.includes(additionalAddress)">
      <p class="text-lg mt-4 mb-2">Would you like to add another address?</p>
      <ClaimsGateButton class="mr-2" variant="primary" text="Yes" @click="handleAddAnotherAddress" />
      <ClaimsGateButton variant="secondary-grey" text="No" @click="handleCompleteHistory" />
    </div>
    <ClaimsGateButton
      class="mt-4"
      variant="primary"
      text="Submit"
      @click="submit"
      :loading="isSubmitting"
      loadingText=""
      :disabled="!isHistoryComplete"
    />
  </div>
</template>
