<script lang="ts">
//@ts-check
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
// eslint-disable-next-line no-unused-vars
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { Props } from "@/types";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import Vue from "vue";
const inputPropsValue = inputProps({
  answerType: "string",
  extraProps: ["text", "linkTargets", "linkTexts", "linkTarget", "linkText"],
});

delete inputPropsValue.label;
delete inputPropsValue.info;

if (inputPropsValue.linkTarget) {
  inputPropsValue.linkTargets = [inputPropsValue.linkTarget];
}

if (inputPropsValue.linkText) {
  inputPropsValue.linkTexts = [inputPropsValue.linkText];
}

export default Vue.extend({
  name: "BlockCheckbox",
  components: { BlockInputWrapper },
  props: { ...(inputPropsValue as Props.BlockCheckbox) },
  data() {
    return {
      linkTargetCopy: [],
      BuilderUtility: BuilderUtility,
    };
  },

  methods: {
    setAnswerUndefined() {
      this.$emit("update:answer", "");
    },
  },
  mounted() {
    if (this.linkTargets) {
      this.linkTargets.forEach((linkTarget) => {
        console.log("linkTarget", linkTarget);
        this.linkTargetCopy.push(BuilderUtility.parseUrl(linkTarget));
      });
    }
  },

  watch: {
    linkTarget: {
      handler(newLink) {
        this.linkTargetCopy = BuilderUtility.parseUrl(newLink);
      },
    },
    answer: function (newVal) {
      if (newVal === "not_accepted") {
        this.setAnswerUndefined();
      }
    },
  },
  computed: {
    ...themesComputed,
    dataCyLabel(): string {
      return `checkbox-${this.text.toLowerCase().replace(/\s/g, "")}`;
    },
  },
});
</script>

<template>
  <BlockInputWrapper
    :padding="padding"
    :invalidFeedback="invalidFeedback"
    :state="state"
    :info="undefined"
    :label="undefined"
  >
    <div class="mt-1">
      <b-form-checkbox
        @change="
          $emit('update:answer', $event);
          $emit('update:state', null);
        "
        switch
        :checked="answer"
        :state="state"
        :require="required"
        :disabled="disabled"
        value="accepted"
        unchecked-value="not_accepted"
        :size="theme.size"
        class="x126"
        :ref="'b-checkbox'"
        v-bind:style="[$route.params.workspaceId && theme.primaryStyles ? theme.primaryStyles : {}]"
        :data-cy="`checkbox-${dataCyLabel}`"
      >
        <template v-for="(linkTarget, index) in linkTargets">
          <span
            v-if="linkTarget && linkTarget.length > 0"
            class="font-weight-normal"
            :style="theme.textColourStyles"
            :key="index"
          >
            <span v-if="index > 0"> and </span>

            {{
              $te("blocks.contactDetails." + (index > 0 ? text.toLowerCase() : text))
                ? $t("blocks.contactDetails." + (index > 0 ? text.toLowerCase() : text))
                : index > 0
                ? text
                : text
            }}
            <a :href="linkTarget" target="_blank">
              {{
                $te("blocks.contactDetails." + linkTexts[index])
                  ? $t("blocks.contactDetails." + linkTexts[index])
                  : linkTexts[index]
              }}
            </a>
          </span>
        </template>
        <span v-if="!linkTargets" class="font-weight-normal" :style="theme.textColourStyles">
          {{ $te("blocks.contactDetails." + text) ? $t("blocks.contactDetails." + text) : text }}
          <a v-if="linkTarget" :href="linkTarget" target="_blank"
            >{{ $te("blocks.contactDetails." + linkText) ? $t("blocks.contactDetails." + linkText) : linkText }}
          </a>
        </span>
      </b-form-checkbox>
    </div>
  </BlockInputWrapper>
</template>

<style scoped></style>
