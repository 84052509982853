import { FormState } from "../BlockFormInstance";
import { PageHelper } from "@/helpers/ClaimsGate/funnels/PageHelper";

import { ReminderSequence } from "@claimsgate/core-types";
import { getFirebaseBackend } from "@/authUtils";
import { userHasClaimWithFunnel } from "@claimsgate/core";
import { calculatePerformAtDate } from "./helpers";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";

/** Handles the reminder sequences to be fired on the currently processed page */
export async function handleReminderSequences(state: FormState) {
  if (!state.reminderSequences) {
    return;
  }

  await Promise.all(
    state.reminderSequences.map(async (reminderSequence) => {
      try {
        let startReminderSequence = false;

        // No reminder messages
        if (reminderSequence?.messages?.length === 0) {
          return;
        }

        // NewUser & ContactDetails triggers
        else if (reminderSequence.trigger === "contactDetails" || reminderSequence.trigger === "newUser") {
          const pageHasContactDetailsBlock = await PageHelper.calculatePageContainsContactDetailsBlock(state.page);

          if (reminderSequence.trigger === "newUser") {
            const userHasClaimWithThisFunnel = await userHasClaimWithFunnel(
              getFirebaseBackend().firestore(),
              state.userId,
              state.funnelId
            );

            if (!userHasClaimWithThisFunnel && pageHasContactDetailsBlock) {
              startReminderSequence = true;
            }
          } else if (reminderSequence.trigger === "contactDetails") {
            if (pageHasContactDetailsBlock) {
              startReminderSequence = true;
            }
          }
        }

        // Page trigger
        else if (reminderSequence.trigger === "page") {
          if (state.pageId === reminderSequence.triggerParameters.pageId) {
            startReminderSequence = true;
          }
        }

        // AgreementSigned trigger
        else if (reminderSequence.trigger === "agreementSigned") {
          const pageHasAgreementBlock = await PageHelper.calculatePageContainsAgreementBlock(state.page);
          if (pageHasAgreementBlock) {
            startReminderSequence = true;
          }
        }

        if (startReminderSequence) {
          await createReminderMessageTask(state, reminderSequence);
        }
      } catch (e) {
        console.error("!!! handleReminderSequences", e);
      }
    })
  );
}

/** Creates a task for a reminder message */
async function createReminderMessageTask(state: FormState, reminderSequence: ReminderSequence): Promise<boolean> {
  try {
    // Data sanity check
    if (!state.userId || !state.claimId || !state.funnelId || !state.page?.id || !reminderSequence?.id) {
      return false;
    }

    const reminderMessages = reminderSequence?.messages;
    const messageId = reminderMessages[0].id;

    const options = {
      userId: state.userId,
      claimId: state.claimId,
      funnelId: state.funnelId,
      reminderSequenceId: reminderSequence.id,
      pageId: state.page.id,
      messageId,
      useVariables: false,
      reminderPointer: 0,
      messageType: "reminder",
      sendingWindow: reminderSequence?.sendingWindow ?? null,
    };
    const taskRef = state.db.collection("tasks").doc();

    const performAt = calculatePerformAtDate(reminderMessages[0].delay, false);

    const taskData = {
      performAt: performAt,
      status: "scheduled",
      worker: "sendMessage",
      options: options,

      // Denormalise values from options so we can query the tasks collection
      messageType: "reminder",
      claimId: state.claimId,
      userId: state.userId,
    };

    const result = await onCallGateway<"createReminderMessageTask">({
      functionName: "createReminderMessageTask",
      data: taskData as any,
    });
    console.log("Create reminder message task result", result);

    // call gateway function to schedule the task

    console.log(">>> [createdTask] reminder message", taskRef.id);
    return true;
  } catch (exception) {
    console.error("!!! createReminderMessageTask", exception);
    return false;
  }
}
