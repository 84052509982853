import { blocks, BlockVehicleProps } from "./blocks";

export function getData(state: Vue) {
  return {
    splitTest: "",
    BlockInputs: {
      ...blocks.vehicles,
    } as BlockVehicleProps,
    uiToggles: {
      show: {
        motorspecs: false,
        carweb: false,
      },
    },
  };
}
