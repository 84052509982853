export default {
  email: {
    label: `ईमेल पता`,
    invalidFeedback: `इस क्षेत्र की आवश्यकता है`,
  },
  phone: {
    label: `फोन नम्बर`,
  },
  contactDetails: {
    mdrPrivacyPolicyText: `जारी रखने को प्रेस करने के द्वारा मुझे अपने क्लेम के बारे में Mishcon de Reya LLP से संपर्क करके प्रसन्नता हो रही है।s`,
    "Do you agree to the": "क्या आप सहमत हैं'",
    "Privacy Policy": "गोपनीयता नीति'",
  },

  invalidFeedback: {
    "Please enter a valid email address": "कृपया एक मान्य ईमेल पता दर्ज करें",
    "Please enter a valid phone number": "कृपया एक मान्य फ़ोन नंबर दर्ज करें",
    "First name must be at least 2 characters": "पहला नाम कम से कम 2 वर्ण होना चाहिए।",
    "Last name must be at least 2 characters": "अंतिम नाम कम से कम 2 वर्ण होना चाहिए।'",
    "Please select a title": "कृपया एक शीर्षक चुनें",
    "You must be at least 18 years old to use continue": "आगे बढ़ने के लिए आपको कम से कम 18 वर्ष की उम्र होनी चाहिए।",
  },

  button: {
    continue: "जारी रखें",
    previous: "पिछला",
  },

  address: {
    addressLine1: "पता पंक्ति 1",

    city: "शहर",
    status: "स्थिति",

    title: "टाइटल",
    firstName: "प्रथम नाम",
    middleName: "मिडिल नाम",
    lastName: "अंतिम नाम",
    dateOfBirth: "जन्म तिथि",
    postcode: "पोस्टकोड",
    livesInUk: "Do you live in the UK?",
    enterAddress: "Enter Your Address",
  },

  singleSelect: {
    Yes: "हां",
    No: "नहीं",
    "Exact figure": "सटीक आंकड़ा",
    Approximation: "सन्निकटिकरण",
  },

  placeholder: {
    "Select an item": "एक आइटम चुनें",
  },

  dropdown: {
    "No, I am not aware that Mishcon de Reya LLP has acted or is currently acting for or against me in a legal matter":
      "नहीं, मुझे नहीं पता कि Mishcon de Reya LLP ने मेरे खिलाफ या मेरे पक्ष में कानूनी मामले में कार्य किया है या वर्तमान में कर रहा है।.",
    "Yes, Mishcon de Reya LLP has acted and/or is currently acting against or for me in legal matter(s)":
      "हां, Mishcon de Reya LLP ने मेरे खिलाफ और/या मेरे पक्ष में कानूनी मामलों में कार्य किया है और/या वर्तमान में कर रहा है।.",
    "I am not sure": "मुझे निश्चित नहीं है।.",
    Title: "टाइटल",
    Mr: "श्री",
    Mrs: "श्रीमती",
    Ms: "सुश्री",
    Miss: "मिस",
    Mx: "एमएक्स",
    Dr: "डा.",
    Professor: "प्रोफेसर",
    "Prefer not to say": "बताना नहीं चाहते",
    Other: "अन्य",
    Shareholder: "शेयरहोल्डर",
    Director: "डायरेक्टर",
    Employee: "कर्मचारी ",
  },

  currency: {
    enterValue: "कृपया मूल्य दर्ज करें।",
  },

  yotiManualIdUpload: {
    selectDocumentType: "कृपया उस दस्तावेज़ के प्रकार का चयन करें जिसे आप अपलोड करना चाहते हैं",
    thisFieldIsRequired: "इस क्षेत्र की आवश्यकता है",
    uploadIdentityDocument: "कृपया अपने पहचान पत्र की एक कॉपी अपलोड करें",
    uploadMustBeClear: "आपको यकीनी बनाना चाहिए कि दस्तावेज स्पष्ट और पढ़ा जा सकता है जो फ़ाइल अपलोड किया गया है।",
    errorUploading: "आपके फ़ाइलों को अपलोड करते समय एक त्रुटि आई थी, कृपया पुन: प्रयास करें।",
  },

  yoti: {
    clickManualUpload:
      "यहां क्लिक करें यदि आपको Yoti पहचान सेवा का उपयोग करने में समस्या हो रही है तो अपने पहचान दस्तावेज़ हस्तांतरित करने के लिए",
    backToYoti: "Yoti के साथ पहचान करने के लिए वापस जाएं",
    verificationInProgress: "पहचान प्रक्रिया जारी है",
    inProgressInfo:
      "आपकी पहचान प्रक्रिया वर्तमान में चल रही है। यह प्रक्रिया कुछ मिनट ले सकती है। हम आपकी सहानुभूति के लिए आभारी हैं। यदि आपको कोई समस्या आती है, तो कृपया हमसे संपर्क करने से हिचकिचाएं नहीं।",
    faqs: {
      troubleCamera: "क्या आपको अपने कैमरा के साथ समस्या हो रही है?",
      iphoneUsersTitle: "आईफोन उपयोगकर्ता",
      iphoneSafariInstructions: "सफारी के लिए: सेटिंग्स > सफारी > कैमरा पर जाएं और 'अनुमति दें' का चयन करें।",
      iphoneOtherBrowsersInstructions:
        "अन्य ब्राउज़र के लिए: सेटिंग्स > [आपका ब्राउज़र] > कैमरा पर जाएं और 'अनुमति दें' का चयन करें।",

      androidUsersTitle: "एंड्रॉयड उपयोगकर्ता",
      androidPopupInstructions:
        "जब आप कैमरा का उपयोग करने की कोशिश करते हैं, तो एक पॉपअप अनुमतियाँ मांगने के लिए प्रकट होना चाहिए।",
      androidManualInstructions:
        "यदि ऐसा नहीं होता है, तो सेटिंग्स > ऐप्स > [आपका ब्राउज़र] > अनुमतियाँ पर जाएं और कैमरा सक्षम करें।",

      desktopUsersTitle: "डेस्कटॉप / लैपटॉप उपयोगकर्ता",
      chromeInstructions: "गूगल क्रोम: कैमरा का आइकन पता बार में प्रकट होगा। इस पर क्लिक करें और 'अनुमति दें' चुनें।",
      firefoxInstructions: "मोजिला फ़ायरफ़ॉक्स: पता बार के पास एक पॉपअप प्रकट होगा। 'अनुमति दें' पर क्लिक करें।",
      edgeInstructions: "माइक्रोसॉफ्ट एज: ब्राउज़र के नीचे एक पॉपअप प्रकट होगा। 'अनुमति दें' पर क्लिक करें।",
      safariInstructions: "सफारी: आपकी अनुमति मांगने वाला एक संकेत प्रकट होगा। 'अनुमति दें' पर क्लिक करें।",
      introTextDocs: "Yoti निम्नलिखित दस्तावेज़ स्वीकार करता है:",
      whatDocuments: "कौन से पहचान पत्र स्वीकार किए जाते हैं?",
      passportItem: "वर्तमान पासपोर्ट (200+ देशों से स्वीकार्य - देशों की सूची की समीक्षा करने के लिए यहां क्लिक करें)",
      drivingLicenseItem:
        "फ़ोटो ड्राइविंग लाइसेंस (92 देशों से स्वीकार्य - देशों की सूची की समीक्षा करने के लिए यहां क्लिक करें)",
      nationalIdItem:
        "राष्ट्रीय पहचान पत्र (66 देशों से स्वीकार्य - देशों की सूची की समीक्षा करने के लिए यहां क्लिक करें)",
      residencePermitItem: "फ़ोटो के साथ ब्रिटिश निवास अनुमति",
      additionalInfo:
        "इन दस्तावेज़ों के कुछ संस्करणों को स्वीकार नहीं किया जा सकता है क्योंकि वे हमारी सुरक्षा आवश्यकताओं को पूरा नहीं करते हैं",
      howToTakePhoto: "मैं अपने आईडी की एक अच्छी फ़ोटो कैसे लूं?",
      title: "क्या आपको पहचान जांच के साथ मदद की आवश्यकता है?",
      introTextTakePhoto:
        "कृपया सुनिश्चित करें कि आपके दस्तावेज़ों पर फ़ोटो और व्यक्तिगत विवरण सामने की ओर हैं। फ़ोटो स्वचालित रूप से ली जाएगी। इसके देश के जारी करने पर आपको अपने आईडी के सामने और पीछे की फ़ोटो लेने की आवश्यकता हो सकती है। अपने आईडी की फ़ोटो लेने के लिए, सुनिश्चित करें कि:",
      visibleCorners: "आपके आईडी के सभी चार कोने फ़ोटो में दिखाई दे रहे हैं",
      noFingerCovering: "आपकी उंगलियाँ आपकी फ़ोटो या उस पर लिखी गई जानकारी को ढक नहीं रही हैं",
      documentClarity: "दस्तावेज स्पष्ट और फोकस में है",
      adequateLighting: "प्रकाशन बहुत अंधेरा नहीं है",
      clearImage:
        "सुनिश्चित करें कि आप बिना किसी चमक के एक स्पष्ट छवि कैप्चर करते हैं - सर्वश्रेष्ठ परिणामों के लिए, अपने स्मार्टफ़ोन का उपयोग करें।",
      avoidGlare: "आप किसी भी चमक या प्रतिबिंब से बचें",
      fullDocumentImage: "पूरा दस्तावेज़ छवि में होना चाहिए।",
      contact: "यदि आप पहचान सत्यापन को पूरा करने में समस्या का सामना कर रहे हैं, तो कृपया हमारी टीम से संपर्क करें",
      clickHere: "यहां क्लिक करें",
      struggleToConfirm: "अपनी पहचान की पुष्टि करने में संघर्ष कर रहे हैं?",
    },
  },
  multiFile: {
    addFile: "फ़ाइल जोड़ें",
  },
};
