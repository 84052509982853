<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
// Components
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";
// Helpers
import { gatewayHelper } from "../helpers/gatewayHelper";
import { viewSubmit } from "../helpers/viewSubmit";
import { baseProps } from "../helpers/baseProps";
import { formatAddress } from "../helpers/formatAddress";
import { handleUnknownError } from "../helpers/handleUnknownError";
import { getUserDetailsFromCheck } from "../helpers/getUserDetailsFromCheck";
// Types
import type { CheckboardAddress, AddressConfirmation } from "../helpers/CheckboardCreditCheck";
import { themesComputed } from "@/state/helpers";
export default (Vue as VueConstructor<Vue & AddressConfirmation.Refs>).extend({
  name: "AddressConfirmation",
  components: { BlockDropdown, BlockButton, CheckboardHeaderContent },
  props: {
    ...baseProps(),
    addresses: {
      type: Array as PropType<CheckboardAddress[]>,
      required: true,
    },
    currentAddress: {
      type: Object as PropType<CheckboardAddress>,
      required: false,
    },
  },
  data() {
    return {
      isSubmitting: false,
      selectedAddress: null as CheckboardAddress | null,
    };
  },
  computed: {
    ...themesComputed,
    dropdownOptions(): string[] {
      return this.addresses.map((address) => this.formatAddress(address));
    },
    user(): ReturnType<typeof getUserDetailsFromCheck> {
      return getUserDetailsFromCheck(this.check);
    },
  },
  methods: {
    formatAddress,
    handleAddressSelect(address: string) {
      this.selectedAddress = this.addresses.find((addressEntry) => this.formatAddress(addressEntry) === address);
    },
    /**
     * @description User answers "YES" to the question "Have you been living here for the past 3 years?"
     * we call the checkboardRunCheck gateway function with the selected address
     */
    async handleCallRunCheck() {
      try {
        this.isSubmitting = true;

        const data = {
          claimId: this.claimId,
          userId: this.userId,
          checkId: this.checkId,
          addressHistory: [this.selectedAddress],
        };

        const result = await gatewayHelper("checkboardRunCheck", data);

        viewSubmit(this, "addressConfirmation", result);
      } catch (error) {
        console.error("YES route error", error);
        handleUnknownError(this, this.check);
      } finally {
        this.isSubmitting = false;
      }
    },
    /**
     * @description User answers "NO" to the question "Have you been living here for the past 3 years?"
     * we navigate them to the address history view
     */
    handleGoToHistoryView() {
      viewSubmit(
        this,
        "addressConfirmationToHistory",
        { check: this.check, clientView: "addressHistory" },
        this.selectedAddress
      );
    },
    goToAddressInput() {
      viewSubmit(this, "addressConfirmationToAddressInput", { check: this.check, clientView: "addressInput" });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.currentAddress) {
      this.$refs.dropdown.handleInput(this.formatAddress(this.currentAddress));
    }
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
.gap-8 {
  gap: 8px;
}
</style>

<template>
  <div class="d-flex flex-column gap-20">
    <CheckboardHeaderContent
      :isBuilder="isBuilder"
      :progressValue="'55'"
      :hideProgressBar="hideProgressBar"
      :header="'Address Confirmation'"
      :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, please select your address from the list below.`"
    />

    <div class="d-flex flex-column gap-8">
      <BlockDropdown
        v-bind="{
          label: 'Select your address',
          placeholder: 'Select an address',
          options: dropdownOptions,
          ref: 'dropdown',
        }"
        @update:answer="handleAddressSelect"
      />
      <div>
        <h5 role="button" @click="goToAddressInput">
          <span class="text-sm lh-base font-weight-semibold text-primary-700">
            If your address is not listed, click here to search by postcode
          </span>
        </h5>
      </div>
    </div>
    <!-- Deprecating address history view for now -->
    <!--<div v-if="selectedAddress" class="mx-auto text-center">
      <p class="mt-4 mb-2 text-lg">Have to been living here for the past 3 years?</p>
      <ClaimsGateButton
        variant="primary"
        text="Yes"
        @click="handleCallRunCheck"
        :loading="isSubmitting"
        loadingText=""
      />
      <ClaimsGateButton variant="secondary-grey" class="ml-2" text="No" @click="handleGoToHistoryView" />
    </div>-->
    <BlockButton
      :text="'Continue'"
      :variant="'primary'"
      :isProcessing="isSubmitting"
      @clicked="handleCallRunCheck"
      :block="false"
      :submitButtonDefault="false"
    />
  </div>
</template>
