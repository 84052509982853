import { BlockRevXInstance } from "./BlockRevX";
import { ClaimsGateRevXGetVehicleResponse, RevXGetVehicleResponse } from "@claimsgate/core-types";
import {
  ClaimsGateErrors,
  ClaimsGateFunctionReturnedError,
  Milberg,
  Quantum,
  Provenio,
  getClaims,
  getClaimsByFunnelId,
} from "@claimsgate/core";
import { DateTime } from "luxon";
import { RevXPartialVehicleStore } from "./types";

import omitBy from "lodash.omitby";
import isNil from "lodash.isnil";
import { reject } from "@/helpers/vue";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { fireRevXApiErrorEvent } from "./sentry";
import { JONES_WHYTE_FUNNELS } from "@/components/claimant/form/next";
import { getFirebaseBackend } from "@/authUtils";

/** Methods to be consumed by Vue instance */
export const methods = {
  searchVehicle,
  submitVehicleConfirmation,
  submitKeeperSelect,
  handleVehicleSelected,

  displayHelpModal,
  setValidationInvalidFeedback,
  notMyVehicle,
};

export function displayHelpModal(state: BlockRevXInstance) {
  state.$bvModal.show("helpModal");
}

/** When the user selects not my vehicle */
export function notMyVehicle(state: BlockRevXInstance) {
  resetToSearchVehicleView(state);
}

/** Sets invalid feedback which has been fed from a page level validation onto the block */
export function setValidationInvalidFeedback(state: BlockRevXInstance, invalidFeedback: string, stayOnPage = false) {
  if (!stayOnPage) {
    resetToSearchVehicleView(state);
    state.vrmInputState = false;
    state.vrmInputInvalidFeedback = invalidFeedback;
  } else {
    state.BlockInputs.keeperSelectSingleSelect.state = false;
    state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = invalidFeedback;
    state.BlockInputs.searchRegButton.isProcessing = false;
    state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
  }
}

/** Handles the logic when the user submits the view for confirming their keeper dates dates */
export async function submitKeeperSelect(state: BlockRevXInstance) {
  try {
    state.BlockInputs.submitKeeperSelectButton.isProcessing = true;
    state.BlockInputs.keeperSelectSingleSelect.state = null;

    // Validate keeper selected
    if (!validateKeeperSelected(state)) return;

    // Parse the keeper start and end dates from the keeperSelectSingleSelect
    const selectedKeeperStartDate = state.BlockInputs.keeperSelectSingleSelect.answer;
    const selectedKeeper = state.vehicle.keeperChangeHistory.find(
      (keeperChange) => DateTime.fromJSDate(new Date(keeperChange.startDate)).toISO() === selectedKeeperStartDate
    );

    state.vehicle.keeperStartDate = selectedKeeper.startDate;
    // The number of previous keepers is the index of the selected keeper + 1
    state.vehicle.numberOfPreviousKeepers = state.vehicle.keeperChangeHistory.indexOf(selectedKeeper) + 1;

    if (selectedKeeper.endDate) {
      state.vehicle.keeperEndDate = selectedKeeper.endDate;
    } else {
      state.vehicle.isCurrentKeeper = true;
    }

    await saveVehicle(state);
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

/** Validates if a keeper has been selected */
function validateKeeperSelected(state: BlockRevXInstance): boolean {
  if (state.BlockInputs.keeperSelectSingleSelect.answer?.length === 0) {
    state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = state.uiMessages.noKeeperSelected;

    reject(state, state.uiMessages.noKeeperSelected);

    state.BlockInputs.keeperSelectSingleSelect.state = false;
    state.BlockInputs.submitKeeperSelectButton.isProcessing = false;

    return false;
  }
  return true;
}

/** Handles the logic when the user submits the view for confirming if the retured vehicle is correct */
export async function submitVehicleConfirmation(state: BlockRevXInstance) {
  try {
    if (state.BlockInputs.vehicleConfirmationSingleSelect.answer === "No") {
      resetToSearchVehicleView(state);

      return;
    }

    state.BlockInputs.confirmVehicleText.html = `<div><div><h3><b>Vehicle Found</b></h3><h4>We found your vehicle, <b>${state.vehicle.make?.toUpperCase()}</b>, <b>${state.vehicle.model?.toUpperCase()}</b>, <b>${
      state.vehicle.yearOfManufacture
    }</b>, <b>${state.vehicle.fuelType}</b>.</h4></div></div>`;

    state.uiToggles.isVehicleSearchVisible = false;

    setupKeeperChangeSingleSelect(state);
    await state.$nextTick();
    state.uiToggles.showVehicleConfirmation = false;
    await state.$nextTick();
    state.uiToggles.isKeeperSelectVisible = true;
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

function setupKeeperChangeSingleSelect(state: BlockRevXInstance) {
  state.BlockInputs.keeperSelectSingleSelect.options = state.vehicle.keeperChangeHistory.map((keeperChange) => {
    return DateTime.fromJSDate(new Date(keeperChange.startDate)).toISO();
  });
}

export function resetToSearchVehicleView(state: BlockRevXInstance) {
  // Clear fetched vehicle
  state.vehicle = null;

  // Clear previous answers
  state.vrm = "";
  state.ownershipDate = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.answer = "";
  state.BlockInputs.keeperSelectSingleSelect.answer = "";
  state.BlockInputs.vehicleSelectSingleSelect.answer = "";

  // Clear previous block states
  state.vrmInputState = null;
  state.BlockInputs.ownershipDatePicker.state = null;
  state.BlockInputs.vehicleConfirmationSingleSelect.state = null;
  state.BlockInputs.keeperSelectSingleSelect.state = null;
  state.BlockInputs.vehicleSelectSingleSelect.state = null;

  // Clear previous block invalid feedbacks
  state.vrmInputInvalidFeedback = "";
  state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = "";
  state.BlockInputs.ownershipDatePicker.invalidFeedback = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.invalidFeedback = "";
  state.BlockInputs.vehicleSelectSingleSelect.invalidFeedback = "";

  // Display the vehicle select
  state.uiToggles.isKeeperSelectVisible = false;
  state.uiToggles.showVehicleConfirmation = false;
  state.uiToggles.showOwnershipDateQuestion = false;
  state.uiToggles.isVehicleSelectVisible = false;
  state.$nextTick(() => {
    state.uiToggles.isVehicleSearchVisible = true;
  });

  // Reset the HTML text
  state.BlockInputs.confirmVehicleText.html = "";

  // Reset button loaders
  state.BlockInputs.submitVehicleConfirmationButton.isProcessing = false;
  state.BlockInputs.searchRegButton.isProcessing = false;
  state.BlockInputs.submitKeeperSelectButton.isProcessing = false;

  state.revXResponse = null;
}

/** Returns the correct set of funnel variables */
async function _getFunnelVariables(state: BlockRevXInstance) {
  let { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);
  if (JONES_WHYTE_FUNNELS.includes(state.funnelId)) {
    // If we are on a Jones Whyte funnel and the vehicle is a Mercedes
    if (state.vehicle.make.toLowerCase().includes("mercedes")) {
      const { data: _funnelVariables } = await state.funnelsService.getFunnelVariables(
        Quantum.Funnels.JONES_WHYTE_MERCEDES_EMISSIONS_ID
      );

      funnelVariables = _funnelVariables;

      // If we are on a Jones Whyte funnel and the vehicle is not a Mercedes
    } else {
      const { data: _funnelVariables } = await state.funnelsService.getFunnelVariables(
        Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID
      );

      funnelVariables = _funnelVariables;
    }
  }

  return funnelVariables;
}

/** Saves the vehicle stored in the state to the claim data service */
export async function saveVehicle(state: BlockRevXInstance): Promise<boolean> {
  const funnelVariables = await _getFunnelVariables(state);

  const hashedVehicle = await state.variablesService.hashData(state.vehicle, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }

  const MILBERG_DPF_FUNNEL_ID = "xIIzib4WMmdDg6Sa8R8B";
  const THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID = Quantum.Funnels.THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID;

  if (state.funnelId === MILBERG_DPF_FUNNEL_ID) {
    if (await userHasClaimsForVin(state)) {
      reject(state, "Unfortunately, you have already registered a claim for this vehicle.");
      setValidationInvalidFeedback(state, "Unfortunately, you have already registered a claim for this vehicle.");

      return false;
    }

    if (!state.vehicle.dpfAffected) {
      reject(state, "Unfortunately, your vehicle is not affected by the Diesel Particulate Filter (DPF) issue.");
      setValidationInvalidFeedback(
        state,
        "Unfortunately, your vehicle is not affected by the Diesel Particulate Filter (DPF) issue."
      );

      return false;
    }

    if (state.vehicle.dpfConfidenceScore < 100) {
      reject(state, "Unfortunately, your vehicle is not affected by the Diesel Particulate Filter (DPF) issue.");
      setValidationInvalidFeedback(
        state,
        "Unfortunately, your vehicle is not affected by the Diesel Particulate Filter (DPF) issue."
      );

      return false;
    }
    const keeperStartDate = DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate));
    const purchaseFrom = DateTime.fromISO("2014-10-01");
    const purchaseTo = DateTime.fromISO("2023-06-30");
    if (keeperStartDate.toMillis() < purchaseFrom.toMillis() || keeperStartDate.toMillis() > purchaseTo.toMillis()) {
      reject(
        state,
        "Unfortunately, to register a claim, you must have purchased your vehicle between October 2014 and June 2023"
      );
      setValidationInvalidFeedback(
        state,
        "Unfortunately, to register a claim, you must have purchased your vehicle between October 2014 and June 2023",
        true
      );
      return false;
    }

    // If the vehicle was purchased between 1st January 2018 and 30th June 2023
    // Tag the claim as category 1
    const category1StartDate = DateTime.fromISO("2018-01-01");
    const category1EndDate = DateTime.fromISO("2023-06-30");
    if (
      keeperStartDate.toMillis() >= category1StartDate.toMillis() &&
      keeperStartDate.toMillis() <= category1EndDate.toMillis()
    ) {
      state.claimDataService.setArtefact("vehicleTier", "Tier 1");
    }

    // If the vehicle was purchased between 1st October 2014 and 31st December 2017
    // Tag the claim as category 2
    const category2StartDate = DateTime.fromISO("2014-10-01");
    const category2EndDate = DateTime.fromISO("2017-12-31");
    if (
      keeperStartDate.toMillis() >= category2StartDate.toMillis() &&
      keeperStartDate.toMillis() <= category2EndDate.toMillis()
    ) {
      state.claimDataService.setArtefact("vehicleTier", "Tier 2");
    }
  }

  if (state.funnelId === THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID) {
    if (await userHasClaimsForVin(state)) {
      reject(state, "Unfortunately, you have already registered a claim for this vehicle.");
      setValidationInvalidFeedback(state, "Unfortunately, you have already registered a claim for this vehicle.");

      return false;
    }
  }

  if (
    state.funnelId === Milberg.Funnels.VAUXHALL_DIESEL_EMISSIONS_FUNNEL_ID ||
    state.funnelId === "VabLrfVIHH3uYOVIpHyd"
  ) {
    // If the vehicle's make is not a mercedes or vauxhall (lowercase)
    if (!["mercedes", "vauxhall"].includes(state.vehicle.make.toLowerCase())) {
      reject(state, state.uiMessages.milbergNotVauxhallOrMercedes);
      setValidationInvalidFeedback(state, state.uiMessages.milbergNotVauxhallOrMercedes);
      return false;
    }

    // If the vehicle's fuel type does not include Diesel
    if (!state.vehicle.fuelType.toLowerCase().includes("diesel")) {
      reject(state, state.uiMessages.milbergNotDiesel);
      setValidationInvalidFeedback(state, state.uiMessages.milbergNotDiesel);
      return false;
    }

    // ! If the vehicle is a Vauxhall
    if (state.vehicle.make.toLowerCase().includes("vauxhall")) {
      // If the start date of keeper is defined and is in the year 2017
      if (
        state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year === 2017 &&
        // Ensure that the month is greater than July
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).month > 7
      ) {
        if (parseFloat(state.vehicle.yearOfManufacture) < 2013) {
          reject(state, state.uiMessages.milberg4YearsAfterManufacture);
          setValidationInvalidFeedback(state, state.uiMessages.milberg4YearsAfterManufacture);
          return false;
        }
      } else if (
        state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year === 2018
      ) {
        if (parseFloat(state.vehicle.yearOfManufacture) < 2014) {
          reject(state, state.uiMessages.milberg4YearsAfterManufacture);
          setValidationInvalidFeedback(state, state.uiMessages.milberg4YearsAfterManufacture);
          return false;
        }
      } else if (
        state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year === 2019
      ) {
        if (parseFloat(state.vehicle.yearOfManufacture) < 2015) {
          reject(state, state.uiMessages.milberg4YearsAfterManufacture);
          setValidationInvalidFeedback(state, state.uiMessages.milberg4YearsAfterManufacture);
          return false;
        }
      } else {
        reject(state, state.uiMessages.milbergVehicleNotOwnedIn2017);
        setValidationInvalidFeedback(state, state.uiMessages.milbergVehicleNotOwnedIn2017, true);
        return false;
      }
    }

    // If the vehicle is a Mercedes
    if (state.vehicle.make.toLowerCase().includes("mercedes")) {
      // If the start date of keeper is defined and is not in the year 2017, 2018 or 2019.
      if (
        state.vehicle.keeperStartDate &&
        ![2017, 2018, 2019].includes(DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year)
      ) {
        reject(state, state.uiMessages.milbergVehicleNotOwnedIn2017);
        setValidationInvalidFeedback(state, state.uiMessages.milbergVehicleNotOwnedIn2017, true);
        return false;
      }

      // If the vehicle was purchaed in 2017 and before July
      if (
        state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year === 2017 &&
        // Ensure that the month is greater than July
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).month < 7
      ) {
        reject(state, state.uiMessages.milbergVehicleNotOwnedIn2017);
        setValidationInvalidFeedback(state, state.uiMessages.milbergVehicleNotOwnedIn2017, true);
        return false;
      }
    }
  }

  state.$store.dispatch("events/fire", { name: state.eventValues.next });

  return true;
}

async function handleVehicleSelected(state: BlockRevXInstance) {
  state.BlockInputs.submitVehicleSelectButton.isProcessing = true;

  if (!state.BlockInputs.vehicleSelectSingleSelect.answer) {
    state.BlockInputs.vehicleSelectSingleSelect.invalidFeedback = "Please select a vehicle.";
    reject(state, "Please select a vehicle.");
    state.BlockInputs.submitVehicleSelectButton.isProcessing = false;

    return;
  }

  // The user has selected a vehicle so let us proceed with the storage
  await setupVehicleState(state.revXResponse, state);
  state.BlockInputs.submitVehicleSelectButton.isProcessing = false;
}

export async function searchVehicle(state: BlockRevXInstance, forceHistoricalVinLookup: boolean = false) {
  try {
    resetSearchVehicleUiToggles(state);

    // Breaks if we were unable to validate the entered VRM
    if (!validateVrm(state)) {
      state.uiToggles.isSearchingByQueryParameters = false;
      return;
    }

    // Remove any spaces from the VRM
    const vrm = state.vrm.replace(/\s/g, "");

    const { data: RevXResponse } = await getVehicleFromRevX(state, vrm);
    if (!(await parseRevXResponse(RevXResponse, state))) {
      state.uiToggles.isSearchingByQueryParameters = false;
      return;
    }

    state.BlockInputs.searchRegButton.isProcessing = false;
    state.vrmInputState = null;
    state.uiToggles.isSearchingByQueryParameters = false;
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

/** Fetches the vehicle and updates the interface in the event of an error */
async function parseRevXResponse(
  revXResponse: ClaimsGateRevXGetVehicleResponse | ClaimsGateFunctionReturnedError,
  state: BlockRevXInstance
): Promise<boolean> {
  console.log("isClaimsGateFunctionReturnedError", isClaimsGateFunctionReturnedError(revXResponse));
  if (isClaimsGateFunctionReturnedError(revXResponse)) {
    // If the API returned a RevXVehicleNotFound error then the vehicle either
    // a) could not be found
    // b) is affected by reverse cherished transfer and we require a date when the user owned the vehicle to find it
    const isRevXVehicleNotFoundError =
      revXResponse.error.id === new ClaimsGateErrors.RevXVehicleNotFound().id ||
      revXResponse.error.id === "RevXVehicleNotFound";

    if (isRevXVehicleNotFoundError) {
      resetToSearchVehicleView(state);
      state.vrmInputState = false;
      state.vrmInputInvalidFeedback = state.uiMessages.noVehicleFound;
      reject(state, state.uiMessages.noVehicleFound);
      state.BlockInputs.searchRegButton.isProcessing = false;
    } else {
      state.vrmInputState = false;
      state.vrmInputInvalidFeedback = state.uiMessages.errorFindingVehicle;
      reject(state, state.uiMessages.errorFindingVehicle);
      state.BlockInputs.searchRegButton.isProcessing = false;
    }

    return false;
  }

  // If the returned vehicle is affected by reverse cherished transfer
  if (revXResponse.vehicles.length > 1) {
    state.BlockInputs.vehicleSelectSingleSelect.options = revXResponse.vehicles.map(
      (vehicle) =>
        `${vehicle.manufacturer.toUpperCase()} ${vehicle.model.toUpperCase()} - ${vehicle.manufacturedYear}, ${
          vehicle.colour
        }, ${vehicle.fuelType?.toUpperCase()}`
    );

    state.$set(state, "revXResponse", revXResponse);

    state.uiToggles.isVehicleSelectVisible = true;
    await state.$nextTick();

    state.uiToggles.isVehicleSearchVisible = false;
    state.BlockInputs.searchRegButton.isProcessing = false;

    return true;
  }

  await setupVehicleState(revXResponse, state);

  // If no error was returned, then we can save the vehicle data to our database

  return true;
}

async function setupVehicleState(revXResponse: RevXGetVehicleResponse, state: BlockRevXInstance) {
  let index = 0;

  if (state.BlockInputs.vehicleSelectSingleSelect.answer) {
    index = revXResponse.vehicles.findIndex(
      (vehicle) =>
        state.BlockInputs.vehicleSelectSingleSelect.answer ===
        `${vehicle.manufacturer.toUpperCase()} ${vehicle.model.toUpperCase()} - ${vehicle.manufacturedYear}, ${
          vehicle.colour
        }, ${vehicle.fuelType?.toUpperCase()}`
    );
  }

  const vehicle: RevXPartialVehicleStore = {
    make: revXResponse.vehicles[index].manufacturer,
    model: revXResponse.vehicles[index].model,
    colour: revXResponse.vehicles[index].colour,
    vin: revXResponse.vehicles[index].vin,
    vehicleRegistration: revXResponse.vehicles[index].vrm,
    fuelType: revXResponse.vehicles[index].fuelType?.toUpperCase(),
    engineCapacity: revXResponse.vehicles[index].engineSizeCc,
    dateFirstRegistered: revXResponse.vehicles[index].dateFirstRegistered,
    bhp: revXResponse.vehicles[index].bhp,
    euroStatus: revXResponse.vehicles[index].euroStatus,
    numberOfDoors: revXResponse.vehicles[index].numberOfDoors,
    series: revXResponse.vehicles[index].series,
    bodyDescription: revXResponse.vehicles[index].bodyDescription,
    firstKnownVrm: revXResponse.vehicles[index].firstKnownVrm,
    mileageEstTotal: revXResponse.vehicles[index].mileage.estTotalMileage,
    mileageYearlyTotal: revXResponse.vehicles[index].mileage.estYearlyMileage,
    rrpCurrencyCode: revXResponse.vehicles[index].rrp.currencyCode,
    rrpValue: revXResponse.vehicles[index].rrp.unitValue,
    maximumPowerInKw: revXResponse.vehicles[index].bhp,
    keeperChangeHistory: revXResponse.vehicles[index].history,
    vinLast5: revXResponse.vehicles[index].vin.substring(revXResponse.vehicles[index].vin.length - 5),
    yearOfManufacture: DateTime.fromISO(revXResponse.vehicles[index].dateFirstRegistered).get("year").toString(),
    engineSize: revXResponse.vehicles[index].engineSize,
    vehicleDataSource: "RevX",
    dpfAffected: revXResponse.vehicles[index].claims.dpf.isAffected,
    dpfConfidenceScore: revXResponse.vehicles[index].claims.dpf.confidenceScore,
    dpfConfidenceScoreReason: revXResponse.vehicles[index].claims.dpf.confidenceScoreReasons,
    dpfFailReason: revXResponse.vehicles[index].claims.dpf.failureReasons,
    dpfPlatform: revXResponse.vehicles[index].claims.dpf.platform,
  };

  const compactedVehicle: RevXPartialVehicleStore = omitBy(vehicle, isNil);

  // Using a vue setter to prevenet reactivity issues
  state.$set(state, "vehicle", compactedVehicle);

  // Show the vehicle confirmation step
  state.uiToggles.showVehicleConfirmation = true;
  await state.$nextTick();

  if (revXResponse.vehicles.length > 0) {
    state.uiToggles.isVehicleSelectVisible = false;
  }

  state.uiToggles.isVehicleSearchVisible = false;

  state.BlockInputs.searchRegButton.isProcessing = false;
}

/** Returns a boolean indcating if the entered vrm is valid */
function validateVrm(state: BlockRevXInstance): boolean {
  if (state.vrm.length === 0) {
    state.vrmInputState = false;
    state.vrmInputInvalidFeedback = state.uiMessages.enterReg;
    reject(state, state.uiMessages.enterReg);
    state.BlockInputs.searchRegButton.isProcessing = false;
    return false;
  }

  return true;
}

/** Resets the states of inputs and processing state when on the search vehicle view */
function resetSearchVehicleUiToggles(state: BlockRevXInstance) {
  state.BlockInputs.searchRegButton.isProcessing = true;
  state.BlockInputs.ownershipDatePicker.state = null;

  state.vrmInputState = null;

  state.BlockInputs.vehicleSelectSingleSelect.answer = "";
}

/** Type guard to check if the response returned from getVehicleFromRevX is a ClaimsGateFunctionReturnedError */
function isClaimsGateFunctionReturnedError(
  response: ClaimsGateRevXGetVehicleResponse | ClaimsGateFunctionReturnedError
): response is ClaimsGateFunctionReturnedError {
  console.log("isClaimsGateReturnedError", response, response as ClaimsGateFunctionReturnedError);
  return (response as ClaimsGateFunctionReturnedError)?.error?.id !== undefined;
}

/** Fetches a given vehicle from the RevX backend */
async function getVehicleFromRevX<F = "getVehicleFromRevX">(
  state: BlockRevXInstance,
  vid: string
): Promise<{ data?: ClaimsGateRevXGetVehicleResponse | ClaimsGateFunctionReturnedError }> {
  return onCallGateway<"getVehicleFromRevX">({
    functionName: "getVehicleFromRevX",
    data: {
      vid,
      claimId: state.claimId,
      funnelId: state.funnelId,
      pageId: state.pageId,
    },
  });
}

// Create a generic try catch handler which will log the error and reset the view to the search vehicle view
function tryCatchHandler(state: BlockRevXInstance, error: Error) {
  console.error(error);
  resetToSearchVehicleView(state);

  state.vrmInputState = false;
  state.vrmInputInvalidFeedback = state.uiMessages.errorFindingVehicle;
  reject(state, state.uiMessages.errorFindingVehicle);

  fireRevXApiErrorEvent(state);
}

async function userHasClaimsForVin(state: BlockRevXInstance): Promise<boolean> {
  const claimStatus = state.claimDataService.getArtefact("claimStatus");
  // comment out this if you want testing to be included
  if (claimStatus === "testing") {
    return false;
  }
  //Check that vin is not existing in this users claims
  const [claimsForUser, error] = await getClaimsByFunnelId(
    getFirebaseBackend().firestore(),
    state.userId,
    state.funnelId
  );

  const funnelVariables = await _getFunnelVariables(state);

  const vinVariable = funnelVariables.find((variable) => variable.field === "vin");

  const existingClaim = claimsForUser.find((claim) => {
    if (claim.currentFunnelId !== state.funnelId) {
      return false;
    }
    // comment out this if you want testing to be included
    if (claim.claimStatus === "testing" || claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
      return false;
    }
    if (claim.claimId === state.claimId) {
      return false;
    }

    return claim?.[vinVariable.id] === state.vehicle.vin;
  });

  if (!existingClaim) {
    return false;
  }

  await state.infoModalService.fire("warning", {
    title: "You have already registered a claim for this vehicle",
    text: "It looks like you already have an active claim for this vehicle. Click resume to go to your existing claim.",
    cancelButtonText: "Cancel",
    confirmButtonText: "Resume",
    onConfirm: () => {
      state.$router.push({ name: "Track", params: { claimId: existingClaim.documentId } }).then();
    },
    onHide: () => {
      console.log("Closed");
    },
  });

  return true;
}
