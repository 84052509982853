export default {
  email: {
    label: "Alamat email",
    invalidFeedback: "Bidang ini wajib diisi",
  },

  phone: {
    label: "Nomor Telepon",
  },

  contactDetails: {
    mdrPrivacyPolicyText:
      "Menekan Lanjutkan berarti bahwa saya bersedia dihubungi oleh Mishcon de Reya LLP terkait gugatan saya. ",
    "Do you agree to the": "Apakah Anda setuju dengan",
    "Privacy Policy": "Kebijakan Privasi",
  },

  invalidFeedback: {
    "Please enter a valid email address": "Silakan masukkan alamat email yang valid",
    "Please enter a valid phone number": "Silakan masukkan nomor telepon yang valid",
    "First name must be at least 2 characters": "Ama depan harus setidaknya 2 karakter",
    "Last name must be at least 2 characters": "Nama belakang harus setidaknya 2 karakter",
    "Please select a title": "Silakan pilih sebuah gelar",
    "You must be at least 18 years old to use continue": "Anda harus berusia minimal 18 tahun untuk melanjutkan",
  },

  button: {
    continue: "Lanjutkan",
    previous: "Sebelumnya",
  },

  singleSelect: {
    Yes: "Ya",
    No: "Tidak",
    "Exact figure": "Angka pasti",
    Approximation: "Perkiraan",
  },

  placeholder: {
    "Select an item": "Pilih item",
  },

  dropdown: {
    "No, I am not aware that Mishcon de Reya LLP has acted or is currently acting for or against me in a legal matter":
      "Tidak, saya tidak sedar bahawa Mishcon de Reya LLP telah bertindak atau sedang bertindak untuk atau menentang saya dalam perkara undang-undang.",
    "Yes, Mishcon de Reya LLP has acted and/or is currently acting against or for me in legal matter(s)":
      "Ya, Mishcon de Reya LLP telah bertindak dan/atau sedang bertindak menentang atau untuk saya dalam perkara undang-undang.",
    "I am not sure": "Saya tidak pasti.",
    Title: "Gelar",
    Mr: "Tn.",
    Mrs: "Ny.",
    Ms: "Ibu",
    Miss: "Sdr.",
    Mx: "Sdr.",
    Dr: "Dr.",
    Professor: "Profesor",
    "Prefer not to say": "Tidak bersedia menjawab",
    Other: "Lainnya",
    Shareholder: "Pemegang Saham",
    Director: "Direktur",
    Employee: "Karyawan",
  },

  address: {
    addressLine1: "Baris Alamat 1",
    city: "Kota",

    title: "Gelar",
    firstName: "Nama Depan",
    middleName: "Nama Tengah",
    lastName: "Nama Belakang",
    dateOfBirth: "Tanggal Lahir",
    postcode: "Kode Pos",
    livesInUk: "Apakah Anda tinggal di Inggris?",
    enterAddress: "Masukkan Alamat Anda",
  },

  currency: {
    enterValue: "Silakan masukkan nilai.",
  },

  yotiManualIdUpload: {
    selectDocumentType: "Sila pilih jenis dokumen yang anda ingin muat naik",
    thisFieldIsRequired: "Medan ini diperlukan",
    uploadIdentityDocument: "Sila muat naik salinan dokumen pengenalan diri anda",
    uploadMustBeClear: "Anda perlu memastikan bahawa dokumen itu jelas dan boleh dibaca dalam fail yang dimuat naik.",
    errorUploading: "Terdapat ralat semasa memuat naik fail anda, sila cuba lagi.",
  },

  yoti: {
    clickManualUpload:
      "Klik di sini untuk memuat naik dokumen pengenalan diri anda secara manual jika anda mengalami masalah menggunakan perkhidmatan pengenalan diri Yoti",
    backToYoti: "Kembali untuk mengenal pasti dengan Yoti",
    verificationInProgress: "Pengesahan Identiti Sedang Berjalan",
    inProgressInfo:
      "Pengesahan identiti anda sedang dalam proses. Proses ini boleh mengambil beberapa minit. Kami menghargai kesabaran anda. Jika anda mengalami sebarang masalah, jangan ragu untuk menghubungi kami.",
    faqs: {
      troubleCamera: "Menghadapi masalah dengan kamera anda?",
      iphoneUsersTitle: "Pengguna iPhone",
      iphoneSafariInstructions: "Untuk Safari: Pergi ke Tetapan > Safari > Kamera dan pilih 'Benarkan'.",
      iphoneOtherBrowsersInstructions:
        "Untuk Pelayar Lain: Pergi ke Tetapan > [Pelayar Anda] > Kamera dan pilih 'Benarkan'.",

      androidUsersTitle: "Pengguna Android",
      androidPopupInstructions:
        "Popup seharusnya muncul meminta kebenaran apabila anda mencuba untuk menggunakan kamera.",
      androidManualInstructions:
        "Jika tidak, pergi ke Tetapan > Aplikasi > [Pelayar Anda] > Kebenaran dan aktifkan Kamera.",

      desktopUsersTitle: "Pengguna Desktop/Laptop",
      chromeInstructions: "Google Chrome: Ikon kamera akan muncul di bar alamat. Klik dan pilih 'Benarkan'.",
      firefoxInstructions: "Mozilla Firefox: Popup akan muncul berhampiran bar alamat. Klik 'Benarkan'.",
      edgeInstructions: "Microsoft Edge: Popup akan muncul di bahagian bawah pelayar. Klik 'Benarkan'.",
      safariInstructions: "Safari: Prompt akan muncul meminta kebenaran anda. Klik 'Benarkan'.",
      introTextDocs: "Yoti menerima dokumen berikut:",
      whatDocuments: "Dokumen pengenalan apa yang diterima?",
      passportItem: "Pasport semasa (diterima dari 200+ negara - klik di sini untuk melihat senarai negara)",
      drivingLicenseItem: "Lesen memandu foto (diterima dari 92 negara - klik di sini untuk melihat senarai negara)",
      nationalIdItem: "Kad Pengenalan Kebangsaan (diterima dari 66 negara - klik di sini untuk melihat senarai negara)",
      residencePermitItem: "Permit Kediaman British dengan foto",
      additionalInfo:
        "Beberapa versi dokumen ini mungkin tidak diterima kerana mereka tidak memenuhi keperluan keselamatan kami",
      howToTakePhoto: "Bagaimana saya mengambil foto ID yang baik?",
      title: "Perlukan bantuan dengan semakan identiti?",
      introTextTakePhoto:
        "Pastikan foto dan butiran peribadi pada dokumen anda menghadap ke atas. Foto akan diambil secara automatik. Bergantung pada negara penerbitannya, anda mungkin perlu mengambil foto depan dan belakang ID anda. Untuk mengambil foto ID anda, pastikan bahawa:",
      visibleCorners: "Keempat-empat penjuru ID anda kelihatan dalam foto",
      noFingerCovering: "Jari-jari anda tidak menutupi foto atau maklumat yang ditulis di atasnya",
      documentClarity: "Dokumen itu jelas dan fokus",
      adequateLighting: "Pencahayaan tidak terlalu gelap",
      clearImage:
        "Pastikan anda mengambil gambar yang jelas tanpa sebarang silau - untuk hasil terbaik, gunakan telefon pintar anda.",
      avoidGlare: "Anda mengelakkan sebarang silau atau pantulan",
      fullDocumentImage: "Seluruh dokumen mesti dalam gambar.",
      contact: "Jika anda mengalami masalah untuk menyelesaikan pengesahan identiti, sila hubungi pasukan kami dengan",
      clickHere: "klik di sini",
      struggleToConfirm: "Mengalami kesukaran untuk mengesahkan identiti anda?",
    },
  },

  multiFile: {
    addFile: "Tambah Fail",
  },
};
