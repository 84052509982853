import { BlockMultiMotorSpecsInstance } from "./BlockMultiMotorSpecs";
import { ClaimsGateMotorSpecsGetVehicleResponse } from "@claimsgate/core-types";
import {
  ClaimsGateErrors,
  ClaimsGateFunctionReturnedError,
  ClaimsCompass,
  Quantum,
  getClaimsByFunnelId,
} from "@claimsgate/core";
import { DateTime } from "luxon";
import { MotorSpecsPartialAdditionalVehicleStore } from "./types";
import omitBy from "lodash.omitby";
import isNil from "lodash.isnil";
import { reject } from "@/helpers/vue";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { JONES_WHYTE_FUNNELS } from "@/components/claimant/form/next";
import { getFirebaseBackend } from "@/authUtils";

/** Methods to be consumed by Vue instance */
export const methods = {
  submitAdditionalVehiclePage,
  searchAdditionalVehicle,
  addVehicle,
  submitAdditionalVehiclePageMethod,
  notMyVehicle,
  submitVehicleConfirmation,
  submitOwnershipDate,
  userHasClaimsForVin,
  displayHelpModal,
  resetToSearchAdditionalVehicleView,
  isClaimsGateFunctionReturnedError,
  calculateDepreciatedPrice,
  getVehicleFromMotorSpecs,
};

// Functions to set the visual state of each additional vehicles:

// 1. Reset to lookup VRM View
export async function moveToLookupView(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number) {
  state.additionalVehicles[additionalVehicleIndex].view.ownershipQuestions = false;
  state.additionalVehicles[additionalVehicleIndex].view.ownershipConfirmation = false;
  state.additionalVehicles[additionalVehicleIndex].view.historicalVinLookup = false;
  state.additionalVehicles[additionalVehicleIndex].view.showCard = false;
  await state.$nextTick();
  state.additionalVehicles[additionalVehicleIndex].view.lookup = true;
}

export async function moveToOwnershipQuestionsView(
  state: BlockMultiMotorSpecsInstance,
  additionalVehicleIndex: number
) {
  state.additionalVehicles[additionalVehicleIndex].view.lookup = false;
  state.additionalVehicles[additionalVehicleIndex].view.ownershipConfirmation = false;
  state.additionalVehicles[additionalVehicleIndex].view.historicalVinLookup = false;
  state.additionalVehicles[additionalVehicleIndex].view.showCard = false;
  await state.$nextTick();
  state.additionalVehicles[additionalVehicleIndex].view.ownershipQuestions = true;
}

export async function moveToOwnershipConfirmationView(
  state: BlockMultiMotorSpecsInstance,
  additionalVehicleIndex: number
) {
  state.additionalVehicles[additionalVehicleIndex].view.lookup = false;
  state.additionalVehicles[additionalVehicleIndex].view.ownershipQuestions = false;
  state.additionalVehicles[additionalVehicleIndex].view.historicalVinLookup = false;
  state.additionalVehicles[additionalVehicleIndex].view.showCard = false;
  await state.$nextTick();
  state.additionalVehicles[additionalVehicleIndex].view.ownershipConfirmation = true;
}

export async function moveToHistoricalVinLookupView(
  state: BlockMultiMotorSpecsInstance,
  additionalVehicleIndex: number
) {
  state.additionalVehicles[additionalVehicleIndex].view.lookup = false;
  state.additionalVehicles[additionalVehicleIndex].view.ownershipQuestions = false;
  state.additionalVehicles[additionalVehicleIndex].view.ownershipConfirmation = false;
  state.additionalVehicles[additionalVehicleIndex].view.showCard = false;
  await state.$nextTick();
  state.additionalVehicles[additionalVehicleIndex].view.historicalVinLookup = true;
}

export async function moveToShowCardView(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number) {
  state.additionalVehicles[additionalVehicleIndex].view.lookup = false;
  state.additionalVehicles[additionalVehicleIndex].view.ownershipQuestions = false;
  state.additionalVehicles[additionalVehicleIndex].view.ownershipConfirmation = false;
  state.additionalVehicles[additionalVehicleIndex].view.historicalVinLookup = false;
  await state.$nextTick();
  state.additionalVehicles[additionalVehicleIndex].view.showCard = true;
}

// 2. Reset to search additional vehicle view
export function resetToSearchAdditionalVehicleView(
  state: BlockMultiMotorSpecsInstance,
  additionalVehicleIndex: number
) {
  // Clear fetched vehicle
  state.vehicle = null;

  // Clear previous answers
  state.vrm = "";
  state.ownershipDate = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.answer = "";
  state.BlockInputs.keeperSelectSingleSelect.answer = "";

  // Clear previous block states
  state.vrmInputState = null;
  state.BlockInputs.ownershipDatePicker.state = null;
  state.BlockInputs.vehicleConfirmationSingleSelect.state = null;
  state.BlockInputs.keeperSelectSingleSelect.state = null;

  // Clear previous block invalid feedbacks
  state.vrmInputInvalidFeedback = "";
  state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = "";
  state.BlockInputs.ownershipDatePicker.invalidFeedback = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.invalidFeedback = "";

  // Display the vehicle select
  state.uiToggles.isKeeperSelectVisible = false;
  state.uiToggles.showVehicleConfirmation = false;
  state.uiToggles.showOwnershipDateQuestion = false;
  state.uiToggles.additionalVehicles.showAdditionalVehicleSection = false;
  state.$nextTick(() => {
    state.uiToggles.isVehicleSearchVisible = false;
    state.uiToggles.additionalVehicles.showAdditionalVehicleSection = true;
    // show just <template v-if="additionalVehicle.view === 'lookup'">
    state.additionalVehicles[additionalVehicleIndex].view.lookup = true;
  });

  // Reset the HTML text
  state.BlockInputs.confirmVehicleText.html = "";

  // Reset button loaders
  state.BlockInputs.submitVehicleConfirmationButton.isProcessing = false;
  state.BlockInputs.searchRegButton.isProcessing = false;
  state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
}

export function displayHelpModal(state: BlockMultiMotorSpecsInstance) {
  state.$bvModal.show("helpModal");
}

function validateOwnershipDate(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number): boolean {
  state.BlockInputs.submitOwnershipDateButton.isProcessing = true;

  // Add null check for additionalVehicles array and index
  if (!state.additionalVehicles?.[additionalVehicleIndex]) {
    reject(state, state.uiMessages.errorFindingVehicle);
    state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
    return false;
  }

  if (!state.additionalVehicles[additionalVehicleIndex].ownershipDate) {
    state.BlockInputs.additionalOwnershipDatePicker.state = false;
    state.BlockInputs.additionalOwnershipDatePicker.invalidFeedback = state.uiMessages.noOwnershipDateSelected;
    reject(state, state.uiMessages.noOwnershipDateSelected);
    state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
    return false;
  }

  const ownershipDate = DateTime.fromJSDate(new Date(state.additionalVehicles[additionalVehicleIndex].ownershipDate));
  const currentDate = DateTime.now().setZone("utc");

  if (ownershipDate > currentDate) {
    state.BlockInputs.additionalOwnershipDatePicker.state = false;
    state.BlockInputs.additionalOwnershipDatePicker.invalidFeedback = state.uiMessages.ownershipDateInFuture;
    state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
    return false;
  }

  state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
  return true;
}

// Function to validate the additional keeper start date picker
async function validateKeeperStartDatePicker(state: BlockMultiMotorSpecsInstance) {
  if (!state.BlockInputs.additionalKeeperStartDatePicker.answer) {
    state.BlockInputs.additionalKeeperStartDatePicker.invalidFeedback = state.uiMessages.noKeeperStartDateSelected;
    state.BlockInputs.additionalKeeperStartDatePicker.state = false;
    reject(state, state.uiMessages.noKeeperStartDateSelected);
    state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
    return false;
  }
  // If the input passes validation, set the state to true
  state.BlockInputs.additionalKeeperStartDatePicker.state = true;
  return true;
}

// Function to validate the additional keeper end date picker
async function validateKeeperEndDatePicker(state: BlockMultiMotorSpecsInstance) {
  if (!state.BlockInputs.additionalKeeperEndDatePicker.answer) {
    state.BlockInputs.additionalKeeperEndDatePicker.invalidFeedback = state.uiMessages.noKeeperEndDateSelected;
    state.BlockInputs.additionalKeeperEndDatePicker.state = false;
    reject(state, state.uiMessages.noKeeperEndDateSelected);
    state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
    return false;
  }

  if (
    state.BlockInputs.additionalKeeperStartDatePicker.answer > state.BlockInputs.additionalKeeperEndDatePicker.answer
  ) {
    state.BlockInputs.additionalKeeperEndDatePicker.invalidFeedback = state.uiMessages.keeperEndDateBeforeStartDate;

    state.BlockInputs.additionalKeeperEndDatePicker.state = false;
    reject(state, state.uiMessages.keeperEndDateBeforeStartDate);
    state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
    return false;
  }
  // If the input passes validation, set the state to true
  state.BlockInputs.additionalKeeperEndDatePicker.state = true;
  return true;
}

export async function userHasClaimsForVin(
  state: BlockMultiMotorSpecsInstance,
  additionalVehicleIndex?: number,
  options?: { ignoreRejectedClaims?: boolean }
): Promise<boolean> {
  // const claimStatus = state.claimDataService.getArtefact("claimStatus");

  // comment out this if you want testing to be included
  // if (claimStatus === "testing") {
  //   return false;
  // }
  //Check that vin is not existing in this users claims
  const [claimsForUser, error] = await getClaimsByFunnelId(
    getFirebaseBackend().firestore(),
    state.userId,
    state.funnelId
  );

  if (state.claimDataService.getArtefact("claimStatus") === "testing") {
    return false;
  }

  if (error) {
    console.error("[motorspecs/methods.ts] Failed to get claims for user", error);
  }

  const funnelVariables = await _getFunnelVariables(state);

  const vinVariable = funnelVariables.find((variable) => variable.field === "vin");

  const existingClaim = claimsForUser.find((claim) => {
    if (claim.currentFunnelId !== state.funnelId) {
      return false;
    }
    // comment out this if you want testing to be included
    // if (claim.claimStatus === "testing" || claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
    //   return false;
    // }

    if (claim.claimId === state.claimId) {
      return false;
    }

    if (claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
      return false;
    }

    if (claim.clientClaimProgress === "rejected" && options.ignoreRejectedClaims) {
      return false;
    }

    return claim?.[vinVariable.id] === state.additionalVehicles[additionalVehicleIndex].vin;
  });

  if (!existingClaim) {
    return false;
  }

  return true;
}

// Function to submit the additional ownership date

export async function submitOwnershipDate(
  state: BlockMultiMotorSpecsInstance,
  forceHistoricalVinLookup: boolean,
  additionalVehicleIndex: number
) {
  try {
    // Set processing state at the start
    state.BlockInputs.submitOwnershipDateButton.isProcessing = true;
    state.BlockInputs.submitOwnershipDateButton.isDisabled = true;
    state.uiToggles.isSubmitting = true;
    // Add initial validation
    if (typeof additionalVehicleIndex !== "number" || !state.additionalVehicles?.[additionalVehicleIndex]) {
      console.error("Invalid additionalVehicleIndex or missing additionalVehicles array");
      state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
      state.BlockInputs.submitOwnershipDateButton.isDisabled = false;
      state.uiToggles.isSubmitting = false;
      return;
    }

    await state.$nextTick();
    console.log("ownership date", state.additionalVehicles[additionalVehicleIndex].ownershipDate);

    // set up a current vehicle which will be the vehicle registration after selecting the ownership date
    const currentVehicle = state.additionalVehicles[additionalVehicleIndex];
    console.log("aaaaaa", currentVehicle);

    if (!validateOwnershipDate(state, additionalVehicleIndex)) {
      state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
      state.BlockInputs.submitOwnershipDateButton.isDisabled = false;
      state.uiToggles.isSubmitting = false;
      console.log("ownership date validation failed");
      return;
    }

    if (forceHistoricalVinLookup) {
      // Santitise the vrm
      const vid = currentVehicle.enteredRegistration.replace(/\s/g, "").toUpperCase();
      const ownershipDate = DateTime.fromJSDate(new Date(currentVehicle.ownershipDate));

      console.log("ownershipDate", ownershipDate.toISO());
      const { data: motorSpecsResponse } = await getVehicleFromMotorSpecs(
        state,
        vid,
        forceHistoricalVinLookup,
        ownershipDate
      );

      // Add error handling for the response
      if (!motorSpecsResponse) {
        reject(state, state.uiMessages.errorFindingVehicle);
        state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
        state.BlockInputs.submitOwnershipDateButton.isDisabled = false;
        state.uiToggles.isSubmitting = false;
        console.log("error finding vehicle");
        return;
      }

      const parsedResponse = await parseMotorSpecsResponseForAdditionalVehicle(
        motorSpecsResponse,
        state,
        additionalVehicleIndex,
        forceHistoricalVinLookup
      );

      console.log("parsedResponse", parsedResponse);
      if (parsedResponse) {
        state.additionalVehicles[additionalVehicleIndex].view.historicalVinLookup = false; // TODO
        state.additionalVehicles[additionalVehicleIndex].view.ownershipConfirmation = false; // TODO
        await state.$nextTick();
        state.additionalVehicles[additionalVehicleIndex].view.ownershipQuestions = true; // TODO
        setupAdditionalKeeperChangeSingleSelect(state, additionalVehicleIndex);
        await state.$nextTick();
        return;
      } else {
        // Error case - vehicle was not found or there was an error
        state.additionalVehicles[additionalVehicleIndex].view.historicalVinLookup = false; // TODO
        state.additionalVehicles[additionalVehicleIndex].view.ownershipQuestions = false; // TODO
        state.additionalVehicles[additionalVehicleIndex].view.ownershipConfirmation = false; // TODO
        await state.$nextTick();
        state.additionalVehicles[additionalVehicleIndex].view.lookup = true; // TODO
        state.additionalVehicles[additionalVehicleIndex].vrmInputState = false;
        state.additionalVehicles[additionalVehicleIndex].state = false;
        state.additionalVehicles[additionalVehicleIndex].invalidFeedback = state.uiMessages.noVehicleFound;
        reject(state, state.uiMessages.noVehicleFound);
        state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
        state.BlockInputs.submitOwnershipDateButton.isDisabled = false;
        state.uiToggles.isSubmitting = false;
        return;
      }
    }
  } catch (error) {
    console.error("Error in submitOwnershipDate:", error);
    reject(state, state.uiMessages.errorFindingVehicle);
  } finally {
    // Always reset processing states
    state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
    state.BlockInputs.submitOwnershipDateButton.isDisabled = false;
    state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
    state.uiToggles.isSubmitting = false;
  }
}

/** Function to setup the keeper change single select for the additional vehicle */
function setupAdditionalKeeperChangeSingleSelect(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number) {
  const currentVehicle = state.additionalVehicles[additionalVehicleIndex];

  if (!currentVehicle?.keeperChangeHistory?.length) {
    console.warn("No keeper change history found for vehicle");
    state.BlockInputs.additionalKeeperSelectSingleSelect.options = state.allowManualKeeperEntry ? ["Other"] : [];
    return;
  }

  state.BlockInputs.additionalKeeperSelectSingleSelect.options = [
    ...currentVehicle.keeperChangeHistory.map((keeperChange) => {
      return DateTime.fromJSDate(new Date(keeperChange.startDate)).toISO();
    }),
    ...(state.allowManualKeeperEntry ? ["Other"] : []),
  ];

  // Reset the select input
  state.BlockInputs.additionalKeeperSelectSingleSelect.answer = "";
  state.BlockInputs.additionalKeeperSelectSingleSelect.state = null;
  state.BlockInputs.additionalKeeperSelectSingleSelect.invalidFeedback = "";
}

/** Function to validate the keeper selected for the additional vehicle */
function validateAdditionalKeeperSelected(state: BlockMultiMotorSpecsInstance): boolean {
  if (state.BlockInputs.additionalKeeperSelectSingleSelect.answer?.length === 0) {
    state.BlockInputs.additionalKeeperSelectSingleSelect.invalidFeedback = state.uiMessages.noKeeperSelected;

    reject(state, state.uiMessages.noKeeperSelected);
    state.BlockInputs.additionalKeeperSelectSingleSelect.state = false;
    state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;

    return false;
  }
  return true;
}

/** Function to validate the purchase method selected for the additional vehicle */
function validateAdditionalPurchaseMethodSelected(state: BlockMultiMotorSpecsInstance): boolean {
  if (state.BlockInputs.additionalPurchaseMethodSingleSelect.answer?.length === 0) {
    state.BlockInputs.additionalPurchaseMethodSingleSelect.invalidFeedback = state.uiMessages.noPurchaseMethodSelected;

    reject(state, state.uiMessages.noPurchaseMethodSelected);
    state.BlockInputs.additionalPurchaseMethodSingleSelect.state = false;
    state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
    return false;
  }
  return true;
}

/** Function to parse the response from the MotorSpecs backend and store the additional vehicle data */
async function parseMotorSpecsResponseForAdditionalVehicle(
  motorSpecsResponse: ClaimsGateMotorSpecsGetVehicleResponse | ClaimsGateFunctionReturnedError,
  state: BlockMultiMotorSpecsInstance,
  additionalVehicleIndex: number,
  forceHistoricalVinLookup: boolean = false
): Promise<boolean> {
  console.log("isClaimsGateFunctionReturnedError", isClaimsGateFunctionReturnedError(motorSpecsResponse));

  if (isClaimsGateFunctionReturnedError(motorSpecsResponse)) {
    const isMotorSpecsVehicleNotFoundError =
      motorSpecsResponse.error.id === new ClaimsGateErrors.MotorSpecsVehicleNotFound().id ||
      motorSpecsResponse.error.id === "MotorSpecsVehicleNotFound";

    if (isMotorSpecsVehicleNotFoundError) {
      // If no vehicle was found then collect the ownership date for a more accurate search
      if (!forceHistoricalVinLookup) {
        // ! We would need ot update the view to collect the Ownership Date, in order to perform a more accurate lookup
        // ! This code -pretty much reads:
        // ! => If we can't find the car with the providedr registration and we have not already done a historical VIN lookup
        // ! => Then we need to collect the ownership date and perform a historical VIN lookup

        await moveToHistoricalVinLookupView(state, additionalVehicleIndex);
        reject(state, state.uiMessages.noVehicleFound);
        state.uiToggles.showAdditionalVehicleOwnershipDateQuestion = true;
        await state.$nextTick();
        state.uiToggles.isVehicleSearchVisible = false;
      } else {
        await moveToLookupView(state, additionalVehicleIndex);
        state.additionalVehicles[additionalVehicleIndex].vrmInputState = false;
        state.additionalVehicles[additionalVehicleIndex].invalidFeedback = state.uiMessages.noVehicleFound;
        reject(state, state.uiMessages.noVehicleFound);
        state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
      }
    } else {
      state.additionalVehicles[additionalVehicleIndex].vrmInputState = false;
      state.additionalVehicles[additionalVehicleIndex].state = false;
      state.additionalVehicles[additionalVehicleIndex].invalidFeedback = state.uiMessages.errorFindingVehicle;
      reject(state, state.uiMessages.errorFindingVehicle);
      state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;

      // Await next tick to ensure UI updates
      await state.$nextTick();
    }

    return false;
  }

  console.log("Prior to the issue", additionalVehicleIndex, state.additionalVehicles[additionalVehicleIndex]);

  const additionalVehicle: MotorSpecsPartialAdditionalVehicleStore = {
    purchaseMethod: motorSpecsResponse.purchaseMethod,
    id: Date.now().toString(), //unique id for each vehicle
    isEligible: motorSpecsResponse.isEligible, //new
    defender: motorSpecsResponse.defender, //new
    contactEmail: motorSpecsResponse.contactEmail, //new
    ownershipDate: motorSpecsResponse.ownershipDate,
    emCode: motorSpecsResponse.emCode,
    keeperStartDate: motorSpecsResponse.keeperStartDate,
    enteredRegistration: state.additionalVehicles[additionalVehicleIndex].enteredRegistration
      .toUpperCase()
      .replace(/\s/g, ""),
    numberOfPreviousKeepers: motorSpecsResponse.numberOfPreviousKeepers,
    isCurrentKeeper: motorSpecsResponse.isCurrentKeeper,
    wereKeeperDatesManuallyEntered: motorSpecsResponse.wereKeeperDatesManuallyEntered,
    emEngineFamily: motorSpecsResponse.emCodeDetails?.engineFamily,
    make: motorSpecsResponse.vehicle.combined.make,
    model: motorSpecsResponse.vehicle.combined.model,
    version: motorSpecsResponse.vehicle.combined.version,
    colour: motorSpecsResponse.vehicle.combined.colour,
    vin: motorSpecsResponse.vehicle.combined.vin,
    vehicleRegistration: motorSpecsResponse.vehicle.combined.registration,
    fuelType: motorSpecsResponse.vehicle.combined.fuel,
    numberOfCylinders: motorSpecsResponse.vehicle.mvris.cylinderCount,
    engineCapacity: motorSpecsResponse.vehicle.combined.cc,
    engineSize: motorSpecsResponse.vehicle.mvris.engineSize,
    dateFirstRegistered: motorSpecsResponse.vehicle.combined.regDate,
    bhp: motorSpecsResponse.vehicle.combined.powerBHP,
    torqueNm: motorSpecsResponse.vehicle.mvris.torqueNm,
    engineNumber: motorSpecsResponse.vehicle.dvla.engineNumber,
    engineModelCode: motorSpecsResponse.vehicle.dvla.engineCode ?? motorSpecsResponse.vehicle.mvris.engineDescription,
    engineLocation: motorSpecsResponse.vehicle.mvris.engineLocation,
    euroStatus: motorSpecsResponse.vehicle.mvris.euroStatus?.toString(),
    euroStatusEnhanced: motorSpecsResponse.emCodeDetails?.euroStageEnhanced,
    numberOfDoors: motorSpecsResponse.vehicle.combined.doors,
    series: motorSpecsResponse.vehicle.mvris.vehicleSeries,
    maximumPowerInKw: motorSpecsResponse.vehicle.combined.powerKW ?? motorSpecsResponse.vehicle.mvris.powerKw,
    vinLast5: motorSpecsResponse.vehicle.combined.vin.substring(motorSpecsResponse.vehicle.combined.vin.length - 5),
    yearOfManufacture: DateTime.fromISO(
      motorSpecsResponse.vehicle?.combined?.manufDate ?? motorSpecsResponse.vehicle?.combined?.regDate
    )
      .get("year")
      .toString(),
    keeperChangeHistory: motorSpecsResponse.keepersHistory,
    mercedesEmissionsRecallMandatoryStatus:
      motorSpecsResponse.mercedesRecallComputedFields?.mercedesEmissionsRecallMandatoryStatus,
    mercedesEmissionsRecallEligibleForExclusion:
      motorSpecsResponse.mercedesRecallComputedFields?.mercedesEmissionsRecallEligibleForExclusion,
    mercedesEmissionsRecallAccomplished:
      motorSpecsResponse.mercedesRecallComputedFields?.mercedesEmissionsRecallAccomplished,
    manufacturerPurchasePrice: motorSpecsResponse.manufacturerPurchasePrice,
    purchasePriceInformation: motorSpecsResponse.purchasePriceInformation,
    recalls: motorSpecsResponse.recalls,
    dpfAffected: motorSpecsResponse.dpf?.isEligible,
    dpfConfidenceScoreReason: motorSpecsResponse.dpf?.reasons,
    dpfEngineMake: motorSpecsResponse.dpf?.engineMake,
    dpfPlatform: motorSpecsResponse.dpf?.platform,
    dpfSeries: motorSpecsResponse.dpf?.series,
    ukvdEuroStatus: motorSpecsResponse.ukvdEuroStatus,
    ukvdTypeApproval: motorSpecsResponse.ukvdTypeApproval,
    mvrisTypeApprovalCategory: motorSpecsResponse.mvrisTypeApprovalCategory,
    milbergVehicleEligible: true,
    milbergVehicleRejectionTag: "",
    milbergVehicleRejectionExplanation: "",
    tlwVehicleEligible: true,
    tlwVehicleRejectionTag: "",
    tlwVehicleRejectionExplanation: "",
    vehicleDataSource: "MotorCheck",
    manufactureDate: DateTime.fromISO(
      motorSpecsResponse.vehicle?.combined?.manufDate ?? motorSpecsResponse.vehicle?.combined?.regDate
    ).toISO(),

    eaEngineConfig: motorSpecsResponse.engineConfig,
    view: {
      lookup: true,
      ownershipQuestions: false,
      ownershipConfirmation: false,
      historicalVinLookup: false,
      success: false,
      showCard: false,
    },
  };

  console.log("additionalVehicle", additionalVehicle);

  const compactedAdditionalVehicle: MotorSpecsPartialAdditionalVehicleStore = omitBy(additionalVehicle, isNil);

  console.log("compactedAdditionalVehicle", compactedAdditionalVehicle);

  state.$set(state.additionalVehicles, additionalVehicleIndex, compactedAdditionalVehicle);

  //show vehicle confirmation section
  state.uiToggles.additionalVehicles.showAdditionalVehicleSection = true;
  await moveToOwnershipConfirmationView(state, additionalVehicleIndex);
  await state.$nextTick();
  state.uiToggles.isVehicleSearchVisible = false;
  await state.$nextTick();

  if (forceHistoricalVinLookup) {
    state.uiToggles.showAdditionalVehicleOwnershipDateQuestion = false;
  }

  state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;

  return true;
}

/** Function to validate the VRM entered for the additional vehicle */
function validateAdditionalVrm(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number): boolean {
  if (!state.additionalVehicles[additionalVehicleIndex]?.enteredRegistration?.length) {
    // Only update validation state for this specific vehicle
    state.additionalVehicles[additionalVehicleIndex].vrmInputState = false;
    state.additionalVehicles[additionalVehicleIndex].invalidFeedback = state.uiMessages.enterReg;
    reject(state, state.uiMessages.enterReg);
    state.additionalVehicles[additionalVehicleIndex].state = false;
    state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
    return false;
  }

  return true;
}

/** Function to search for the additional vehicle - corresponds to the "Lookup" button */
export async function searchAdditionalVehicle(
  state: BlockMultiMotorSpecsInstance,
  forceHistoricalVinLookup: boolean = false,
  additionalVehicleIndex: number
) {
  // ! Reset the annwers to the upcoming questions concerning Purchase Method and Purchase Date
  state.BlockInputs.additionalPurchaseMethodSingleSelect.answer = "";
  state.BlockInputs.additionalKeeperSelectSingleSelect.answer = "";
  state.BlockInputs.additionalKeeperSelectSingleSelect.invalidFeedback = "";
  state.BlockInputs.additionalPurchaseMethodSingleSelect.invalidFeedback = "";
  state.BlockInputs.additionalKeeperSelectSingleSelect.state = null;
  state.BlockInputs.additionalPurchaseMethodSingleSelect.state = null;

  state.BlockInputs.additionalVehicleConfirmationSingleSelect.answer = "";
  state.BlockInputs.additionalVehicleConfirmationSingleSelect.state = null;

  // ! Reset the invalid feedback every time the button is pressed
  state.additionalVehicles[additionalVehicleIndex].vrmInputState = null;
  state.additionalVehicles[additionalVehicleIndex].invalidFeedback = "";

  // ! Set the loader to true
  state.BlockInputs.addVehicleButton.isProcessing = false;
  state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
  state.BlockInputs.searchAdditionalVehicleButton.isProcessing = true;

  // Breaks if we were unable to validate the entered VRM
  if (!validateAdditionalVrm(state, additionalVehicleIndex)) {
    state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
    return;
  }

  // Validate the vehicle and VRM exist
  if (!state.additionalVehicles?.[additionalVehicleIndex]) {
    console.error("Vehicle not found at index:", additionalVehicleIndex);
    state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
    return;
  }

  // Check against all additional vehicles
  const isDuplicateFound = state.additionalVehicles.some((vehicle, index) => {
    // Skip checking against the current vehicle being added
    if (index === additionalVehicleIndex) {
      return false;
    }
    return (
      vehicle.vehicleRegistration?.toUpperCase() ===
      state.additionalVehicles[additionalVehicleIndex].enteredRegistration?.toUpperCase().replace(/\s/g, "")
    );
  });

  if (isDuplicateFound) {
    state.additionalVehicles[additionalVehicleIndex].state = false;
    state.additionalVehicles[additionalVehicleIndex].vrmInputState = false;
    state.additionalVehicles[additionalVehicleIndex].invalidFeedback = state.uiMessages.duplicateVehicleRegistration;
    state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
    reject(state, state.uiMessages.duplicateVehicleRegistration);
    return;
  }

  const { data: motorSpecsResponse } = await getVehicleFromMotorSpecs(
    state,
    state.additionalVehicles[additionalVehicleIndex].enteredRegistration.replace(/\s/g, "").toUpperCase()
  );
  if (!(await parseMotorSpecsResponseForAdditionalVehicle(motorSpecsResponse, state, additionalVehicleIndex))) {
    state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
    return;
  }

  state.BlockInputs.submitOwnershipDateButton.isProcessing = true;

  setupAdditionalKeeperChangeSingleSelect(state, additionalVehicleIndex);
  await state.$nextTick();

  state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
  await moveToOwnershipConfirmationView(state, additionalVehicleIndex);

  state.uiToggles.showVehicleConfirmation = false;
}

export async function submitVehicleConfirmation(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number) {
  if (!state.BlockInputs.additionalVehicleConfirmationSingleSelect.answer) {
    state.BlockInputs.additionalVehicleConfirmationSingleSelect.state = false;
    state.BlockInputs.additionalVehicleConfirmationSingleSelect.invalidFeedback = "Please select an option.";
    return;
  }

  if (state.BlockInputs.additionalVehicleConfirmationSingleSelect.answer === "Yes") {
    await moveToOwnershipQuestionsView(state, additionalVehicleIndex);
  }

  if (state.BlockInputs.additionalVehicleConfirmationSingleSelect.answer === "No") {
    state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
    await moveToHistoricalVinLookupView(state, additionalVehicleIndex);
    setupAdditionalKeeperChangeSingleSelect(state, additionalVehicleIndex);
    await state.$nextTick();
  }
  // Reset the confirmation select after processing
  state.BlockInputs.additionalVehicleConfirmationSingleSelect.answer = "";
  state.BlockInputs.additionalVehicleConfirmationSingleSelect.state = null;
}

/** Function to store additional vehicles locally and trigger another lookup - The corresponding button is "Add another vehicle" */

export async function addVehicle(state: BlockMultiMotorSpecsInstance) {
  // Reset additionalVehicleConfirmationSingleSelect
  state.error = "";

  state.additionalVehicles.push({
    vrm: "",
    id: Date.now().toString(), //unique id for each vehicle
    isEligible: false,
    enteredRegistration: "",
    ownershipDate: "",
    keeperEndDate: "",
    purchaseMethod: "",
    vehicleAcquisitionLocation: "",
    keeperStartDate: "",
    numberOfPreviousKeepers: 0,
    isCurrentKeeper: false,
    wereKeeperDatesManuallyEntered: false,
    state: null,
    invalidFeedback: "",
    vrmInputState: null,
    vrmInputInvalidFeedback: "",
    keeperChangeHistory: [],
    milbergVehicleEligible: true,
    milbergVehicleRejectionTag: "",
    milbergVehicleRejectionExplanation: "",
    view: {
      lookup: true,
      ownershipQuestions: false,
      ownershipConfirmation: false,
      historicalVinLookup: false,
      success: false,
      showCard: false,
    },
  });

  state.BlockInputs.additionalPurchaseLocationSingleSelect.answer = "";
  state.BlockInputs.additionalPurchaseLocationSingleSelect.state = null;
  state.BlockInputs.additionalPurchaseLocationSingleSelect.invalidFeedback = "";
  state.BlockInputs.additionalKeeperStartDatePicker.answer = null;
  state.BlockInputs.additionalKeeperStartDatePicker.invalidFeedback = "";
  state.BlockInputs.additionalKeeperStartDatePicker.state = null;
  state.BlockInputs.ownershipDatePicker.answer = null;
  state.BlockInputs.ownershipDatePicker.invalidFeedback = "";
  state.BlockInputs.ownershipDatePicker.state = null;
  state.BlockInputs.submitSingleVehicleButton.isProcessing = false;
  state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
  state.BlockInputs.additionalOwnershipDatePicker.answer = null;
  state.BlockInputs.additionalOwnershipDatePicker.invalidFeedback = "";
  state.BlockInputs.additionalOwnershipDatePicker.state = null;

  //console.log for additionalVehicles array:
  console.log("additionalVehicles", state.additionalVehicles);
  await state.$nextTick();
}

/** When the user selects not my vehicle */
export async function notMyVehicle(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number) {
  resetToAddVehicle(state, additionalVehicleIndex);

  console.log("additionalVehicles", state.additionalVehicles);
  await state.$nextTick();
}

export function resetToAddVehicle(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number) {
  state.$set(state.additionalVehicles, additionalVehicleIndex, {
    vrm: "",
    id: Date.now().toString(), //unique id for each vehicle
    isEligible: false,
    enteredRegistration: "",
    ownershipDate: "",
    purchaseMethod: "",
    keeperStartDate: "",
    numberOfPreviousKeepers: 0,
    isCurrentKeeper: false,
    wereKeeperDatesManuallyEntered: false,
    state: null,
    invalidFeedback: "",
    vrmInputState: null,
    vrmInputInvalidFeedback: "",
    keeperChangeHistory: [],
    view: {
      lookup: true,
      ownershipQuestions: false,
      ownershipConfirmation: false,
      historicalVinLookup: false,
      success: false,
    },
  });
}

async function _getFunnelVariables(state: BlockMultiMotorSpecsInstance) {
  let { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);
  if (JONES_WHYTE_FUNNELS.includes(state.funnelId)) {
    // If we are on a Jones Whyte funnel and the vehicle is a Mercedes
    if (state.vehicle.make.toLowerCase().includes("mercedes")) {
      const { data: _funnelVariables } = await state.funnelsService.getFunnelVariables(
        Quantum.Funnels.JONES_WHYTE_MERCEDES_EMISSIONS_ID
      );

      funnelVariables = _funnelVariables;

      // If we are on a Jones Whyte funnel and the vehicle is not a Mercedes
    } else {
      const { data: _funnelVariables } = await state.funnelsService.getFunnelVariables(
        Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID
      );

      funnelVariables = _funnelVariables;
    }
  }

  return funnelVariables;
}

async function eligibleVehicleCheck(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number) {
  // DIESEL EMISSIONS AND CRITERIA CHECKS
  const vehicleState = state.additionalVehicles[additionalVehicleIndex];

  if (state.funnelId === ClaimsCompass.Funnels.DIESEL_EMISSIONS_FUNNEL_ID) {
    let isEuro5: boolean;

    if (vehicleState.ukvdEuroStatus) {
      isEuro5 = vehicleState.ukvdEuroStatus.toLowerCase().includes("5");
    } else if (vehicleState.euroStatus) {
      isEuro5 = vehicleState.euroStatus.toLowerCase().includes("5");
    }

    const isEuro6PreRDE = vehicleState.euroStatusEnhanced === "Euro 6 pre-RDE";

    if (!isEuro5 && !isEuro6PreRDE) {
      vehicleState.milbergVehicleEligible = false;
      vehicleState.invalidFeedback = `Vehicle does not have Euro 5 or Euro 6 pre-RDE status.`;
      vehicleState.view.success = false; // Show the red card
      vehicleState.isEligible = false;
      vehicleState.milbergVehicleRejectionTag = `Incorrect Euro Status`;
      vehicleState.milbergVehicleRejectionExplanation = `Vehicle does not have Euro 5 or Euro 6 pre-RDE status.`;
    }
    // The vehicle must have been manufactured between 2009 and 2018
    if (parseInt(vehicleState.yearOfManufacture) < 2009 || parseInt(vehicleState.yearOfManufacture) > 2018) {
      vehicleState.milbergVehicleEligible = false;
      vehicleState.invalidFeedback = `Vehicle was not manufactured between 2009 and 2018.`;
      vehicleState.view.success = false; // Show the red card
      vehicleState.isEligible = false;
      vehicleState.milbergVehicleRejectionTag = `Outside of Manufacture Dates`;
      vehicleState.milbergVehicleRejectionExplanation = `Vehicle was not manufactured between 2009 and 2018.`;
    }

    if (vehicleState.vehicleAcquisitionLocation.toLowerCase() !== "england/wales") {
      vehicleState.milbergVehicleEligible = false;
      vehicleState.invalidFeedback = `Vehicle was not acquired in England/Wales.`;
      vehicleState.view.success = false; // Show the red card
      vehicleState.isEligible = false;
      vehicleState.milbergVehicleRejectionTag = `Incorrect Vehicle Acquisition Location`;
      vehicleState.milbergVehicleRejectionExplanation = `Vehicle was not acquired in England/Wales.`;
    }

    if (vehicleState.fuelType.toLowerCase() !== "diesel") {
      vehicleState.milbergVehicleEligible = false;
      vehicleState.invalidFeedback = `Vehicle is not a Diesel.`;
      vehicleState.view.success = false; // Show the red card
      vehicleState.isEligible = false;
      vehicleState.milbergVehicleRejectionTag = `Incorrect Fuel Type`;
      vehicleState.milbergVehicleRejectionExplanation = `Vehicle is not a Diesel.`;
    }

    const makeEligible = ["CITROEN", "PEUGEOT", "VOLVO"].includes(vehicleState.make?.toUpperCase());

    console.log("makeEligible", makeEligible);

    if (!makeEligible) {
      vehicleState.milbergVehicleEligible = false;
      vehicleState.invalidFeedback = `Vehicle is not produced by an eligible manufacturer.`;
      vehicleState.view.success = false; // Show the red card
      vehicleState.isEligible = false;
      vehicleState.milbergVehicleRejectionTag = `Incorrect Make`;
      vehicleState.milbergVehicleRejectionExplanation = `Vehicle is not produced by an eligible manufacturer.`;
    }

    if (vehicleState.milbergVehicleEligible !== false) {
      vehicleState.invalidFeedback = null;
      vehicleState.view.success = true; // Show the green tick
      vehicleState.isEligible = true;
      vehicleState.milbergVehicleEligible = true;
      vehicleState.milbergVehicleRejectionTag = ``;
      vehicleState.milbergVehicleRejectionExplanation = `Vehicle is eligible`;
    }

    // ! CHECKED
    if (await userHasClaimsForVin(state, additionalVehicleIndex, { ignoreRejectedClaims: true })) {
      vehicleState.invalidFeedback = state.uiMessages.duplicateClaim;
      vehicleState.state = false;
      vehicleState.view.success = true;
      vehicleState.duplicateClaim = true;
      await moveToShowCardView(state, additionalVehicleIndex);

      state.$set(state.additionalVehicles, additionalVehicleIndex, vehicleState);
      return false;
    }
    await state.$nextTick();
    state.uiToggles.additionalVehicles.showAdditionalVehicleSection = true;
    await moveToShowCardView(state, additionalVehicleIndex);
    state.$set(state.additionalVehicles, additionalVehicleIndex, vehicleState);

    console.log("vehicleState", vehicleState);

    return true;
  }

  // ! JLR CRITERIA CHECKS
  if (
    state.funnelId === ClaimsCompass.Funnels.JLR_FUNNEL_ID ||
    state.funnelId === ClaimsCompass.Funnels.STAGING_FUNNEL_ID
  ) {
    console.log("JLR CRITERIA CHECKS");

    const vehicleState = state.additionalVehicles[additionalVehicleIndex];
    const manufactureDate = DateTime.fromJSDate(new Date(vehicleState.yearOfManufacture as string));
    const cutoffDate = DateTime.fromISO("2014-10-01");
    const purchaseDate = DateTime.fromISO(vehicleState.keeperStartDate);
    const currentDate = DateTime.now();

    // Check manufacture date is after Oct 1st 2014
    if (manufactureDate < cutoffDate) {
      vehicleState.tlwVehicleEligible = false;
      vehicleState.invalidFeedback = "Vehicle was manufactured before October 2014";
      vehicleState.view.success = false;
      vehicleState.isEligible = false;
      vehicleState.tlwVehicleRejectionTag = "Manufacture Date Too Early";
      vehicleState.tlwVehicleRejectionExplanation = "Vehicle must be manufactured after October 1st 2014";
    }

    if (vehicleState.vehicleAcquisitionLocation.toLowerCase() !== "england/wales") {
      vehicleState.tlwVehicleEligible = false;
      vehicleState.invalidFeedback = `Vehicle was not acquired in England/Wales.`;
      vehicleState.view.success = false; // Show the red card
      vehicleState.isEligible = false;
      vehicleState.tlwVehicleRejectionTag = `Incorrect Vehicle Acquisition Location`;
      vehicleState.tlwVehicleRejectionExplanation = `Vehicle was not acquired in England/Wales.`;
    }

    // Check purchase date ranges
    const purchaseStartDate = DateTime.fromISO("2015-04-01");
    const purchaseEndDate = DateTime.fromISO("2023-09-30");
    const isJaguarEPace = vehicleState.model?.toLowerCase().includes("e-pace") || vehicleState.series?.includes("X540");

    if (isJaguarEPace) {
      const epaceStartDate = DateTime.fromISO("2017-04-01");
      const epaceEndDate = DateTime.fromISO("2021-12-31");

      if (purchaseDate < epaceStartDate || purchaseDate > epaceEndDate) {
        vehicleState.tlwVehicleEligible = false;
        vehicleState.invalidFeedback = "Jaguar E-Pace must be purchased/leased between April 2017 and December 2021";
        vehicleState.view.success = false;
        vehicleState.isEligible = false;
        vehicleState.tlwVehicleRejectionTag = "Outside Purchase Window";
        vehicleState.tlwVehicleRejectionExplanation =
          "Jaguar E-Pace X540 must be purchased/leased between April 2017 and December 2021";
      }
    } else if (purchaseDate < purchaseStartDate || purchaseDate > purchaseEndDate) {
      vehicleState.tlwVehicleEligible = false;
      vehicleState.invalidFeedback = "Vehicle must be purchased/leased between April 2015 and September 2023";
      vehicleState.view.success = false;
      vehicleState.isEligible = false;
      vehicleState.tlwVehicleRejectionTag = "Outside Purchase Window";
      vehicleState.tlwVehicleRejectionExplanation =
        "Vehicle must be purchased/leased between April 2015 and September 2023";
    }

    // Check 5 year limitation for cash/lease purchases - CHECK
    const isCashOrLease = ["cash", "lease", "finance"].includes(vehicleState.purchaseMethod?.toLowerCase());

    if (isCashOrLease) {
      const fiveYearsAgo = DateTime.fromISO("2020-01-01");
      if (purchaseDate < fiveYearsAgo) {
        vehicleState.tlwVehicleEligible = false;
        vehicleState.invalidFeedback = "Cash/lease purchases must be from January 2020 onwards";
        vehicleState.view.success = false;
        vehicleState.isEligible = false;
        vehicleState.tlwVehicleRejectionTag = "Purchase Too Old";
        vehicleState.tlwVehicleRejectionExplanation =
          "Cash/lease purchases must be within the last 5 years (from January 2020)";
      }
    }

    // Check minimum 12 months ownership
    const ownershipDuration = vehicleState.isCurrentKeeper
      ? currentDate.diff(purchaseDate, "months").months
      : DateTime.fromISO(vehicleState.keeperEndDate).diff(purchaseDate, "months").months;
    if (ownershipDuration < 12) {
      vehicleState.tlwVehicleEligible = false;
      vehicleState.invalidFeedback = "Vehicle must be owned for at least 12 months";
      vehicleState.view.success = false;
      vehicleState.isEligible = false;
      vehicleState.tlwVehicleRejectionTag = "Insufficient Ownership Duration";
      vehicleState.tlwVehicleRejectionExplanation = "Vehicle must be owned for a minimum of 12 months";
    }

    // Check minimum purchase price
    const minimumPrice = 5000;
    const purchasePrice = vehicleState.estimatedPurchasePrice || vehicleState.manufacturerPurchasePrice;

    if (purchasePrice && purchasePrice < minimumPrice) {
      vehicleState.tlwVehicleEligible = false;
      vehicleState.invalidFeedback = "Vehicle purchase price must be £5,000 or more";
      vehicleState.view.success = false;
      vehicleState.isEligible = false;
      vehicleState.tlwVehicleRejectionTag = "Purchase Price Too Low";

      vehicleState.tlwVehicleRejectionExplanation = "Vehicle purchase price must be £5,000 or more";
    }

    // Check DPF series, platform and affected
    if (!vehicleState.dpfSeries || !vehicleState.dpfPlatform || !vehicleState.dpfAffected) {
      vehicleState.tlwVehicleEligible = false;
      vehicleState.invalidFeedback = "Vehicle is not eligible for a DPF claim";
      vehicleState.view.success = false;
      vehicleState.isEligible = false;
      vehicleState.tlwVehicleRejectionTag = "DPF Claim Not Eligible";
      vehicleState.tlwVehicleRejectionExplanation = "Vehicle is not eligible for a DPF claim";
    }

    const makeEligible = ["JAGUAR", "LAND ROVER"].includes(vehicleState.make?.toUpperCase());


    console.log("makeEligible", makeEligible);

    if (!makeEligible) {
      vehicleState.milbergVehicleEligible = false;
      vehicleState.invalidFeedback = `Vehicle is not produced by an eligible manufacturer.`;
      vehicleState.view.success = false; // Show the red card
      vehicleState.isEligible = false;
      vehicleState.tlwVehicleRejectionTag = `Incorrect Make`;
      vehicleState.tlwVehicleRejectionExplanation = `Vehicle is not produced by an eligible manufacturer.`;
    }


    // If all checks pass, mark as eligible
    if (vehicleState.tlwVehicleEligible !== false) {
      vehicleState.invalidFeedback = null;
      vehicleState.view.success = true; // Show the green tick
      vehicleState.isEligible = true;
      vehicleState.tlwVehicleEligible = true;
      vehicleState.tlwVehicleRejectionTag = ``;
      vehicleState.tlwVehicleRejectionExplanation = `Vehicle is eligible`;
    }

    // ! CHECKED
    if (await userHasClaimsForVin(state, additionalVehicleIndex, { ignoreRejectedClaims: true })) {
      vehicleState.invalidFeedback = state.uiMessages.duplicateClaim;
      vehicleState.state = false;
      vehicleState.view.success = true;
      vehicleState.duplicateClaim = true;
      await moveToShowCardView(state, additionalVehicleIndex);

      state.$set(state.additionalVehicles, additionalVehicleIndex, vehicleState);
      return false;
    }
    await state.$nextTick();
    state.uiToggles.additionalVehicles.showAdditionalVehicleSection = true;
    await moveToShowCardView(state, additionalVehicleIndex);
    state.$set(state.additionalVehicles, additionalVehicleIndex, vehicleState);

    console.log("vehicleState", vehicleState);

    return true;
  }
  // ! If the funnel is none of the above, there is no criteria to check, so the vehicle is eligible
  if (
    state.funnelId !== ClaimsCompass.Funnels.DIESEL_EMISSIONS_FUNNEL_ID &&
    state.funnelId !== ClaimsCompass.Funnels.STAGING_FUNNEL_ID
  ) {
    console.log(":) the car is eligible because the funnel doesnt have any criteria to check");
    vehicleState.view.success = true;
    await moveToShowCardView(state, additionalVehicleIndex);
    vehicleState.isEligible = true;
    vehicleState.invalidFeedback = "";
  }
  state.$set(state.additionalVehicles, additionalVehicleIndex, vehicleState);

  return true;
}

// Function to submit additional vehicles and store it locally - The corresponding button is "Continue"

export async function submitAdditionalVehiclePage(state: BlockMultiMotorSpecsInstance, additionalVehicleIndex: number) {
  if (!validateAdditionalKeeperSelected(state) || !validateAdditionalPurchaseMethodSelected(state)) {
    state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
    return false;
  }

  if (
    state.BlockInputs.additionalCurrentOwnerSingleSelect.answer === "No" ||
    state.BlockInputs.additionalCurrentOwnerSingleSelect.answer === "Yes"
  ) {
    if (!validateKeeperStartDatePicker(state) || !validateKeeperEndDatePicker(state)) {
      state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
      return false;
    }
  }

  state.additionalVehicles[additionalVehicleIndex].purchaseMethod =
    state.BlockInputs.additionalPurchaseMethodSingleSelect.answer;
  state.additionalVehicles[additionalVehicleIndex].vehicleAcquisitionLocation =
    state.BlockInputs.additionalPurchaseLocationSingleSelect.answer;

  // ! Duplicate check again in case there is a private reg situation
  const newVehicleRegistration = state.additionalVehicles[additionalVehicleIndex].vehicleRegistration;

  const isDuplicate = state.additionalVehicles.some(
    (vehicle, index) =>
      // Skip checking against the current vehicle
      index !== additionalVehicleIndex && vehicle.vehicleRegistration === newVehicleRegistration
  );

  if (isDuplicate) {
    state.additionalVehicles[additionalVehicleIndex].view.success = false;
    await moveToLookupView(state, additionalVehicleIndex);
    state.additionalVehicles[additionalVehicleIndex].state = false;
    state.additionalVehicles[additionalVehicleIndex].vrmInputState = false;
    state.additionalVehicles[additionalVehicleIndex].invalidFeedback = state.uiMessages.duplicateVehicleRegistration;
    state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
    reject(state, state.uiMessages.duplicateVehicleRegistration);
    return false;
  }
  // ! if the keeper is other and we allow manual keeper entry, set the keeper end and start date
  if (state.BlockInputs.additionalKeeperSelectSingleSelect.answer === "Other" && state.allowManualKeeperEntry) {
    // ! to calculate number of previous keepers
    const keeperChangeHistory = state.additionalVehicles[additionalVehicleIndex].keeperChangeHistory;
    const keeperStartDate = DateTime.fromJSDate(
      new Date(state.additionalVehicles[additionalVehicleIndex].keeperStartDate)
    );
    // Filter keeperChangeHistory to include only dates before keeperStartDate
    const calculatedNumberOfPreviousKeepers = keeperChangeHistory.filter(
      (keeperChange) => DateTime.fromJSDate(new Date(keeperChange.startDate)) < keeperStartDate
    ).length;

    console.log("calculatedNumberOfPreviousKeepers", calculatedNumberOfPreviousKeepers);
    state.$set(
      state.additionalVehicles[additionalVehicleIndex],
      "numberOfPreviousKeepers",
      calculatedNumberOfPreviousKeepers
    );

    // ! if collectOwnershipPeriod is true, set the ownership status

    if (state.collectOwnershipPeriod) {
      let keeperEndDate: DateTime;

      const keeperStartDate = DateTime.fromJSDate(
        new Date(state.additionalVehicles[additionalVehicleIndex].keeperStartDate)
      );

      if (
        state.additionalVehicles[additionalVehicleIndex].keeperEndDate &&
        !state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper
      ) {
        keeperEndDate = DateTime.fromJSDate(new Date(state.additionalVehicles[additionalVehicleIndex].keeperEndDate));
      } else {
        keeperEndDate = DateTime.now();
        state.$set(state.additionalVehicles[additionalVehicleIndex], "keeperEndDate", keeperEndDate.toISO());
      }

      console.log("keeperStartDate", keeperStartDate);
      console.log("keeperEndDate", keeperEndDate);

      const ownershipDuration = keeperEndDate.diff(keeperStartDate, ["months"]);
      state.$set(
        state.additionalVehicles[additionalVehicleIndex],
        "keeperOwnershipPeriodInMonths",
        Math.floor(ownershipDuration.months)
      );
    }

    if (state.BlockInputs.additionalCurrentOwnerSingleSelect.answer === "No") {
      state.$set(state.additionalVehicles[additionalVehicleIndex], "isCurrentKeeper", false);
      await state.$nextTick();

      if (!validateKeeperStartDatePicker(state) || !validateKeeperEndDatePicker(state)) {
        state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
        return false;
      }

      state.$set(
        state.additionalVehicles[additionalVehicleIndex],
        "keeperStartDate",
        state.BlockInputs.additionalKeeperStartDatePicker.answer
      );
      state.$set(
        state.additionalVehicles[additionalVehicleIndex],
        "keeperEndDate",
        state.BlockInputs.additionalKeeperEndDatePicker.answer
      );
      state.$set(
        state.additionalVehicles[additionalVehicleIndex],
        "vehicleOwnershipStatus",
        "I have disposed of the vehicle"
      );
    } else {
      if (!validateKeeperStartDatePicker(state)) {
        state.BlockInputs.submitAdditionalVehiclePageButton.isProcessing = false;
        return false;
      }
      state.$set(
        state.additionalVehicles[additionalVehicleIndex],
        "keeperEndDate",
        state.BlockInputs.additionalKeeperEndDatePicker.answer
      );

      state.$set(
        state.additionalVehicles[additionalVehicleIndex],
        "keeperStartDate",
        state.BlockInputs.additionalKeeperStartDatePicker.answer
      );
      state.$set(state.additionalVehicles[additionalVehicleIndex], "isCurrentKeeper", true);
      await state.$nextTick();
      state.$set(state.additionalVehicles[additionalVehicleIndex], "vehicleOwnershipStatus", "I still own the vehicle");
    }

    state.$set(state.additionalVehicles[additionalVehicleIndex], "wereKeeperDatesManuallyEntered", true);
    await state.$nextTick();

    //! if returnPriceEstimation is true, set the return price estimation status
    console.log("state.returnPriceEstimation", state.returnPriceEstimation);
    if (state.returnPriceEstimation) {
      state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice;

      const yearOfManufacture = parseInt(state.additionalVehicles[additionalVehicleIndex].yearOfManufacture);
      if (
        ((state.funnelId === ClaimsCompass.Funnels.JLR_FUNNEL_ID ||
          state.funnelId === ClaimsCompass.Funnels.STAGING_FUNNEL_ID) &&
          yearOfManufacture > 2010 &&
          yearOfManufacture < 2025) ||
        (state.funnelId === ClaimsCompass.Funnels.DIESEL_EMISSIONS_FUNNEL_ID &&
          yearOfManufacture > 2008 &&
          yearOfManufacture < 2020)
      ) {
        const priceEstimationData = {
          make: state.additionalVehicles[additionalVehicleIndex].make,
          model: state.additionalVehicles[additionalVehicleIndex].model,
          yearOfManufacture: yearOfManufacture,

          keeperStartDate: state.additionalVehicles[additionalVehicleIndex].keeperStartDate?.toLocaleString(),
          keeperEndDate: state.additionalVehicles[additionalVehicleIndex].keeperEndDate?.toLocaleString(),
          isCurrentKeeper: state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper,
          claimId: state.claimId,
          userId: state.userId,
          manufactureDate: state.additionalVehicles[additionalVehicleIndex].dateFirstRegistered,
        };

        console.log("priceEstimationData", priceEstimationData);

        try {
          const manufactureDate = DateTime.fromJSDate(new Date(priceEstimationData.manufactureDate));
          const keeperStartDate = DateTime.fromISO(priceEstimationData.keeperStartDate);
          const ownershipPeriodInMonths = Math.abs(manufactureDate.diff(keeperStartDate, "months").toObject().months);
          const [estimatedPurchasePrice, depreciatedPurchaseAmount] = calculateDepreciatedPrice(
            state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice,
            ownershipPeriodInMonths
          );
          console.log("estimatedPurchasePrice", estimatedPurchasePrice);
          console.log("depreciatedPurchaseAmount", depreciatedPurchaseAmount);

          const queryStartDate = DateTime.fromJSDate(new Date());
          const monthsSinceProduction = Math.abs(manufactureDate.diff(queryStartDate, "months").months);
          const [estimatedCurrentPrice, depreciatedCurrentAmount] = calculateDepreciatedPrice(
            state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice,
            monthsSinceProduction
          );

          let saleOwnershipPeriod: number;

          // If the claimant is the current keeper, use currentDate, otherwise use the keeperEndDate
          console.log("isCurrentKeeper", state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper);
          if (state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper) {
            const currentDate = DateTime.fromJSDate(new Date());
            const monthsSinceKeeperEndDate = Math.abs(manufactureDate.diff(currentDate, "months").months);
            saleOwnershipPeriod = monthsSinceKeeperEndDate;
          } else {
            const keeperEndDate = DateTime.fromISO(priceEstimationData.keeperEndDate);
            const monthsSinceKeeperEndDate = Math.abs(manufactureDate.diff(keeperEndDate, "months").months);
            saleOwnershipPeriod = monthsSinceKeeperEndDate;
          }

          const [estimatedSalePrice, depreciatedSaleAmount] = calculateDepreciatedPrice(
            state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice,
            saleOwnershipPeriod
          );

          const depreciatedDuringOwnershipValue = estimatedPurchasePrice - estimatedSalePrice;
          const depreciationData = {
            claimId: priceEstimationData.claimId,
            userId: priceEstimationData.userId,
            manufacturerPurchasePrice: state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice,
            estimatedPurchasePrice: Math.round(estimatedPurchasePrice),
            estimatedPurchasePriceRoundedFiveHundred: Math.round(estimatedPurchasePrice / 500) * 500,
            depreciatedPurchaseValue: Math.round(depreciatedPurchaseAmount),
            estimatedCurrentPrice: Math.round(estimatedCurrentPrice),
            depreciatedCurrentValue: Math.round(depreciatedCurrentAmount),
            estimatedSalePrice: Math.round(estimatedSalePrice),
            depreciatedSaleValue: Math.round(depreciatedSaleAmount),
            depreciatedDuringOwnershipValue: Math.round(depreciatedDuringOwnershipValue),
            manufactureDate: priceEstimationData.manufactureDate,
          };

          state.additionalVehicles[additionalVehicleIndex].estimatedPurchasePrice =
            depreciationData.estimatedPurchasePrice;
          state.additionalVehicles[additionalVehicleIndex].estimatedPurchasePriceRoundedFiveHundred =
            depreciationData.estimatedPurchasePriceRoundedFiveHundred;
          state.additionalVehicles[additionalVehicleIndex].depreciatedPurchaseValue =
            depreciationData.depreciatedPurchaseValue;
          state.additionalVehicles[additionalVehicleIndex].estimatedCurrentPrice =
            depreciationData.estimatedCurrentPrice;
          state.additionalVehicles[additionalVehicleIndex].depreciatedCurrentValue =
            depreciationData.depreciatedCurrentValue;
          state.additionalVehicles[additionalVehicleIndex].estimatedSalePrice = depreciationData.estimatedSalePrice;
          state.additionalVehicles[additionalVehicleIndex].depreciatedSaleValue = depreciationData.depreciatedSaleValue;
          state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice =
            depreciationData.manufacturerPurchasePrice;
          state.additionalVehicles[additionalVehicleIndex].depreciatedDuringOwnershipValue =
            depreciationData.depreciatedDuringOwnershipValue;
          state.additionalVehicles[additionalVehicleIndex].manufactureDate = depreciationData.manufactureDate;
          state.additionalVehicles[additionalVehicleIndex].yearOfManufacture = yearOfManufacture.toString();
        } catch (error) {
          console.error("Error calculating price estimation:", error);
        }
      }
    }
  } else {
    const additionalSelectedKeeperStartDate = state.BlockInputs.additionalKeeperSelectSingleSelect.answer;
    const additionalSelectedKeeper = state.additionalVehicles[additionalVehicleIndex].keeperChangeHistory.find(
      (keeperChange) =>
        DateTime.fromJSDate(new Date(keeperChange.startDate)).toISO() === additionalSelectedKeeperStartDate
    );
    state.$set(state.additionalVehicles[additionalVehicleIndex], "keeperStartDate", additionalSelectedKeeper.startDate);
    await state.$nextTick();
    console.log("keeperStartDate", state.additionalVehicles[additionalVehicleIndex].keeperStartDate); //working

    state.$set(
      state.additionalVehicles[additionalVehicleIndex],
      "numberOfPreviousKeepers",
      additionalSelectedKeeper.previousKeepers
    );
    await state.$nextTick();
    console.log("numberOfPreviousKeepers", state.additionalVehicles[additionalVehicleIndex].numberOfPreviousKeepers); //working

    const selectedKeeperStartDate = state.additionalVehicles[additionalVehicleIndex].keeperStartDate;
    console.log("selectedKeeperStartDate", selectedKeeperStartDate);

    const selectedKeeper = state.additionalVehicles[additionalVehicleIndex].keeperChangeHistory.find(
      (keeperChange) =>
        DateTime.fromJSDate(new Date(keeperChange.startDate)).toISO() ===
        DateTime.fromJSDate(new Date(selectedKeeperStartDate)).toISO()
    );

    console.log("selectedKeeper", selectedKeeper);

    state.additionalVehicles[additionalVehicleIndex].keeperStartDate = selectedKeeper.startDate;

    state.additionalVehicles[additionalVehicleIndex].numberOfPreviousKeepers = selectedKeeper.previousKeepers;

    if (selectedKeeper.endDate) {
      state.additionalVehicles[additionalVehicleIndex].keeperEndDate = selectedKeeper.endDate;
      state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper = false;
      state.additionalVehicles[additionalVehicleIndex].vehicleOwnershipStatus = "I have disposed of the vehicle";
    } else {
      state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper = true;
      state.additionalVehicles[additionalVehicleIndex].vehicleOwnershipStatus = "I still own the vehicle";
    }

    //! if returnPriceEstimation is true, set the return price estimation status
    console.log("state.returnPriceEstimation", state.returnPriceEstimation);
    if (state.returnPriceEstimation) {
      state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice;

      const yearOfManufacture = parseInt(state.additionalVehicles[additionalVehicleIndex].yearOfManufacture);
      if (
        ((state.funnelId === ClaimsCompass.Funnels.JLR_FUNNEL_ID ||
          state.funnelId === ClaimsCompass.Funnels.STAGING_FUNNEL_ID) &&
          yearOfManufacture > 2010 &&
          yearOfManufacture < 2025) ||
        (state.funnelId === ClaimsCompass.Funnels.DIESEL_EMISSIONS_FUNNEL_ID &&
          yearOfManufacture > 2008 &&
          yearOfManufacture < 2020)
      ) {
        const priceEstimationData = {
          make: state.additionalVehicles[additionalVehicleIndex].make,
          model: state.additionalVehicles[additionalVehicleIndex].model,
          yearOfManufacture: yearOfManufacture,
          keeperStartDate: state.additionalVehicles[additionalVehicleIndex].keeperStartDate?.toLocaleString(),
          keeperEndDate: state.additionalVehicles[additionalVehicleIndex].keeperEndDate?.toLocaleString(),
          isCurrentKeeper: state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper,
          claimId: state.claimId,
          userId: state.userId,
          manufactureDate: state.additionalVehicles[additionalVehicleIndex].dateFirstRegistered,
        };

        console.log("priceEstimationData", priceEstimationData);

        try {
          const manufactureDate = DateTime.fromJSDate(new Date(priceEstimationData.manufactureDate));
          const keeperStartDate = DateTime.fromISO(priceEstimationData.keeperStartDate);
          const ownershipPeriodInMonths = Math.abs(manufactureDate.diff(keeperStartDate, "months").toObject().months);
          const [estimatedPurchasePrice, depreciatedPurchaseAmount] = calculateDepreciatedPrice(
            state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice,
            ownershipPeriodInMonths
          );
          console.log("estimatedPurchasePrice", estimatedPurchasePrice);
          console.log("depreciatedPurchaseAmount", depreciatedPurchaseAmount);

          const queryStartDate = DateTime.fromJSDate(new Date());
          const monthsSinceProduction = Math.abs(manufactureDate.diff(queryStartDate, "months").months);
          const [estimatedCurrentPrice, depreciatedCurrentAmount] = calculateDepreciatedPrice(
            state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice,
            monthsSinceProduction
          );

          let saleOwnershipPeriod: number;

          // If the claimant is the current keeper, use currentDate, otherwise use the keeperEndDate
          console.log("isCurrentKeeper", state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper);
          if (state.additionalVehicles[additionalVehicleIndex].isCurrentKeeper) {
            const currentDate = DateTime.fromJSDate(new Date());
            const monthsSinceKeeperEndDate = Math.abs(manufactureDate.diff(currentDate, "months").months);
            saleOwnershipPeriod = monthsSinceKeeperEndDate;
          } else {
            const keeperEndDate = DateTime.fromISO(priceEstimationData.keeperEndDate);
            const monthsSinceKeeperEndDate = Math.abs(manufactureDate.diff(keeperEndDate, "months").months);
            saleOwnershipPeriod = monthsSinceKeeperEndDate;
          }

          const [estimatedSalePrice, depreciatedSaleAmount] = calculateDepreciatedPrice(
            state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice,
            saleOwnershipPeriod
          );

          const depreciatedDuringOwnershipValue = estimatedPurchasePrice - estimatedSalePrice;
          const depreciationData = {
            claimId: priceEstimationData.claimId,
            userId: priceEstimationData.userId,
            manufacturerPurchasePrice: state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice,
            estimatedPurchasePrice: Math.round(estimatedPurchasePrice),
            estimatedPurchasePriceRoundedFiveHundred: Math.round(estimatedPurchasePrice / 500) * 500,
            depreciatedPurchaseValue: Math.round(depreciatedPurchaseAmount),
            estimatedCurrentPrice: Math.round(estimatedCurrentPrice),
            depreciatedCurrentValue: Math.round(depreciatedCurrentAmount),
            estimatedSalePrice: Math.round(estimatedSalePrice),
            depreciatedSaleValue: Math.round(depreciatedSaleAmount),
            depreciatedDuringOwnershipValue: Math.round(depreciatedDuringOwnershipValue),
            manufactureDate: priceEstimationData.manufactureDate,
          };

          state.additionalVehicles[additionalVehicleIndex].estimatedPurchasePrice =
            depreciationData.estimatedPurchasePrice;
          state.additionalVehicles[additionalVehicleIndex].estimatedPurchasePriceRoundedFiveHundred =
            depreciationData.estimatedPurchasePriceRoundedFiveHundred;
          state.additionalVehicles[additionalVehicleIndex].depreciatedPurchaseValue =
            depreciationData.depreciatedPurchaseValue;
          state.additionalVehicles[additionalVehicleIndex].estimatedCurrentPrice =
            depreciationData.estimatedCurrentPrice;
          state.additionalVehicles[additionalVehicleIndex].depreciatedCurrentValue =
            depreciationData.depreciatedCurrentValue;
          state.additionalVehicles[additionalVehicleIndex].estimatedSalePrice = depreciationData.estimatedSalePrice;
          state.additionalVehicles[additionalVehicleIndex].depreciatedSaleValue = depreciationData.depreciatedSaleValue;
          state.additionalVehicles[additionalVehicleIndex].manufacturerPurchasePrice =
            depreciationData.manufacturerPurchasePrice;
          state.additionalVehicles[additionalVehicleIndex].depreciatedDuringOwnershipValue =
            depreciationData.depreciatedDuringOwnershipValue;
          state.additionalVehicles[additionalVehicleIndex].manufactureDate = depreciationData.manufactureDate;
          state.additionalVehicles[additionalVehicleIndex].yearOfManufacture = yearOfManufacture.toString();
        } catch (error) {
          console.error("Error calculating price estimation:", error);
        }
      }
    }
  }
  await state.$nextTick();
  state.additionalVehicles[additionalVehicleIndex].view.success = true;

  // ! Check if the vehicle is eligible
  return await eligibleVehicleCheck(state, additionalVehicleIndex);
}

// Last function to call the backend and submit the additional vehicles

export async function submitAdditionalVehiclePageMethod(
  state: BlockMultiMotorSpecsInstance,
  additionalVehicleIndex: number
) {
  try {
    state.error = "";
    state.BlockInputs.submitAdditionalVehicleFinalPageButton.isProcessing = true;
    if (state.additionalVehicles.length > 0) {
      const funnelVariables = await _getFunnelVariables(state);

      // ! filter out the vehicles that are not eligible and THEN remove the first vehicle (which is the first eligible vehicle)
      //const eligibleVehicles = state.additionalVehicles.filter((vehicle) => vehicle.isEligible);

      console.log("state.additionalVehicles", state.additionalVehicles);
      let additionalVehiclesToStore = state.additionalVehicles.filter((vehicle) => vehicle.duplicateClaim !== true);

      console.log("eligibleVehicles", additionalVehiclesToStore);

      // ! First vehicle will be the first eligible vehicle
      let firstVehicle = additionalVehiclesToStore.find((vehicle) => vehicle.isEligible);
      if (!firstVehicle && additionalVehiclesToStore.length > 0) {
        firstVehicle = additionalVehiclesToStore[0];
      } else if (additionalVehiclesToStore.length === 0) {
        state.error = state.uiMessages.oneVehicleMustBeEligible;
        return;
      }

      console.log("firstVehicle", firstVehicle);
      firstVehicle.vehicleCaseType = "Original Vehicle";

      additionalVehiclesToStore = additionalVehiclesToStore.filter((vehicle) => firstVehicle?.id !== vehicle.id);
      additionalVehiclesToStore.forEach((vehicle) => {
        vehicle.vehicleCaseType = "Additional Vehicle";
      });
      //! Reseting constants
      const resetProcessingStates = () => {
        state.BlockInputs.addVehicleButton.isProcessing = false;
        state.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
        state.BlockInputs.submitAdditionalVehicleFinalPageButton.isProcessing = false;
        state.uiToggles.isSubmitting = false;
        state.additionalVehicles[additionalVehicleIndex].view.success = false;
        state.additionalVehicles[additionalVehicleIndex].duplicateClaim = false;
      };

      //! if the first vehicle is not eligible, and there is only one vehicle, reset it to lookup view and reset the array
      if (state.additionalVehicles.length === 1 && !firstVehicle) {
        // Reset the first vehicle to lookup view only if it's the only vehicle
        state.additionalVehicles = [
          {
            vrm: "",
            id: Date.now().toString(),
            isEligible: false,
            milbergVehicleEligible: true,
            milbergVehicleRejectionTag: "",
            milbergVehicleRejectionExplanation: "",
            enteredRegistration: "",
            keeperEndDate: "",
            ownershipDate: "",
            purchaseMethod: "",
            vehicleAcquisitionLocation: "",
            keeperStartDate: "",
            numberOfPreviousKeepers: 0,
            isCurrentKeeper: false,
            wereKeeperDatesManuallyEntered: false,
            state: false,
            invalidFeedback: state.uiMessages.firstVehicleMustBeEligible,
            vrmInputState: false,
            vrmInputInvalidFeedback: "",
            keeperChangeHistory: [],
            tlwVehicleEligible: true,
            tlwVehicleRejectionTag: "",
            tlwVehicleRejectionExplanation: "",
            view: {
              lookup: true,
              ownershipQuestions: false,

              ownershipConfirmation: false,
              historicalVinLookup: false,
              success: false,
              showCard: false,
            },
          },
        ];

        resetProcessingStates();
        reject(state, state.uiMessages.firstVehicleMustBeEligible);
        state.error = state.uiMessages.firstVehicleMustBeEligible;
        return;
      }

      //! if no vehicle is eligible, reset the first vehicle to lookup view
      if (additionalVehiclesToStore.length === 0 && !firstVehicle) {
        // Reset the first vehicle to lookup view only if it's no vehicle is eligible
        state.additionalVehicles = [
          {
            vrm: "",
            id: Date.now().toString(),
            isEligible: false,
            keeperEndDate: "",
            enteredRegistration: "",
            ownershipDate: "",
            purchaseMethod: "",
            vehicleAcquisitionLocation: "",
            keeperStartDate: "",
            numberOfPreviousKeepers: 0,
            isCurrentKeeper: false,
            wereKeeperDatesManuallyEntered: false,
            state: false,
            invalidFeedback: state.uiMessages.oneVehicleMustBeEligible,
            vrmInputState: false,
            vrmInputInvalidFeedback: "",
            keeperChangeHistory: [],
            view: {
              lookup: true,
              ownershipQuestions: false,
              ownershipConfirmation: false,
              historicalVinLookup: false,
              success: false,
              showCard: false,
            },
            milbergVehicleEligible: true,
            milbergVehicleRejectionTag: "",
            milbergVehicleRejectionExplanation: "",
            tlwVehicleEligible: true,
            tlwVehicleRejectionTag: "",
            tlwVehicleRejectionExplanation: "",
          },
        ];
        resetProcessingStates();

        state.error = state.uiMessages.oneVehicleMustBeEligible;
        reject(state, state.uiMessages.oneVehicleMustBeEligible);
        return;
      }
      console.log("firstVehicle", firstVehicle);
      const vehicle: (typeof state)["additionalVehicle"] = {
        ...firstVehicle,
        enteredRegistration: firstVehicle.vehicleRegistration?.toUpperCase()?.replace(/\s/g, ""),
      };
      console.log("vehicle", vehicle);
      const hashedVehicle = await state.variablesService.hashData(vehicle, funnelVariables);

      for (const [key, value] of Object.entries(hashedVehicle)) {
        state.claimDataService.setArtefact(key, value);
      }

      await state.$nextTick();

      console.log("additionalVehiclesToStore", additionalVehiclesToStore);

      const result = await onCallGateway<"createAssociatedClaims">({
        functionName: "createAssociatedClaims",
        data: {
          sourceClaimId: state.claimId,
          sourceUserId: state.userId,
          sourceFunnelId: state.funnelId,
          vehicles: additionalVehiclesToStore,
        },
      });

      if (result.data && result.data.error) {
        state.$data.error = "An error occurred while creating associated claims. Please try again.";
      } else if (result.data) {
        state.$data.success = "Additional vehicle claims created successfully.";
        await state.$store.dispatch("events/fire", {
          name: state?.eventValues.next,
        });
        return;
      } else {
        throw new Error("Unexpected API response");
      }

      state.BlockInputs.submitAdditionalVehicleFinalPageButton.isProcessing = true;
      state.uiToggles.isSubmitting = true;
    }
  } catch (exception) {
    console.error("[motorspecsMultipleVehicle] An error occurred during claim submission:", exception);
    if (exception.message) {
      state.error = exception.message;
    } else {
      state.error = "An unexpected error occurred. Please try again later.";
    }
  } finally {
    // Reset loading state or perform any cleanup
    state.BlockInputs.submitAdditionalVehicleFinalPageButton.isProcessing = false;
    state.uiToggles.isSubmitting = false;
  }
}

/** Type guard to check if the response returned from getVehicleFromMotorSpecs is a ClaimsGateFunctionReturnedError */
export function isClaimsGateFunctionReturnedError(
  response: ClaimsGateMotorSpecsGetVehicleResponse | ClaimsGateFunctionReturnedError
): response is ClaimsGateFunctionReturnedError {
  console.log("isClaimsGateReturnedError", response, response as ClaimsGateFunctionReturnedError);
  return (response as ClaimsGateFunctionReturnedError)?.error?.id !== undefined;
}

export function calculateDepreciatedPrice(purchasePrice: number, months: number) {
  if (months === 0) {
    return [purchasePrice, 0];
  }

  const annualDepreciationRate1 = 0.2; // First year depreciation
  const annualDepreciationRate2 = 0.15; // Depreciation for the next three years
  const annualDepreciationRate3 = 0.08; // Depreciation after the fourth year

  const years = months / 12;
  const initialYears = Math.min(years, 1);
  const middleYears = Math.min(Math.max(years - 1, 0), 3);
  const laterYears = Math.max(years - 4, 0);

  const initialDepreciation = Math.pow(1 - annualDepreciationRate1, initialYears);
  const middleDepreciation = Math.pow(1 - annualDepreciationRate2, middleYears);
  const laterDepreciation = Math.pow(1 - annualDepreciationRate3, laterYears);

  const estimatedDepreciatedPrice = purchasePrice * initialDepreciation * middleDepreciation * laterDepreciation;
  const estimatedDepreciatedAmount = purchasePrice - estimatedDepreciatedPrice;
  if (
    estimatedDepreciatedAmount < 0 ||
    isNaN(estimatedDepreciatedAmount) ||
    estimatedDepreciatedPrice < 0 ||
    isNaN(estimatedDepreciatedPrice)
  ) {
    throw new Error("Estimated depreciated amount is less than 0 or NAN");
  }
  return [estimatedDepreciatedPrice, estimatedDepreciatedAmount];
}

/** Fetches a given vehicle from the MotorSpecs backend */
export async function getVehicleFromMotorSpecs<F = "getVehicleFromMotorSpecs">(
  state: BlockMultiMotorSpecsInstance,
  vid: string,
  forceHistoricalVinLookup: boolean = false,
  date?: DateTime
): Promise<{
  data?: ClaimsGateMotorSpecsGetVehicleResponse | ClaimsGateFunctionReturnedError;
}> {
  return onCallGateway<"getVehicleFromMotorSpecs">({
    functionName: "getVehicleFromMotorSpecs",
    data: {
      vid,
      options: {
        returnMercedesRecallData: state.returnMercedesRecallData,
        returnDpfData: state.returnDpfData,
        returnSupplementaryDataFromUkvd: state.returnSupplementaryDataFromUkvd,
        returnPriceEstimation: state.returnPriceEstimation,
        returnSupplementaryVwDataFromEa: state.returnSupplementaryVwDataFromEa,
      },

      claimId: state.claimId,

      funnelId: state.funnelId,
      pageId: state.pageId,
      forceHistoricalVinLookup,
      date: date?.toISO(),
    },
  });
}
