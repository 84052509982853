<script lang="ts">
import Vue from "vue";
import { getProps } from "./props";
import sgIconsObj from "../../../../design/custom/plugins/icons/iconConfig";
export default Vue.extend({
  name: "BlockIcon",
  props: {
    ...getProps(),
  },

  data() {
    return {
      hovered: false,
      sgIcons: sgIconsObj,
    };
  },
});
</script>

<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <b-button
      class="border-1 border border-grey-300 bg-white p-3 shadow-sm"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    >
      <font-awesome-icon
        :icon="sgIcons[iconType]"
        :class="[
          'align-self-start mt-1 font-weight-semibold',
          isSelected ? 'text-grey-700' : 'text-grey-400',
          hovered ? 'text-grey-700' : 'text-grey-400',
        ]"
        size="xl"
      />
    </b-button>
    <span
      :class="[
        'text-sm',
        isSelected
          ? 'text-grey-700 font-weight-semibold mt-2 text-md'
          : 'text-grey-400 font-weight-semibold mt-2 text-md',
        hovered ? 'text-grey-700 font-weight-semibold mt-2 text-md' : 'text-grey-400 font-weight-semibold mt-2 text-md',
      ]"
      >{{ iconName }}</span
    >
  </div>
</template>
