<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockCaravanInstance } from "./BlockCaravanBlock";
import { Fragment } from "vue-frag";

// Components
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockCaravan",
  components: {
    Fragment,
    BlockPadding,
    BlockButton,
    BlockSingleSelect,
    BlockDatePicker,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as unknown as BlockCaravanInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },

  methods: {
    displayHelpModal() {
      methods.displayHelpModal(this as unknown as BlockCaravanInstance);
    },

    notMyVehicle() {
      methods.notMyVehicle(this as unknown as BlockCaravanInstance);
    },

    searchVehicle() {
      methods.checkCaravanType(this as unknown as BlockCaravanInstance);
    },

    submitVehicleConfirmation() {
      methods.submitVehicleConfirmation(this as unknown as BlockCaravanInstance);
    },

    submitKeeperSelect() {
      methods.submitKeeperSelect(this as unknown as BlockCaravanInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockCaravanInstance);
  },
});
</script>

<style scoped>
.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.selected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #3452e1 !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #eff2f7 !important;
  transition: 0.25s;
}

.input-group > .input-group-prepend {
  flex: 0 0 15%;
}
.input-group .input-group-text {
  width: 100%;
}
/* Reg Input styling */
.reg-input-selected {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}
.input-group-prepend:focus {
  border-color: var(--primary) !important;
}
.btn-glow,
.btn-glow:active,
.btn-glow:focus,
.btn-glow:hover {
  box-shadow: 0px 0px 30px -10px #3452e1 !important;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.disabled {
  background-color: #e9ecef !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}

.form-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.prepend-border {
  border: 2px solid var(--primary) !important;
}
</style>
<template>
  <Fragment>
    <BlockPadding :padding="padding" id="padding-1" />

    <!-- Initial search -->
    <template v-if="!uiToggles.isKeeperSelectVisible && !uiToggles.showVehicleConfirmation">
      <h5 class="mb-0 font-weight-semibold text-grey-900">Caravan vehicle identification number</h5>
      <p class="mb-1 text-grey-700">Please enter your caravan vehicle identification number</p>

      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text class="justify-content-center reg-input-selected">
            <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          :v-mask="BlockInputs.caravanVin.mask"
          v-bind="BlockInputs.caravanVin"
          class="border reg-input-selected"
          size="lg"
          v-model="vin"
          :state="vinInputState"
          placeholder="Example: SGBSX5CBYA1111111"
          @update="vinInputState = null"
        ></b-form-input>
      </b-input-group>

      <template v-if="!vinInputState">
        <b-form-invalid-feedback :state="vinInputState" id="input-live-feedback">
          {{ vinInputInvalidFeedback }}
        </b-form-invalid-feedback>
      </template>

      <BlockButton
        @clicked="searchVehicle"
        v-bind="BlockInputs.searchVinButton"
        v-bind.sync="BlockInputs.searchVinButton"
      />
    </template>

    <b-card
      v-if="uiToggles.showVehicleConfirmation || uiToggles.isKeeperSelectVisible"
      class="mb-0"
      no-body
      style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)"
    >
      <b-card-body style="padding-bottom: 0.25rem !important">
        <h4 class="text-grey-7000 font-weight-semibold">Vehicle Found</h4>
        <h4 class="text-grey-900">
          We found your vehicle: <b>{{ vehicle?.yearOfManufacture }}</b> - <b>{{ vehicle?.make }} Caravan</b>
        </h4>

        <h5 class="font-weight-semibold" role="button" @click="notMyVehicle">
          <span :style="theme.textColourStyles">Click here to change your vehicle</span>
        </h5>
      </b-card-body>
    </b-card>

    <template v-if="uiToggles.showVehicleConfirmation">
      <div class="d-flex flex-column">
        <BlockPadding :padding="padding" id="padding-2" />
        <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Confirmation</h5>
        <p class="mb-1 text-grey-700">Please confirm the caravan details shown above are correct</p>
        <BlockSingleSelect
          v-bind="BlockInputs.vehicleConfirmationSingleSelect"
          v-bind.sync="BlockInputs.vehicleConfirmationSingleSelect"
          padding="0"
          id="singleSelect-1"
        />
        <BlockButton
          @clicked="submitVehicleConfirmation"
          v-bind="BlockInputs.submitVehicleConfirmationButton"
          v-bind.sync="BlockInputs.submitVehicleConfirmationButton"
        />
      </div>
    </template>

    <template v-if="uiToggles.isKeeperSelectVisible">
      <div class="d-flex flex-column">
        <BlockPadding :padding="padding" id="padding-2" />

        <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Date</h5>
        <p class="mb-1 text-grey-700">Please select the date when you purchased the caravan</p>

        <BlockDatePicker
          v-bind="BlockInputs.ownershipDatePicker"
          v-bind.sync="BlockInputs.ownershipDatePicker"
          :answer.sync="ownershipDate"
          padding="0"
          id="datePicker-1"
        />

        <BlockButton
          @clicked="submitKeeperSelect"
          v-bind="BlockInputs.submitKeeperSelectButton"
          v-bind.sync="BlockInputs.submitKeeperSelectButton"
        />
      </div>
    </template>
  </Fragment>
</template>
